import ContentLoader from 'react-content-loader'
import { React } from 'react'

export default function Loading () {
  return (
    <ContentLoader viewBox='-150 0 600 200' height={750} width={800} backgroundColor='transparent'>
      <circle cx='150' cy='86' r='8' />
      <circle cx='194' cy='86' r='8' />
      <circle cx='238' cy='86' r='8' />
    </ContentLoader>
  )
}
