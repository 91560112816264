import { React, Component } from 'react'
import '../popup_components.css'
import './complications.css'
import {
  PregnantSelect,
  PregnantDateTimepicker,
  TextLabel,
  Title,
  TextValue,
  PatientHelpModal,
  StyledDivider,
  PregnantMultiselectValuesPlaceholder
} from 'elements/'
import {
  postHealthChecklistComplication,
  getComplicationFields,
  postHealthChecklistComplicationInteraction,
  getHealthChecklistComplicationId,
  getHealthChecklistComplicationInteractionFieldsRecordType,
  getPrivateDataPregnantId
} from 'api/'
import { transformDatetimeToDate } from 'shortcuts/'
import parseData from './api/post-health-checklist-complication-interaction/parse-data'
import parseDataComplication from './api/post-health-checklist-complication/parse-data'
import { Grid as GridBase, TextField, Typography, Button } from '@mui/material'
import {
  pregnancyComplicationObject,
  interactionWithComplicationObject,
  complicationValidationObject
} from './data-structure/complications'
import Loading from '../../loading/loading'
import styled from '@emotion/styled'
import validationObject from '../card/data-structure/validation-object'
import { newComplicationFields } from './data-structure/new-complications/'
import { PropTypes } from 'prop-types'

const Grid = styled(GridBase)({
  justifyContent: 'flex-start'
})

export default class Complications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pregnancy_id: this.props.pregnant_id,
      data: this.props.data,
      complication_fields: newComplicationFields,
      PregnancyComplication: pregnancyComplicationObject,
      InteractionWithComplication: interactionWithComplicationObject,

      Interactions: [],
      HospitalizationFacility: [],
      InteractionWithComplicationResult: [],

      pregnancy_complication_id: this.props.data.id,
      doctor_username: localStorage.getItem('username'),

      validation: complicationValidationObject,

      is_save_button_disabled: false,
      save_button_text: 'Сохранить',

      complication_loading: true,
      complication_interaction_loading: true,
      max_retries: 5,
      is_help_window_open: false,

      pregnant: null,
      pregnant_loading: false
    }
  }

  checkIsValid = (data) => {
    const validationKeys = Object.keys(data)
    const notValid = []
    for (let i = 0; i < validationKeys.length; i++) {
      if (data[validationKeys[i]] !== '') {
        notValid.push(data[validationKeys[i]])
      }
    }
    return [!notValid.length, notValid]
  }

  changeSaveButton = (saved) => {
    if (saved) {
      this.setState({
        is_save_button_disabled: true,
        save_button_text: 'Сохранено'
      })
    } else {
      this.setState(
        {
          is_save_button_disabled: true,
          save_button_text: 'Ошибка'
        },
        () => {
          setTimeout(() => {
            this.setState({ is_save_button_disabled: false })
          }, 2000)
        }
      )
    }
  }

  postHealthChecklistComplicationInteractionWrap = (data) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    const parsedData = parseData(data)
    const [isValid, notValidData] = this.checkIsValid(data.validation)
    if (!isValid) {
      let errorText = ''
      for (let i = 0; i < notValidData.length; i++) {
        errorText += notValidData[i] + '\n'
      }
      this.props.notify('error', errorText)
      return 0
    }
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        postHealthChecklistComplicationInteraction(parsedData)
          .then((res) => {
            if (res.data) {
              this.changeSaveButton(true)
              timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
              this.props.updateCardBlockComponent('complications')
              this.healthChecklistComplication(this.state.data.id)
              this.props.setCurrentModal(null, {})
              this.props.notify('success', 'Успешно сохранено')
            }
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              this.changeSaveButton(false)
              this.props.notify('error', `Возникли ошибки ${JSON.stringify(err.response.data)}`)
            }
          })
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  healthChecklistComplication = (dataId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getHealthChecklistComplicationId(dataId)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  PregnancyComplication: res.data.PregnancyComplication,
                  Interactions: res.data.InteractionWithComplication.length ? res.data.InteractionWithComplication : [],
                  complication_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  healthChecklistComplicationInteractionFields = (recordType) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getHealthChecklistComplicationInteractionFieldsRecordType(recordType)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  HospitalizationFacility: res.data.HospitalizationFacility,
                  InteractionWithComplicationResult: res.data.InteractionWithComplicationResult,
                  complication_interaction_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  complicationFields = () => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getComplicationFields()
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  complication_fields: res.data,
                  complication_fields_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  posthealthChecklistComplicationWrap = (data) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    const parsedData = parseDataComplication(data)
    const [isValid, notValidData] = this.checkIsValid(data.validation)
    if (!isValid) {
      let errorText = ''
      for (let i = 0; i < notValidData.length; i++) {
        errorText += notValidData[i] + '\n'
      }
      this.props.notify('error', errorText)
      return 0
    }
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        postHealthChecklistComplication(parsedData)
          .then((res) => {
            this.changeSaveButton(true)
            timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
            this.props.notify('success', 'Успешно сохранено')
            this.props.updateCardBlockComponent('complications')
            this.props.setCurrentModal(null, {})
          })
          .catch((err) => {
            if (err.response?.status !== 401) {
              this.changeSaveButton(false)
              this.props.notify('error', `Возникли ошибки ${JSON.stringify(err.response?.data)}`)
            }
          })
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  componentDidMount () {
    this.misPregnantId(this.props.pregnant_id)
    this.complicationFields()
    this.healthChecklistComplication(this.state.data.id)
    if (this.props.data.record_type && this.props.data.record_type !== '-') {
      this.healthChecklistComplicationInteractionFields(this.props.data.record_type)
    }
  }

  setInteractionVal = (id, val) => {
    if (this.state.InteractionWithComplication[id] !== val) {
      this.setState({
        InteractionWithComplication: {
          ...this.state.InteractionWithComplication,
          [id]: val
        },
        validation: {
          ...this.state.validation,
          [id]: ''
        }
      })
    }
  }

  setCommentVal = (id, val) => {
    if (val !== this.state.InteractionWithComplication[id]) {
      this.setState(
        {
          InteractionWithComplication: {
            ...this.state.InteractionWithComplication,
            [id]: val
          }
        },
        () => {
          const validationVal = val.length > 0 ? '' : validationObject[id]
          if (this.state.validation[id] !== val) {
            this.setState({
              validation: { ...this.state.validation, [id]: validationVal }
            })
          }
        }
      )
    }
  }

  setDatePickerVal = (id, val) => {
    if (val !== this.state.InteractionWithComplication[id]) {
      this.setState({
        InteractionWithComplication: {
          ...this.state.InteractionWithComplication,
          [id]: val
        },
        validation: {
          ...this.state.validation,
          [id]: ''
        }
      })
    }
  }

  misPregnantId = (pregnantId) => {
    getPrivateDataPregnantId(pregnantId)
      .then((res) => {
        this.setState({ pregnant: res.data, pregnant_loading: false })
      })
      .catch((err) => console.log(err))
  }

  setSelectVal = (id, val) => {
    if (this.state.PregnancyComplication[id].name !== val) {
      this.setState(
        {
          PregnancyComplication: {
            ...this.state.PregnancyComplication,
            [id]: { name: val }
          }
        },
        () => {
          const validationState = !val ? complicationValidationObject[id] : ''
          if (this.state.validation[id] !== validationState) {
            this.setState({
              validation: {
                ...this.state.validation,
                [id]: validationState
              }
            })
          }
        }
      )
    }
  }

  render () {
    return (
      <>
        {this.state.complication_loading && this.state.complication_interaction_loading
          ? (
            <Loading></Loading>
            )
          : (
          <div>
            <div className='popup_card-block' style={{ padding: '1%' }}>
              <div style={{ overflowY: 'auto', height: '71vh' }}>
                <Grid container>
                  <Grid xs={2} md={2} lg={2} style={{ display: 'flex', justifyContent: 'flex-start' }} item>
                    <Title>Запись</Title>
                  </Grid>
                </Grid>
                <StyledDivider />
                <Grid gap={1} container>
                  {this.state.PregnancyComplication?.date_of_filling_checklist_of_health
                    ? (
                      <Grid xs={3} lg={3} md={3} item>
                        <Grid gap={1} container>
                          <Grid xs={12} md={12} lg={12} item>
                            <TextLabel item>Дата заполнения чек-листа</TextLabel>
                          </Grid>
                          <Grid xs={12} md={12} lg={12} item>
                            <TextValue>
                              {transformDatetimeToDate(
                                this.state.PregnancyComplication?.date_of_filling_checklist_of_health
                              )}
                            </TextValue>
                          </Grid>
                        </Grid>
                      </Grid>
                      )
                    : (
                        ''
                      )}
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Врачебные мероприятия</TextLabel>
                    <TextValue>{this.state.PregnancyComplication.diagnosis?.name}</TextValue>
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Оператор (статус обращения)</TextLabel>
                    <TextValue>{this.state.PregnancyComplication.record_type?.name}</TextValue>
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Состояние в динамике</TextLabel>
                    <TextValue>{this.state.PregnancyComplication.diagnosis_status?.name}</TextValue>
                  </Grid>
                </Grid>
                <Grid mt={2} gap={1} container>
                  <Grid xs={3} lg={3} md={3} item>
                    <Grid gap={1} container>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextLabel>Неделя беременности на дату заполнения</TextLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextValue>{this.state.PregnancyComplication.week_of_pregnancy_on_date_of_filling}</TextValue>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={2} lg={2} md={2} item>
                    <Grid gap={1} container>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextLabel>Жалобы</TextLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <PregnantMultiselectValuesPlaceholder
                          chosen={
                            this.state.PregnancyComplication.complaint.length
                              ? this.state.PregnancyComplication.complaint.map((a) => a.name)
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={2} lg={2} md={2} item>
                    <Grid gap={1} container>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextLabel>Тревожные признаки</TextLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <PregnantMultiselectValuesPlaceholder
                          chosen={
                            this.state.PregnancyComplication.danger_sign.length
                              ? this.state.PregnancyComplication.danger_sign.map((a) => a.name)
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <Grid gap={1} container>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextLabel>Комментарий</TextLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography id='anamnesis' aria-disabled multiline='true' rows={3} fullWidth>
                          {this.state.PregnancyComplication.anamnesis}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid mt={2} gap={1} container>
                  <Grid xs={3} lg={3} md={3} item>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextLabel>Врач ТМЦ</TextLabel>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextValue>{this.state.data.doctor_full_name}</TextValue>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <StyledDivider />
                <Grid container>
                  <Grid xs={1} md={1} lg={1} item>
                    <Title>Взаимодействия</Title>
                  </Grid>
                </Grid>
                <StyledDivider />
                <Grid gap={1} container>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Дата записи/госпитализации</TextLabel>
                    <PregnantDateTimepicker
                      id='date_of_doctors_appointment_or_hospitalization'
                      value={this.state.InteractionWithComplication.date_of_doctors_appointment_or_hospitalization}
                      onChange={this.setDatePickerVal}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Учреждение госпитализации</TextLabel>
                    <PregnantSelect
                      id='hospitalization_facility'
                      choices={this.state.HospitalizationFacility}
                      value={this.state.InteractionWithComplication.hospitalization_facility}
                      onChange={this.setInteractionVal}
                    />
                  </Grid>
                  <Grid xs={2} lg={2} md={2} item>
                    <TextLabel>Результат</TextLabel>
                    <PregnantSelect
                      id='interaction_result'
                      choices={this.state.InteractionWithComplicationResult}
                      value={this.state.InteractionWithComplication.interaction_result}
                      onChange={this.setInteractionVal}
                    ></PregnantSelect>
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Комментарий</TextLabel>
                    <TextField
                      id='comment'
                      onChange={(e) => this.setCommentVal(e.target.id, e.target.value)}
                      value={this.state.InteractionWithComplication.comment}
                      multiline='true'
                      rows={3}
                      fullWidth
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={3} lg={3} md={3} item>
                    <Grid container>
                      <Grid xs={12} lg={12} md={12} item>
                        <TextLabel>Врач ТМЦ</TextLabel>
                      </Grid>
                      <Grid xs={12} lg={12} md={12} item>
                        <TextValue>{localStorage.getItem('full_name')}</TextValue>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Дата результата</TextLabel>
                    <TextValue></TextValue>
                  </Grid>
                </Grid>
                <StyledDivider />
                <Grid container>
                  <Grid xs={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-start' }} item>
                    <Title>История взаимодействий</Title>
                  </Grid>
                </Grid>
                <StyledDivider />
                {this.state.Interactions.length > 0
                  ? (
                      this.state.Interactions.map((obj, index) => {
                        return (
                          <Grid key={index} container>
                            <Grid xs={3} lg={3} md={3} item>
                              <TextLabel>Дата взаимодействия</TextLabel>
                              <Typography>{obj.date_of_creation.split('.')[0].replace('T', ' ')}</Typography>
                            </Grid>
                            <Grid xs={3} lg={3} md={3} item>
                              <TextLabel>Дата записи/госпитализации </TextLabel>
                              <Typography>
                                {obj.date_of_doctors_appointment_or_hospitalization.split('+')[0].replace('T', ' ')}
                              </Typography>
                            </Grid>
                            <Grid xs={3} lg={3} md={3} item>
                              <TextLabel>Учреждение госпитализации</TextLabel>
                              <Typography>{obj.hospitalization_facility?.name}</Typography>
                            </Grid>
                            <Grid xs={3} lg={3} md={3} item>
                              <TextLabel>Результат</TextLabel>
                              <Typography>{obj.interaction_result?.name}</Typography>
                            </Grid>
                          </Grid>
                        )
                      })
                    )
                  : (
                      <Grid container>
                        <Grid textAlign='center' item xs={12} lg={12} md={12}>
                          <Typography>Записей нет</Typography>
                        </Grid>
                      </Grid>
                    )}
              </div>
            </div>
            <Grid mt={3} container style={{ justifyContent: 'flex-end', gap: 20 }}>
              <Button
                onClick={() => this.setState({ is_help_window_open: true })}
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textTransform: 'none'
                }}
                variant='contained'
                color='primary'
              >
                Сформировать справку
              </Button>
              <Button
                disabled={this.state.is_save_button_disabled}
                onClick={() => {
                  this.postHealthChecklistComplicationInteractionWrap(this.state)
                }}
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  width: '20%'
                }}
                variant='contained'
                color='primary'
              >
                {this.state.save_button_text}
              </Button>
            </Grid>
          </div>
            )}
            <PatientHelpModal
              open={this.state.is_help_window_open}
              onClose={() => this.setState({ is_help_window_open: false })}
              data={{
                ...this.state.pregnant,
                week: this.props.current_week_of_pregnancy,
                PregnancyComplication: this.state.PregnancyComplication
              }}
            />
      </>
    )
  }
}

Complications.propTypes = {
  pregnant_id: PropTypes.number,
  data: PropTypes.object,
  current_week_of_pregnancy: PropTypes.number,
  notify: PropTypes.object,
  updateCardBlockComponent: PropTypes.func,
  setCurrentModal: PropTypes.func
}
