const moment = require('moment')

export function getDateOfBirthFromIin (iin) {
  const seventhNumberFromIin = iin.slice(6, 7)
  const yearFromIin = seventhNumberFromIin === '2' || seventhNumberFromIin === '1'
    ? '18' + iin.slice(0, 2)
    : seventhNumberFromIin === '4' || seventhNumberFromIin === '3' || seventhNumberFromIin === '0'
      ? '19' + iin.slice(0, 2)
      : seventhNumberFromIin === '6' || seventhNumberFromIin === '5'
        ? '20' + iin.slice(0, 2)
        : undefined
  const monthFromIin = iin.slice(2, 4)
  const dayFromIin = iin.slice(4, 6)
  const dateFromIinObj = moment(
    yearFromIin + '/' + monthFromIin + '/' + dayFromIin,
    'YYYY/MM/DD'
  )
  if (!dateFromIinObj.isValid()) {
    return false
  }
  return yearFromIin + '-' + monthFromIin + '-' + dayFromIin
}
