const personalDataObject = {
  full_name: '',
  date_of_registration_by_tmc: '',
  current_week_of_pregnancy: 0,
  age: 0,
  pmsp: '',
  weight_before_pregnancy: 0,
  total_pregnancies: 0,
  estimated_date_of_childbirth: ''
}

export default personalDataObject
