import {
  getDateOfBirthFromIin,
  isGenderMale,
  isSeventhNumberCorrect,
  isSeventhNumberZero,
  checkIinWithZeroWithBirthDate
} from './'
import AssertDateOfBirthValidation from '../set-date-of-birth/assert-date-of-birth-validation'
import checkIinWithBirthDate from '../check-iin-with-birthday'

export default function AssertIin (iin, dateOfBirth) {
  const dateFromIin = getDateOfBirthFromIin(iin)
  if (!dateFromIin) {
    return ['Некорректный ИИН. Невалидная дата (первые 6 цифр)', '']
  }
  const dateOfBirthHelperText = AssertDateOfBirthValidation(dateFromIin, iin)
  if (isGenderMale(iin)) {
    return ['Некорректный ИИН. Пол не может быть мужским', dateOfBirthHelperText]
  }
  if (!isSeventhNumberCorrect(iin)) {
    return ['Некорректный ИИН.', dateOfBirthHelperText]
  }
  if (isSeventhNumberZero(iin)) {
    if (!checkIinWithZeroWithBirthDate(iin, dateOfBirth)) {
      return ['ИИН не совпадает с датой', 'Дата не совпадает с ИИН']
    }
    if (!checkIinWithBirthDate(iin, dateOfBirth)) {
      return ['', '']
    }
  }
  if (iin.length < 12) {
    return ['Введённый ИИН слишком короткий', dateOfBirthHelperText]
  }
  return ['', dateOfBirthHelperText]
}
