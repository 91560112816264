import { React } from 'react'
import { Navigate } from 'react-router-dom'

const RouteGuard = ({ children }) => {
  function hasJWT () {
    return !!localStorage.getItem('access')
  }
  return hasJWT() ? children : <Navigate to={{ pathname: '/login' }} />
}

export default RouteGuard
