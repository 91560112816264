import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import './table.css'
import React from 'react'
import { NumberInputValue, NumberInputRange, DateInputValue, DateInputRange, SelectInputValue } from './elements'
import {
  getNumberInputValueOperator,
  getNumberInputRangeOperator,
  getDateInputValueOperator,
  getDateInputRangeOperator,
  getSelectInputValueOperator
} from './operators'
import {
  getNumberFilterName,
  getNumberLowerFilterName,
  getNumberUpperFilterName,
  getDateFilterName,
  getDateLowerFilterName,
  getDateUpperFilterName,
  getSelectFilterName
} from './utils'
import { LinearProgress } from '@mui/material'
import { DataGrid, ruRU, GridColumnMenu } from '@mui/x-data-grid'

function CustomColumnMenu (props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null
      }}
    />
  )
}

export default function Table (props) {
  const theme = createTheme(ruRU)
  const navigate = useNavigate()

  function navigateToPregnant (path) {
    navigate(path)
  }

  const columns = () => {
    return props.columns.map((col) => {
      switch (col.field) {
        case 'current_week_of_pregnancy':
          return {
            ...col,
            filterOperators: [
              getNumberInputValueOperator(NumberInputValue, {
                handleFilterChange: props.handleFilterChange,
                getNumberFilterName
              }),
              getNumberInputRangeOperator(NumberInputRange, {
                handleFilterChange: props.handleFilterChange,
                getNumberLowerFilterName,
                getNumberUpperFilterName
              })
            ]
          }

        case 'date_of_filling_checklist':
          return {
            ...col,
            filterOperators: [
              getDateInputValueOperator(DateInputValue, {
                handleFilterChange: props.handleFilterChange,
                getDateFilterName
              }),
              getDateInputRangeOperator(DateInputRange, {
                sx: { width: '100px' },
                handleFilterChange: props.handleFilterChange,
                getDateLowerFilterName,
                getDateUpperFilterName
              })
            ],
            sortable: false
          }
        case 'date_of_doctors_appointment_or_hospitalization':
        case 'date_of_last_interaction':
          return {
            ...col,
            filterOperators: [
              getDateInputValueOperator(DateInputValue, {
                handleFilterChange: props.handleFilterChange,
                getDateFilterName
              }),
              getDateInputRangeOperator(DateInputRange, {
                sx: { width: '100px' },
                handleFilterChange: props.handleFilterChange,
                getDateLowerFilterName,
                getDateUpperFilterName
              })
            ],
            sortable: false
          }

        case 'diagnosis':
          return {
            ...col,
            filterOperators: [
              getSelectInputValueOperator(SelectInputValue, {
                handleFilterChange: props.handleFilterChange,
                getSelectFilterName,
                fields: props.fields
              })
            ],
            sortable: false
          }
        case 'diagnosis_status':
          return {
            ...col,
            filterOperators: [
              getSelectInputValueOperator(SelectInputValue, {
                handleFilterChange: props.handleFilterChange,
                getSelectFilterName,
                fields: props.fields
              })
            ],
            sortable: false
          }
        case 'last_interaction_result':
          return {
            ...col,
            filterOperators: [
              getSelectInputValueOperator(SelectInputValue, {
                handleFilterChange: props.handleFilterChange,
                getSelectFilterName,
                fields: props.fields
              })
            ],
            sortable: false
          }

        case 'record_type':
          return {
            ...col,
            filterOperators: [
              getSelectInputValueOperator(SelectInputValue, {
                handleFilterChange: props.handleFilterChange,
                getSelectFilterName,
                fields: props.fields
              })
            ],
            sortable: false
          }

        case 'id':
          return {
            ...col,
            sortable: false,
            filterable: false,
            disableColumnMenu: true
          }
        case 'full_name':
          return {
            ...col,
            filterable: false
          }
        default:
          return col
      }
    })
  }

  const handleFilterModelChange = (data, { reason }) => {
    if (reason === 'deleteFilterItem') {
      props.resetFilters()
    }
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <DataGrid
          onPaginationModelChange={props.handlePageChange}
          onSortModelChange={props.handleSortChange}
          onFilterModelChange={handleFilterModelChange}
          paginationModel={props.paginationModel}
          paginationMode='server'
          filterMode='server'
          sortingMode='server'
          editMode='row'
          onRowClick={(params) => {
            navigateToPregnant(`/card/${params.row.pregnancy_id}`)
          }}
          disableRowSelectionOnClick
          columns={columns()}
          rows={props.rows}
          rowCount={props.totalItems}
          loading={props.loading}
          slots={{
            loadingOverlay: LinearProgress,
            columnMenu: CustomColumnMenu
          }}
          slotProps={{
            baseFormControl: {
              sx: { width: 'auto' }
            }
          }}
          apiRef={props.apiRef}
        />
      </ThemeProvider>
    </div>
  )
}
