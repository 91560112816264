export const getSelectFilterName = (columnName) => {
  switch (columnName) {
    case 'pmsp':
      return 'pmsp_search'
    case 'status_of_last_call':
      return 'status_of_call_search'
    default:
      return ''
  }
}
