import removeEmptyData from './remove-empty-data'

export default function parseData (data) {
  const parsedData = {}
  Object.assign(parsedData, { hospitalization_facility: data.InteractionWithComplication.hospitalization_facility })
  Object.assign(parsedData, { interaction_result: data.InteractionWithComplication.interaction_result })
  Object.assign(parsedData, { comment: data.InteractionWithComplication.comment })
  Object.assign(parsedData, {
    date_of_doctors_appointment_or_hospitalization:
    data.InteractionWithComplication.date_of_doctors_appointment_or_hospitalization
  })
  Object.assign(parsedData, { doctor_username: data.doctor_username })
  Object.assign(parsedData, { pregnancy_complication_id: data.pregnancy_complication_id })
  return removeEmptyData(parsedData)
}
