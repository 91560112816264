import { React, Component } from 'react'
import { CardContent, CardHeader, Card, Button } from '@mui/material'
import PregnantTable from './elements/table/table'
import { getPregnancyIdViolations } from 'api/'
import { columnsProperties } from './utils/columns-properties'
import Loading from '../loading/loading'

export default class Violations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      columns: [],
      max_retries: 5,
      loading: true
    }
  }

  pregnancyViolationsWithParams = () => {
    this.pregnancyViolations(this.props.pregnant_id)
  }

  pregnancyViolations = (pregnantId) => {
    getPregnancyIdViolations(pregnantId)
      .then((res) => {
        if (res.data) {
          console.log('pregnancyViolations', res.data)
          this.setState(
            {
              rows: res.data.violations,
              columns: columnsProperties,
              loading: false
            }
          )
        }
      })
      .catch((err) => console.log('pregnancyViolations', err))
  }

  handleButtonClick = (target, data) => {
    return this.props.validation_of_personal_data_messages_string
      ? this.props.notify('error', this.props.validation_of_personal_data_messages_string)
      : this.props.setCurrentModal(target, data)
  }

  componentDidMount () {
    this.pregnancyViolationsWithParams()
  }

  render () {
    return (
      <Card className='pregnant_card_block'>
        {this.state.loading
          ? (
            <Loading></Loading>
            )
          : (
              <>
                <CardHeader className='block_title' title='Замечания и пожелания' />
                <CardContent>
                  <div style={{ height: '32.3vh' }}>
                    <PregnantTable
                      openRow={this.props.setCurrentModal}
                      rows={this.state.rows}
                      columns={this.state.columns}
                      className='pregnant_card_table'
                      hideFooter
                      hideFooterPagination={true}
                      hideFooterSelectedRowCount={true}
                    ></PregnantTable>
                  </div>
                  <div className='button_row'>
                    <Button
                      style={{
                        textTransform: 'none',
                        fontSize: '16px'
                      }}
                      onClick={() => this.handleButtonClick('new_violations', {})}
                      variant='contained'
                      color='primary'
                    >
                      Новая запись
                    </Button>
                    <Button
                      style={{
                        textTransform: 'none',
                        fontSize: '16px'
                      }}
                      onClick={() => this.handleButtonClick('all_violations', {})}
                      variant='contained'
                      color='primary'
                    >
                      Открыть все
                    </Button>
                  </div>
                </CardContent>
              </>
            )}
      </Card>
    )
  }
}
