import { getPregnancyIdComplications } from 'api/'
import parseData from '../utils/parse-data'
import columnsProperties from '../utils/columns-properties'

function pregnancyComplications (parent, pregnantId) {
  let timeoutResponse
  const timeoutResponsesCache = []
  for (let i = 0; i < parent.state.max_retries; i++) {
    timeoutResponse = setTimeout(() => {
      getPregnancyIdComplications(pregnantId)
        .then((res) => {
          if (res.data) {
            parent.setState(
              {
                rows: parseData(res.data.pregnancy_complications),
                columns: columnsProperties(),
                loading: false
              },
              () => {
                timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
              }
            )
          }
        })
        .catch((err) => console.log(err))
    }, 1500 * i)
    timeoutResponsesCache.push(timeoutResponse)
  }
}

export default pregnancyComplications
