import { React, useState, useEffect } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Grid, Typography, FormHelperText } from '@mui/material'

export default function PregnantPhones (props) {
  const [choices, setChoices] = useState(props.choices)
  useEffect(() => {
    if (props.choices !== choices) {
      setChoices(props.choices)
    }
  })

  return choices !== undefined && choices.length > 0
    ? (
        <div>
          <Select
            key={1}
            id={props.id}
            error={props.error}
            defaultValue={0}
            value={0}
            size='small'
            style={{ width: '100%' }}
            slotProps={{
              textField: {
                size: 'small',
                helperText: props.helperText || '',
                error: props.error
              }
            }}
          >
            {choices.map((obj, index) => {
              return (
                <MenuItem key={obj.phone} selected={index === 0} value={index}>
                  <Grid justifyContent='space-between' container>
                    <Grid xs={6} lg={6} md={6} item>
                      <Typography style={{ width: '100%' }}>{obj.name + ' ' + obj.phone}</Typography>
                    </Grid>
                    <Grid item container xs={2} lg={2} md={2}>
                      <Grid xs={6} lg={6} md={6} style={{ alignItems: 'center', display: 'flex' }} item>
                        <EditIcon
                          style={{ color: '#0D6EFD' }}
                          onClick={() => {
                            props.toggleEditPhoneModal(props.target, index)
                          }}
                        />
                      </Grid>
                      <Grid xs={6} lg={6} md={6} style={{ alignItems: 'center', display: 'flex' }} item>
                        <DeleteIcon style={{ color: 'red' }} onClick={() => props.removePhone(index, props.target)} />
                      </Grid>
                    </Grid>
                  </Grid>
                </MenuItem>
              )
            })}
          </Select>
          <FormHelperText style={{ color: '#d32f2f' }}>{props.helperText}</FormHelperText>
        </div>
      )
    : (
        <div>
          <Select
            key={2}
            value=''
            error={props.error}
            size='small'
            style={{ width: '100%' }}
            slotProps={{
              textField: {
                size: 'small',
                helperText: props.helperText || '',
                error: props.error
              }
            }}
          ></Select>
          <FormHelperText style={{ color: '#d32f2f' }}>{props.helperText}</FormHelperText>
        </div>
      )
}
