export const columnsProperties = [
  {
    field: 'date_of_creation',
    headerName: 'Дата',
    width: 110,
    filterable: false,
    disableColumnMenu: true
  },
  {
    field: 'violation_type',
    headerName: 'Замечания и пожелания',
    width: 180,
    filterable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value?.name || ''
    }
  },
  {
    field: 'violator',
    headerName: 'Нарушитель',
    width: 125,
    filterable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value?.name || ''
    }
  },
  {
    field: 'status',
    headerName: 'Статус',
    width: 90,
    filterable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value?.name || ''
    }
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
    filterable: false,
    disableColumnMenu: true
  }
]
