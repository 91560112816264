const checklistOfHealthObject = {
  id: '',
  complaint: [],
  danger_sign: [],
  taking_drugs: [],
  term_of_pregnancy: {
    name: ''
  },
  weight: ''
}

export default checklistOfHealthObject
