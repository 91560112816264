const statusColors = {
  'Дети (Перезвонить)': 'yellow',
  'Дети (Не дозвон)': 'gray',
  'Дети (Обработанный звонок)': 'green',
  'Дети (Некорректный номер)': 'purple',
  'Дети (Не беспокоить)': 'red',
  'Дети (Усиленный мониторинг)': 'red',
  'Дети (Госпитализирован)': 'red',
}

export default statusColors
