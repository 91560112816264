import { Box, TextField } from '@mui/material'
import { React, useEffect, useRef, useState } from 'react'

export default function NumberInputRange (props) {
  const { item, applyValue } = props

  const inputLowerRef = useRef(null)
  const inputUpperRef = useRef(null)

  const [whichInputSet, setWhichInputSet] = useState('lower-input')

  const [filterValueState, setFilterValueState] = useState(item.value ?? [null, null])

  useEffect(() => {
    if (whichInputSet === 'lower-input') {
      inputLowerRef.current.querySelector(`#lower-input-${item?.field}`).focus()
    } else if (whichInputSet === 'upper-input') {
      inputUpperRef.current.querySelector(`#upper-input-${item?.field}`).focus()
    }
  }, [whichInputSet])

  const updateFilterValue = (lowerBound, upperBound) => {
    setFilterValueState([lowerBound, upperBound])
    applyValue({ ...item, value: [lowerBound, upperBound] })
  }

  const handleLowerFilterChange = (event) => {
    const newUpperBound = event.target.value
    updateFilterValue(filterValueState[0], newUpperBound)
    props.handleFilterChange(props.getNumberLowerFilterName(item?.field), event.target.value)
    if (event.target.value) {
      setWhichInputSet('lower-input')
    }
  }

  const handleUpperFilterChange = (event) => {
    const newLowerBound = event.target.value
    updateFilterValue(newLowerBound, filterValueState[1])
    props.handleFilterChange(props.getNumberUpperFilterName(item?.field), event.target.value)
    if (event.target.value) {
      setWhichInputSet('upper-input')
    }
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px'
      }}
    >
      <TextField
        id={`lower-input-${item?.field}`}
        placeholder='От'
        label='От'
        variant='standard'
        value={filterValueState[1]}
        onChange={handleLowerFilterChange}
        type='number'
        sx={{ mr: 2 }}
        ref={inputLowerRef}
      />
      <TextField
        id={`upper-input-${item?.field}`}
        placeholder='До'
        label='До'
        variant='standard'
        value={filterValueState[0]}
        onChange={handleUpperFilterChange}
        type='number'
        ref={inputUpperRef}
      />
    </Box>
  )
}
