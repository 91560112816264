export default function setHeightVal (height) {
  let outputHeight
  let heightHelperText
  if (/^\d+$/.test(height) || height === '') {
    if (height === 0) {
      outputHeight = height
      heightHelperText = ''
    } else if (height < 80) {
      outputHeight = height > 0 ? height : 0 || ''
      heightHelperText = 'в поле рост допустимое значение: от 80 до 210'
      this.setState({
        validation: { ...this.state.validation, height_helper_text: 'в поле рост допустимое значение: от 80 до 210' }
      })
    } else if (height > 210) {
      outputHeight = 210
      heightHelperText = ''
    } else if (height === 210) {
      outputHeight = height
      heightHelperText = ''
    } else {
      outputHeight = height
      heightHelperText = ''
    }
    return [outputHeight, heightHelperText]
  }
}
