import { React, Component } from 'react'
import { Card } from '@mui/material'
import { getPregnancyIdQualityChecklist } from 'api/'
import parseData from './utils/parse-data'
import { columnsProperties } from './utils/columns-properties'

export default class ControlChecklist extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      max_retries: 5
    }
  }

  pregnancyQualityChecklist = (pregnantId) => {
    getPregnancyIdQualityChecklist(pregnantId)
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              rows: parseData(res.data.quality_checklists),
              columns: columnsProperties,
              loading: false
            }
          )
        }
      })
      .catch((err) => console.log(err))
  }

  componentDidMount () {
    this.pregnancyQualityChecklist(this.props.pregnant_id)
  }

  render () {
    return (
      <Card className='pregnant_card_block'>
      </Card>
    )
  }
}
