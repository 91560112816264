export default function checkIinWithBirthDate (iin, birthdate) {
  const iinYearSecondPart = iin.charAt(0) + iin.charAt(1)
  const iinMonth = iin.charAt(2) + iin.charAt(3)
  const iinDay = iin.charAt(4) + iin.charAt(5)
  const iinYearFirstPart =
    iin.charAt(6) === '1' || iin.charAt(6) === '2'
      ? 18
      : iin.charAt(6) === '3' || iin.charAt(6) === '4' || iin.charAt(6) === '0'
        ? 19
        : iin.charAt(6) === '5' || iin.charAt(6) === '6'
          ? 20
          : iin.charAt(6) === '0'
            ? undefined
            : ''
  const iinYear = iinYearFirstPart + iinYearSecondPart
  const [birthYear, birthMonth, birthDay] = birthdate.split('-')
  if (iinYearFirstPart === undefined) {
    return birthYear.slice(2, 4) === iinYearSecondPart && iinMonth === birthMonth && iinDay === birthDay
  }
  return birthYear === iinYear && iinMonth === birthMonth && iinDay === birthDay
}
