import { Alert } from '@mui/material'
import { React } from 'react'

export default function Notification (props) {
  return (
    <>
      {props.open
        ? (
            <Alert
              severity={props.type}
              variant='filled'
              style={{
                width: '25%',
                zIndex: '10',
                position: 'absolute',
                right: '0',
                marginRight: '20px',
                marginTop: '5px'
              }}
              onClose={() => {
                props.onClose('', '', true)
              }}
            >
              {props.message}
            </Alert>
          )
        : (
            <></>
          )}
    </>
  )
}
