import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Table from './elements/table/table'
import { getComplications, getComplicationFields } from 'api/'
import parseData from './utils/parse-data'
import debounce from './utils/debounce'
import { getSortValue } from './utils/get-sort-value'
import Header from './header/header'
import columnsProperties from './utils/columns-properties'
import Notification from 'components/notification/notification'
import { setFilterValues } from './utils/set-filter-values'
import { useSearchParams } from 'react-router-dom'
import Buttons from './buttons/buttons'
import { checkIsThereSomeValueInObject } from './utils/check-is-there-some-value-in-object'
import { Box, Switch, Typography } from '@mui/material'
import { gridFilterModelSelector, useGridApiRef } from '@mui/x-data-grid'
import './complications_list.css'

function PregnantsListView () {
  const apiRef = useGridApiRef()
  const queryParamsObject = useRef(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const [rows, setRows] = useState([])
  const [columns, setColunms] = useState([])
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 0)
  const [pageSize, setPageSize] = useState(parseInt(searchParams.get('page_size')) || 14)
  const [totalItems, setTotalItems] = useState(0)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [search, setSearch] = useState(searchParams.get('search') || '')
  const [sorting, setSorting] = useState(searchParams.get('order_by') || '')
  const [fields, setFields] = useState({
    diagnosis: [],
    diagnosis_status: [],
    last_interaction_result: [],
    record_type: []
  })
  const [filters, setFilters] = useState({
    desired_week_of_pregnancy: searchParams.get('desired_week_of_pregnancy') || '',
    further_past_week_of_pregnancy: searchParams.get('further_past_week_of_pregnancy') || '',
    most_recent_week_of_pregnancy: searchParams.get('most_recent_week_of_pregnancy') || '',
    date_of_filling_checklist_search: searchParams.get('date_of_filling_checklist_search') || '',
    further_past_date_of_filling_checklist_search:
      searchParams.get('further_past_date_of_filling_checklist_search') || '',
    most_recent_date_of_filling_checklist_search:
      searchParams.get('most_recent_date_of_filling_checklist_search') || '',
    date_of_doctors_appointment_or_hospitalization_search:
      searchParams.get('date_of_doctors_appointment_or_hospitalization_search') || '',
    most_recent_date_of_doctors_appointment_or_hospitalization_search:
      searchParams.get('most_recent_date_of_doctors_appointment_or_hospitalization_search') || '',
    further_past_date_of_doctors_appointment_or_hospitalization_search:
      searchParams.get('further_past_date_of_doctors_appointment_or_hospitalization_search') || '',
    date_of_last_interaction_search: searchParams.get('date_of_last_interaction_search') || '',
    most_recent_date_of_last_interaction_search: searchParams.get('most_recent_date_of_last_interaction_search') || '',
    further_past_date_of_last_interaction_search:
      searchParams.get('further_past_date_of_last_interaction_search') || '',
    diagnosis_search: searchParams.get('diagnosis_search') || '',
    diagnosis_status_search: searchParams.get('diagnosis_status_search') || '',
    last_interaction_result_search: searchParams.get('last_interaction_result_search') || '',
    record_type_search: searchParams.get('record_type_search') || ''
  })
  const [isSomeFilterActive, setIsSomeFilterActive] = useState(false)
  const [isFlagRegisteredSelected, setIsFlagRegisteredSelected] = useState(
    JSON.parse(searchParams.get('isFlagRegisteredSelected')) || false
  )

  const getData = useCallback((data) => {
    setLoading(true)
    getComplications({ ...data, page: data?.page + 1 })
      .then((res) => {
        if (res.data) {
          setRows(parseData(res.data.results))
          setColunms(columnsProperties())
          setTotalItems(res.data.count)
          setError('')
          setIsError(false)
        }
      })
      .catch((err) => {
        setError(err?.response?.statusText)
        setIsError(true)
      })
      .finally(() => setLoading(false))
  }, [])

  const debouncedGetData = useMemo(() => debounce(getData, 800), [getData])

  const handlePageChange = (data) => {
    setPage(data.page)
    setPageSize(data?.pageSize)
  }

  const handleFilterChange = (name, value) => {
    setFilterValues(setFilters, name, value)
    setPage(0)
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setPage(0)
  }

  const handleCloseError = () => {
    setError('')
    setIsError(false)
  }

  const handleSortChange = (data) => {
    setSorting(getSortValue(data))
  }

  const handleFlagRegisteredSelectedChange = (event) => {
    setIsFlagRegisteredSelected(event.target.checked)
  }

  const resetFilters = () => {
    setFilters({
      age_search: '',
      first_age: '',
      last_age: '',
      desired_week_of_pregnancy: '',
      further_past_week_of_pregnancy: '',
      most_recent_week_of_pregnancy: '',
      pmsp_search: '',
      pmsp_area_search: '',
      area_of_residence_search: '',
      date_of_registration_by_tmc_search: '',
      further_past_date_of_registration_by_tmc_search: '',
      most_recent_date_of_registration_by_tmc_search: ''
    })
  }

  // create (update) an object for sending a request
  const createQueryParamsObject = useCallback(() => {
    queryParamsObject.current = {
      page,
      page_size: pageSize,
      search,
      desired_week_of_pregnancy: filters.desired_week_of_pregnancy,
      further_past_week_of_pregnancy: filters.further_past_week_of_pregnancy,
      most_recent_week_of_pregnancy: filters.most_recent_week_of_pregnancy,
      date_of_filling_checklist_search: filters.date_of_filling_checklist_search,
      further_past_date_of_filling_checklist_search: filters.further_past_date_of_filling_checklist_search,
      most_recent_date_of_filling_checklist_search: filters.most_recent_date_of_filling_checklist_search,
      date_of_doctors_appointment_or_hospitalization_search:
        filters.date_of_doctors_appointment_or_hospitalization_search,
      most_recent_date_of_doctors_appointment_or_hospitalization_search:
        filters.most_recent_date_of_doctors_appointment_or_hospitalization_search,
      further_past_date_of_doctors_appointment_or_hospitalization_search:
        filters.further_past_date_of_doctors_appointment_or_hospitalization_search,
      date_of_last_interaction_search: filters.date_of_last_interaction_search,
      most_recent_date_of_last_interaction_search: filters.most_recent_date_of_last_interaction_search,
      further_past_date_of_last_interaction_search: filters.further_past_date_of_last_interaction_search,
      diagnosis_search: filters.diagnosis_search,
      diagnosis_status_search: filters.diagnosis_status_search,
      last_interaction_result_search: filters.last_interaction_result_search,
      record_type_search: filters.record_type_search,
      order_by: sorting,
      isFlagRegisteredSelected
    }
  }, [page, pageSize, sorting, search, filters, isFlagRegisteredSelected])

  // creating (updating) an object for sending a request
  useEffect(() => {
    createQueryParamsObject()
  }, [page, pageSize, sorting, search, filters, isFlagRegisteredSelected, createQueryParamsObject])

  // set search params to url
  useEffect(() => {
    setSearchParams(queryParamsObject.current)
  }, [page, pageSize, sorting, search, filters, isFlagRegisteredSelected, setSearchParams])

  // data request without delay
  useEffect(() => {
    getData(queryParamsObject.current)
  }, [page, sorting, isFlagRegisteredSelected, getData])

  // delayed data request
  useEffect(() => {
    debouncedGetData(queryParamsObject.current)
  }, [search, filters, debouncedGetData])

  // request fields for filtering
  useEffect(() => {
    getComplicationFields()
      .then((res) => {
        setFields({
          diagnosis: res.data.diagnosis,
          diagnosis_status: res.data.diagnosis_status,
          last_interaction_result: res.data.last_interaction_result,
          record_type: res.data.record_type
        })
      })
      .catch(() => {})
  }, [])

  // set filter button is active/inactive
  useEffect(() => {
    setIsSomeFilterActive(checkIsThereSomeValueInObject(filters))
  }, [filters])

  return (
    <div style={{ height: '90vh' }}>
      {error && <Notification isOpen={isError} handleClose={handleCloseError} message={error} type='error' />}
      <Header handleQueryChange={handleSearchChange} query={search} />
      <Buttons
        isSomeFilterActive={isSomeFilterActive}
        isSortingActive={sorting}
        isSearchingActive={search}
        onResetFilters={() => {
          resetFilters()
          apiRef.current.setFilterModel({ ...gridFilterModelSelector(apiRef), items: [] })
        }}
        onResetSorting={() => {
          setSorting('')
          apiRef.current.setSortModel([])
        }}
        onResetSearching={() => setSearch('')}
      />
      <Table
        loading={loading}
        rows={rows}
        columns={columns}
        table_title={'Список осложнений'}
        handlePageChange={handlePageChange}
        handleFilterChange={handleFilterChange}
        handleSortChange={handleSortChange}
        paginationModel={{
          page,
          pageSize
        }}
        totalItems={totalItems}
        fields={fields}
        resetFilters={resetFilters}
        apiRef={apiRef}
      />
      <Box className='select-box'>
        <Typography className='select-box__text' onClick={() => setIsFlagRegisteredSelected(false)}>
          Сняты с учёта
        </Typography>
        <Switch
          checked={isFlagRegisteredSelected}
          onChange={handleFlagRegisteredSelectedChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography
          className={isFlagRegisteredSelected ? 'select-box__text select-box__text_active' : 'select-box__text'}
          onClick={() => setIsFlagRegisteredSelected(true)}
        >
          На учёте
        </Typography>
      </Box>
    </div>
  )
}

export default PregnantsListView
