import axios from 'axios'
import parseDataForPost from '../../views/pregnant_card/pregnant_modal_window/components/violations/utils/parse-data-for-post'

export async function postQualityChecklistViolations (data) {
  const request = await axios({
    method: 'post',
    url: 'quality_checklist/violations',
    data: parseDataForPost(data)
  })
  return request
}
