import { React } from 'react'
import { Divider } from '@mui/material'

const dividerStyle = {
  border: '1px solid #D1D2D3',
  marginTop: '10px',
  marginBottom: '10px'
}

export const StyledDivider = () => {
  return (
    <Divider
    style={dividerStyle}
    fullWidth
    />
  )
}
