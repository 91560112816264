import axios from 'axios'

/**
 * Описание функции.
 * @code {String} - Код получаемый после авторизации в zoom.
 * @phone {String} - Номер телефона, который мы выбрали.
 * @returns {request} - Ccылка на конференцию.
 */

const postStartMeeting = (phone) => {
  const data = { phone }
  const request = axios.post('zoom/start_meeting', data)
  return request
}

export default postStartMeeting
