export const getDateFilterName = (columnName) => {
  switch (columnName) {
    case 'date_of_registration_by_tmc':
      return 'date_of_registration_by_tmc_search'
    case 'date_of_call':
      return 'date_of_call_day_search'
    default:
      return ''
  }
}
