export const getSelectFilterName = (columnName) => {
  switch (columnName) {
    case 'pmsp':
      return 'pmsp_search'
    case 'pmsp_area':
      return 'pmsp_area_search'
    case 'area_of_residence':
      return 'area_of_residence_search'
    case 'status_of_call':
      return 'status_of_call_search'
    default:
      return ''
  }
}
