export default function parseDataForPostHealthChecklistComplication (data) {
  const clearData = {}
  Object.assign(clearData, { anamnesis: data.PregnancyComplication.anamnesis })
  Object.assign(clearData, { checklist_id: data.PregnancyComplication.checklist_id })
  if (data.PregnancyComplication.complaint.length > 0) {
    Object.assign(clearData, { complaint: data.PregnancyComplication.complaint })
  }
  if (data.PregnancyComplication.danger_sign.length > 0) {
    Object.assign(clearData, { danger_sign: data.PregnancyComplication.danger_sign })
  }
  if (data.PregnancyComplication.taking_drugs.length > 0) {
    Object.assign(clearData, { taking_drugs: data.PregnancyComplication.taking_drugs })
  }
  Object.assign(clearData, { weight: data.ChecklistOfHealth.weight })
  Object.assign(clearData, { pregnancy_id: data.pregnancy_id })
  Object.assign(clearData, { diagnosis: data.PregnancyComplication.diagnosis })
  Object.assign(clearData, { diagnosis_status: data.PregnancyComplication.diagnosis_status })
  Object.assign(clearData, { record_type: data.PregnancyComplication.record_type })
  Object.assign(clearData, { term_of_pregnancy: { name: data.data.term_of_pregnancy } })
  Object.assign(clearData, { doctor_username: data.doctor_username })
  return clearData
}
