export function parseData (data) {
  if (data.results && data.results.length > 0) {
    for (let i = 0; i < data.results.length; i++) {
      Object.assign(data.results[i], { index: i })
      Object.assign(data.results[i], { can_delete_pregnancy: data.can_delete_pregnancy ? i : false })
      // data.results[i]['index'] = i
      // data.results[i]['can_delete_pregnancy'] = data.can_delete_pregnancy ? i : false
    }
    return data.results
  }
  return []
}
