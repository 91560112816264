import { ColumnsProperties } from './columns-properties'

export function parseColumns (data) {
  const columnNames = ColumnsProperties()
  const columnsData = Object.keys(data)
  const columns = []
  for (let i = 0; i < columnsData.length; i++) {
    const dict = {}
    Object.assign(dict, { field: columnsData[i] })
    Object.assign(dict, { headerName: columnNames[columnsData[i]].headerName })
    Object.assign(dict, { width: columnNames[columnsData[i]].width })
    // dict['field'] = columnsData[i]
    // dict['headerName'] = columnNames[columnsData[i]].headerName
    // dict['width'] = columnNames[columnsData[i]].width
    columns.push(dict)
  }
  return columns
}
