import { React, forwardRef, useImperativeHandle, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

function Loading (props, ref) {
  const [loading, setLoading] = useState(0)

  useImperativeHandle(
    ref,
    () => ({
      start: () => {
        const loadingCount = loading + 1
        setLoading(loadingCount)
      },
      stop: () => {
        const loadingCount = loading > 0 ? loading - 1 : 0
        setLoading(loadingCount)
      },
      isLoading: () => loading >= 1
    }),
    []
  )

  if (!loading) {
    return null
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}
export default forwardRef(Loading)
