export function ColumnsProperties() {
  return [
    {
      field: 'pregnant_full_name',
      headerName: 'ФИО',
      width: 300,
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: 'pregnant_iin',
      headerName: 'ИИН',
      width: 150,
      valueGetter: (params) => {
        return params.value
      },
    },
    {
      field: 'info_about_pregnant_at_current_pregnancy',
      headerName: 'Дата родов',
      width: 300,
      valueGetter: (params) => {
        return params.value?.date_of_childbirth
      },
    },
    {
      field: 'pmsp',
      headerName: 'ПМСП',
      width: 150,
      valueGetter: (params) => {
        return params.value.name
      },
    },
    {
      field: 'date_of_last_call',
      headerName: 'Дата последнего звонка',
      width: 300,
    },
    {
      field: 'status_of_last_call',
      headerName: 'Статус последнего звонка',
      width: 300,
      valueGetter: (params) => {
        return params.value?.name
      },
    },

    // {
    //   field: 'status_of_call',
    //   headerName: 'Статус последнего звонка',
    //   width: 300,
    //   valueGetter: (params) => {
    //     return params.value?.name || ''
    //   },
    // },
    // {
    //   field: 'date_of_registration_by_tmc',
    //   headerName: 'Дата взятия',
    //   width: 230,
    //   valueGetter: (params) => {
    //     return params.value?.split('T')[0]
    //   },
    // },
  ]
}
