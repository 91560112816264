export const getSelectFilterName = (columnName) => {
  switch (columnName) {
    case 'diagnosis':
      return 'diagnosis_search'
    case 'diagnosis_status':
      return 'diagnosis_status_search'
    case 'last_interaction_result':
      return 'last_interaction_result_search'
    case 'record_type':
      return 'record_type_search'
    default:
      return ''
  }
}
