import { React, Component } from 'react'
import { CardContent, Card, Button } from '@mui/material'
import columnsProperties from './utils/columns-properties'
import { gethealthChecklistComplicationAll } from 'api/'
import PregnantTable from './table/table'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import './all_complications.css'

export default class AllComplications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      columns: [],
      loading: true,
      data: [],
      max_retries: 5
    }
  }

  healthChecklistComplicationAll = (pregnantId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        gethealthChecklistComplicationAll(pregnantId)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  rows: res.data.pregnancy_complications,
                  columns: columnsProperties(),
                  loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  componentDidMount () {
    this.healthChecklistComplicationAll(this.props.pregnant_id)
  }

  render () {
    return (
      <Card>
        <CardContent>
          <div
            style={{
              height: '80vh',
              width: '90vw',
              position: 'relative',
              left: '99.5%',
              margin: '0 -100%',
              marginRight: '0 -100%'
            }}
          >
            <div className='sidebar_close_button' style={{ padding: '0.5%', width: '100%' }}>
              <Button onClick={() => {}}></Button>
              <a onClick={() => this.props.setCurrentModal(null, {})} style={{ pointerEvents: 'auto !important' }}>
                <CloseOutlinedIcon></CloseOutlinedIcon>
              </a>
            </div>
            <PregnantTable
              loading={this.state.loading}
              fullWidth={this.props.fullWidth}
              rows={this.state.rows}
              columns={this.state.columns}
              openRow={this.props.setCurrentModal}
              className='pregnant_card_table'
              hideFooterSelectedRowCount={true}
            ></PregnantTable>
          </div>
        </CardContent>
      </Card>
    )
  }
}
