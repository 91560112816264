export const getDateFilterName = (columnName) => {
  switch (columnName) {
    case 'date_of_filling_checklist':
      return 'date_of_filling_checklist_search'
    case 'date_of_doctors_appointment_or_hospitalization':
      return 'date_of_doctors_appointment_or_hospitalization_search'
    case 'date_of_last_interaction':
      return 'date_of_last_interaction_search'
    default:
      return ''
  }
}
