import { Typography, Grid, Dialog, Button } from '@mui/material'
import { React, useEffect, useState } from 'react'
import {
  PregnantDatepicker,
  PregnantSelect,
  PregnantMultiselectValuesPlaceholder,
  PregnantMultiSelect,
} from 'elements/'
import {
  gaveBirthPregnantGetId,
  gaveBirthPregnantFields,
  gaveBirthPregnantSave,
  gaveBirthPregnantHistoryGet,
} from 'api/'
import pregnantFieldsObject from '../objects/pregnant-fields-object'
import PregnantRequestsTable from '../elements/pregnant-requests-table'
import columns from '../utils/pregnant-requests-columns'
import { borderStyles, borderStylesWithMaxWidth, blockTitleStyles, infoTitleStyles, inputLabelStyles } from './styles'
import { PropTypes } from 'prop-types'
import { TextField } from '../elements/text-field'

export default function Pregnant(props) {
  const [pregnancyId, setPregnancyId] = useState(props.pregnancy_id)
  const [data, setData] = useState(undefined)
  const [healthComplaints, setHealthComplaints] = useState([])
  const [descriptionOfHealthComplaints, setDescriptionOfHealthComplaints] = useState('')
  const [healthComplaintsComment, setHealthComplaintsComment] = useState('')
  const [complaintsAndSuggestionsAboutPmspWork, setComplaintsAndSuggestionsAboutPmspWork] = useState([])
  const [descriptionOfComplaintsAndSuggestionsAboutPmspWork, setDescriptionOfComplaintsAndSuggestionsAboutPmspWork] =
    useState('')
  const [complaintsAndSuggestionsAboutPmspWorkComment, setComplaintsAndSuggestionsAboutPmspWorkComment] = useState('')
  const [medicalProcedures, setMedicalProcedures] = useState({ name: '' })
  const [requestStatus, setRequestStatus] = useState({ name: '' })
  const [validation, setValidation] = useState({ btn_text: 'Сохранить', info: '' })
  const [rows, setRows] = useState([])
  const [comment, setComment] = useState(data?.info_about_pregnant_at_current_pregnancy?.comment)
  const [fields, setFields] = useState(pregnantFieldsObject)

  const convertToNameStr = (arr) => {
    const resArray = []
    arr.reduce((accumulator, currentVal, index) => {
      return resArray.push({ name: currentVal })
    }, 0)
    return resArray
  }

  const PregnantHistoryGet = (id) => {
    gaveBirthPregnantHistoryGet(id)
      .then((res) => {
        if (res.data) {
          const data = []
          res.data.reduce((accumulator, currentVal, index) => {
            currentVal.id = index
            return data.push(currentVal)
          }, 0)
          console.log(data)
          setRows(data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSetMedicalProcedures = (id, val) => {
    setMedicalProcedures({ name: val })
  }
  const handleSetRequestStatus = (id, val) => {
    setRequestStatus({ name: val })
  }

  const handleSetHealthComplaints = (id, val) => {
    setHealthComplaints(convertToNameStr(val))
  }
  const handlesetComplaintsAndSuggestionsAboutPmspWork = (id, val) => {
    setComplaintsAndSuggestionsAboutPmspWork(convertToNameStr(val))
  }
  const postPregnantData = () => {
    const postData = {
      pregnancy_id: pregnancyId,
      health_complaints: healthComplaints,
      complaints_and_suggestions_about_pmsp_work: complaintsAndSuggestionsAboutPmspWork,
      doctor_username: localStorage.getItem('username'),
    }
    if (complaintsAndSuggestionsAboutPmspWorkComment) {
      Object.assign(postData, {
        complaints_and_suggestions_about_pmsp_work_comment: complaintsAndSuggestionsAboutPmspWorkComment,
      })
    }
    if (descriptionOfComplaintsAndSuggestionsAboutPmspWork) {
      Object.assign(postData, {
        description_of_complaints_and_suggestions_about_pmsp_work: descriptionOfComplaintsAndSuggestionsAboutPmspWork,
      })
    }
    if (descriptionOfHealthComplaints) {
      Object.assign(postData, { description_of_health_complaints: descriptionOfHealthComplaints })
    }
    if (healthComplaintsComment) {
      Object.assign(postData, { health_complaints_comment: healthComplaintsComment })
    }
    if (comment) {
      Object.assign(postData, { comment })
    }
    if (medicalProcedures.name) {
      Object.assign(postData, { medical_procedures: medicalProcedures })
    }
    if (requestStatus.name) {
      Object.assign(postData, { request_status: requestStatus })
    }

    gaveBirthPregnantSave(postData)
      .then((res) => {
        if (res.data) {
          console.log(res.data)
          props.setKey(props.setKeyProp + 1)
          props.handlePregnantModalClose()
          PregnantHistoryGet(pregnancyId)
          setValidation({ btn_text: 'Сохранено', info: '' })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getFieldsData = () => {
    gaveBirthPregnantFields()
      .then((res) => {
        if (res.data) {
          setFields(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getPregnancyData = (pregnancyId) => {
    if (!pregnancyId) {
      return 0
    }
    gaveBirthPregnantGetId(pregnancyId)
      .then((res) => {
        if (res.data) {
          setData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    if (props.pregnancy_id !== pregnancyId) {
      setPregnancyId(props.pregnancy_id)
    }
  })
  useEffect(() => {
    if (data) {
      setComment(data?.info_about_pregnant_at_current_pregnancy?.comment)
    }
  }, [data])
  useEffect(() => {
    if (!isNaN(parseInt(pregnancyId))) {
      getPregnancyData(pregnancyId)
      getFieldsData()
      PregnantHistoryGet(pregnancyId)
    }
  }, [pregnancyId])

  return (
    <Dialog
      onClose={props.handlePregnantModalClose}
      fullScreen
      open={props.toggled}
      style={{ maxWidth: '95vw', maxHeight: '90vh', marginTop: '4vh', marginLeft: '2.4vw' }}
    >
      <Grid container p={3}>
        <Grid xs={12} md={12} lg={12} item container justifyContent='space-between'>
          <Grid xs={6} md={6} lg={6} item>
            <Typography style={blockTitleStyles}>Заполнить информацию о женщине</Typography>
          </Grid>
          <Grid textAlign='end' xs={6} md={6} lg={6} item>
            <Typography style={{ cursor: 'pointer' }} onClick={() => props.handlePregnantModalClose()}>
              X
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={12} lg={12} item container style={borderStyles} p={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography style={infoTitleStyles}>Личные данные</Typography>
          </Grid>
          <Grid item xs={12} lg={12} md={12} container>
            <Grid item xs={2} lg={2} md={2}>
              <Typography style={inputLabelStyles}>ФИО</Typography>
              <TextField value={data?.pregnant?.full_name} disabled={true} />
            </Grid>
            <Grid item xs={2} lg={2} md={2}>
              <Typography style={inputLabelStyles}>ИИН</Typography>
              <TextField value={data?.pregnant?.iin} />
            </Grid>
            <Grid item xs={2} lg={3} md={2}>
              <Typography style={inputLabelStyles}>Дата родов</Typography>
              <PregnantDatepicker value={data?.info_about_pregnant_at_current_pregnancy?.date_of_childbirth} />
            </Grid>
            <Grid item xs={2} lg={2} md={2}>
              <Typography style={inputLabelStyles}>ПМСП</Typography>
              <Typography>{data?.pmsp?.name}</Typography>
            </Grid>
            <Grid item xs={2} lg={2} md={2}>
              <Typography style={inputLabelStyles}>Адрес фактического проживания</Typography>
              <Typography>{data?.info_about_pregnant_at_current_pregnancy?.residential_address}</Typography>
            </Grid>
            {Boolean(data?.info_about_pregnant_at_current_pregnancy?.risk_factor.length) && (
              <Grid item xs={2} lg={2} md={2}>
                <Typography style={inputLabelStyles}>Факторы риска</Typography>
                <PregnantMultiselectValuesPlaceholder
                  key={1}
                  choices={data?.info_about_pregnant_at_current_pregnancy.risk_factor}
                />
              </Grid>
            )}
          </Grid>
          {Boolean(data?.info_about_pregnant_at_current_pregnancy?.accompanying_illness.length) && (
            <Grid item xs={12} lg={12} md={12} container>
              <Grid item xs={2} lg={2} md={2}>
                <Typography style={inputLabelStyles}>Сопутствующие заболевания</Typography>
                <PregnantMultiselectValuesPlaceholder
                  key={2}
                  choices={data?.info_about_pregnant_at_current_pregnancy?.accompanying_illness}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} lg={12} md={12} container>
            <Typography style={inputLabelStyles}>Комментарий</Typography>
            <TextField onChange={setComment} fullWidth={true} value={comment} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} container spacing={2} justifyContent='space-between' mt={4} pl={2}>
          <Grid item xs={6} md={6} lg={6} style={borderStylesWithMaxWidth} p={3} container>
            <Typography style={infoTitleStyles}>Жалобы по здоровью</Typography>
            <Grid xs={12} md={12} lg={12} item container spacing={2}>
              <Grid xs={4} md={4} lg={4} item>
                <Typography style={inputLabelStyles}>Категория жалобы по здоровью</Typography>
                <PregnantMultiSelect
                  key={3}
                  id='complaints_and_suggestions_about_pmsp_work'
                  choices={fields?.health_complaints}
                  value={healthComplaints.map((el) => el.name)}
                  onChange={handleSetHealthComplaints}
                />
              </Grid>
              <Grid xs={4} md={4} lg={4} item>
                <Typography style={inputLabelStyles}>Врачебные мероприятия</Typography>
                <PregnantSelect
                  key={4}
                  id='medical_procedures'
                  choices={fields?.medical_procedures}
                  value={medicalProcedures.name}
                  onChange={handleSetMedicalProcedures}
                />
              </Grid>
              <Grid xs={4} md={4} lg={4} item>
                <Typography style={inputLabelStyles}>Оператор (статус обращения)</Typography>
                <PregnantSelect
                  key={5}
                  id='request_status'
                  choices={fields?.request_status}
                  value={requestStatus.name}
                  onChange={handleSetRequestStatus}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Описание жалобы по здоровью</Typography>
              <TextField
                id='description_of_health_complaints'
                value={descriptionOfHealthComplaints}
                onChange={setDescriptionOfHealthComplaints}
                fullWidth={true}
              />
            </Grid>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Комментарий</Typography>
              <TextField
                id='health_complaints_comment'
                value={healthComplaintsComment}
                onChange={setHealthComplaintsComment}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} md={6} lg={6} style={borderStylesWithMaxWidth} p={3} container>
            <Typography style={infoTitleStyles}>Замечания или пожелания по работе ПМСП</Typography>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Категория замечаний или пожеланий по работе ПМСП</Typography>
              <PregnantMultiSelect
                key={6}
                id='complaints_and_suggestions_about_pmsp_work'
                choices={fields?.complaints_and_suggestions_about_pmsp_work}
                value={complaintsAndSuggestionsAboutPmspWork.map((el) => el.name)}
                onChange={handlesetComplaintsAndSuggestionsAboutPmspWork}
              />
            </Grid>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Описание замечания или пожелания по работе ПМСП</Typography>
              <TextField
                id='description_of_complaints_and_suggestions_about_pmsp_work'
                value={descriptionOfComplaintsAndSuggestionsAboutPmspWork}
                onChange={setDescriptionOfComplaintsAndSuggestionsAboutPmspWork}
                fullWidth={true}
              />
            </Grid>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Комментарий</Typography>
              <TextField
                id='complaints_and_suggestions_about_pmsp_work_comment'
                value={complaintsAndSuggestionsAboutPmspWorkComment}
                onChange={setComplaintsAndSuggestionsAboutPmspWorkComment}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <PregnantRequestsTable rows={rows} columns={columns} />
        </Grid>
        <Grid item xs={12} lg={12} md={12} textAlign='end' mt={2}>
          <Button
            onClick={() => postPregnantData()}
            size='small'
            style={{ fontSize: '16px', fontWeight: 'bold', width: '15%', borderRadius: '6.7px' }}
            color='primary'
            variant='contained'
          >
            {validation.btn_text}
          </Button>
          <Typography style={{ color: 'red' }}>{validation.info}</Typography>
        </Grid>
      </Grid>
    </Dialog>
  )
}

Pregnant.propTypes = {
  pregnancy_id: PropTypes.number,
  setKey: PropTypes.func,
  setKeyProp: PropTypes.func,
  handlePregnantModalClose: PropTypes.func,
  toggled: PropTypes.bool,
}
