import validationObject from '../../data-structure/validation-object'
import {
  isFirstDateLowerThanEstimatedDateForEightMonths,
  isFirstDateMoreThanEstimatedDateForTenMonths,
  // isInputDateMoreThanNowDateForTwentyMonths,
  isFirstDayOfLastMenstruationTodayOrLaterThanNowValidation
} from './'

export default function AssertFirstDayOfLastMenstruationValidation (
  firstDateofLastMenstruation,
  estimatedDateOfChildBirth
) {
  if (!firstDateofLastMenstruation) {
    return validationObject.first_day_of_last_menstruation_helper_text
  }
  if (!estimatedDateOfChildBirth) {
    return validationObject.estimated_date_of_childbirth_helper_text
  }
  if (isFirstDateLowerThanEstimatedDateForEightMonths(firstDateofLastMenstruation, estimatedDateOfChildBirth)) {
    return 'Между ПДПМ и предполагаемой датой родов не может быть менее 8 месяцев'
  }
  if (isFirstDateMoreThanEstimatedDateForTenMonths(firstDateofLastMenstruation, estimatedDateOfChildBirth)) {
    return 'Между ПДПМ и предполагаемой датой родов не может быть более 10 месяцев'
  }
  // if (isInputDateMoreThanNowDateForTwentyMonths(firstDateofLastMenstruation)) {
  //   return 'ПДМП не может быть ранее 18 месяцев от текущей даты'
  // }
  if (isFirstDayOfLastMenstruationTodayOrLaterThanNowValidation(firstDateofLastMenstruation)) {
    return 'ПДМП не можеть быть сегодня или позже текущей даты'
  }
  return ''
}
