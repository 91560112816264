import { Box, Chip, Collapse } from '@mui/material'
import { React } from 'react'
import { PropTypes } from 'prop-types'

export default function Buttons (props) {
  const {
    isSomeFilterActive,
    isSortingActive,
    isSearchingActive,
    onResetFilters,
    onResetSorting,
    onResetSearching
  } = props

  return (
    <Box sx={{ mb: '6px', display: 'flex', gap: '8px' }}>
      {isSomeFilterActive && (
        <Collapse in={isSomeFilterActive} style={{ transitionDelay: '300ms' }}>
          <Chip variant='contained' onClick={onResetFilters} label='Сбросить фильтры' />
        </Collapse>
      )}
      {isSortingActive && (
        <Collapse in={isSortingActive} style={{ transitionDelay: '300ms' }}>
          <Chip variant='contained' onClick={onResetSorting} label='Сбросить сортировку' />
        </Collapse>
      )}
      {isSearchingActive && (
        <Collapse in={isSearchingActive} style={{ transitionDelay: '300ms' }}>
          <Chip variant='contained' onClick={onResetSearching} label='Сбросить поиск' />
        </Collapse>
      )}
    </Box>
  )
}

Buttons.propTypes = {
  isSomeFilterActive: PropTypes.bool,
  isSortingActive: PropTypes.bool,
  isSearchingActive: PropTypes.bool,
  onResetFilters: PropTypes.func,
  onResetSorting: PropTypes.func,
  onResetSearching: PropTypes.func
}
