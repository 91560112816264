const columnProperties = [
  {
    field: 'date_of_call',
    headerName: 'Дата',
    width: 120,
    filterable: false,
    disableColumnMenu: true
  },
  {
    field: 'doctor_full_name',
    headerName: 'Врач ТМЦ',
    width: 105,
    filterable: false,
    disableColumnMenu: true
  },
  {
    field: 'type_of_call',
    headerName: 'Тип',
    width: 120,
    filterable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value?.name || ''
    }
  },
  {
    field: 'status_of_call',
    headerName: 'Статус',
    width: 190,
    filterable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value?.name || ''
    }
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
    filterable: false,
    disableColumnMenu: true
  }
]

export default columnProperties
