import { React, useEffect, useState, useRef } from 'react'
import {
  Grid,
  Container,
  Typography,
  FormControl,
  InputLabel,
  InputAdornment,
  FilledInput,
  Button,
  IconButton
} from '@mui/material'
import Notification from './notification/notification'
import { setAuthToken } from 'index'
import PersonIcon from '@mui/icons-material/Person'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import LoginLogo from './logo.png'
import { apiToken } from 'api/'
import './login.css'

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const useHasChanged = (value) => {
  const prevVal = usePrevious(value)
  return prevVal !== value
}

export const Login = () => {
  const [passwordVisible, setPasswordVisibility] = useState(false)
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationType, setNotificationType] = useState('')
  const [notificationOpen, setNotificationOpen] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const loginHasChanged = useHasChanged(login)
  const submit = () =>
    apiToken(login, password)
      .then((res) => {
        if (res.access) {
          localStorage.setItem('access', res.access)
          setAuthToken(res.access)
          localStorage.setItem('refresh', res.refresh)
          localStorage.setItem('full_name', res.full_name)
          localStorage.setItem('username', login)
          window.location.href = '/'
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          setLoginError(true)
          notify('error', 'Неверные учетные данные')
        } else {
          notify('error', 'Ошибка авторизации. Свяжитесь со службой поддержкии')
        }
      })
  const notify = (type, message, close) => {
    if (close) {
      setNotificationOpen(false)
    } else {
      setNotificationType(type)
      setNotificationMessage(message)
      setNotificationOpen(true)
    }
  }
  useEffect(() => {
    if (localStorage.getItem('access')) {
      window.location.href = '/'
    }
    if (loginError) {
      if (loginHasChanged) {
        setLoginError(false)
      }
    }
  })

  return (
    <>
      <Notification onClose={notify} open={notificationOpen} message={notificationMessage} type={notificationType} />
      <Container
        style={{
          position: 'absolute',
          left: '50%',
          top: '40%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Grid gap={3} container>
          <Grid item xs={12} lg={12} md={12}>
            <img src={LoginLogo} />
          </Grid>

          <Grid item xs={12} lg={12} md={12}>
            <Typography variant='h6'>Вход в портал МИС</Typography>
          </Grid>

          <Grid mt={4} item xs={12} lg={12} md={12}>
            <FormControl error={loginError}>
              <InputLabel>Логин</InputLabel>
              <FilledInput
                id='login'
                size='small'
                value={login}
                onChange={(event) => setLogin(event.target.value)}
                endAdornment={
                  <InputAdornment position='end'>
                    <div style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                      <PersonIcon></PersonIcon>
                    </div>
                  </InputAdornment>
                }
              ></FilledInput>
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={12} md={12}>
            <FormControl error={loginError}>
              <InputLabel>Пароль</InputLabel>
              <FilledInput
                id='password'
                type={passwordVisible ? 'text' : 'password'}
                size='small'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyUp={(event) => event.key === 'Enter' && submit(login, password)}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setPasswordVisibility(!passwordVisible)}>
                      {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              ></FilledInput>
            </FormControl>
          </Grid>

          <Grid mt={4} item xs={12} lg={12} md={12}>
            <Button
              style={{ width: '13%', fontWeight: 'bold' }}
              onClick={() => submit(login, password)}
              variant='contained'
              color='primary'
            >
              Войти
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
