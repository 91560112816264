import axios from 'axios'

export async function patchPrivateDataPregnantId (pregnantId, pregnantData) {
  const request = await axios({
    method: 'patch',
    url: `private_data/pregnant/update/${pregnantId}`,
    data: pregnantData
  })
  return request
}
