const columnsProperties = [
  { field: 'id', headerName: 'ID', width: 125 },
  { field: 'full_name', headerName: 'ФИО', width: 350 },
  {
    field: 'violator',
    headerName: 'Нарушитель',
    width: 200,
    valueGetter: (params) => {
      return params.value?.name
    }
  },
  {
    field: 'type_of_violation',
    headerName: 'Замечания и пожелания',
    width: 150,
    valueGetter: (params) => {
      return params.value?.name
    }
  },
  {
    field: 'status',
    headerName: 'Статус',
    width: 150,
    valueGetter: (params) => {
      return params.value?.name
    }
  },
  { field: 'date_of_set_status', headerName: 'Дата последнего взаимодействия', width: 150 }
]

export default columnsProperties
