import { React, Component } from 'react'
import { CardContent, CardHeader, Card, Button } from '@mui/material'
import PregnantTable from './elements/table/table'
import { getPregnancyIdCallHistory } from 'api/'
import columnProperties from './utils/columns-properties'
import CallRecordingModal from './call-recording-modal/call-recording-modal'

export default class CallsHistory extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      columns: [],
      call_recording_modal_toggled: false,
      loading: true,
      max_retries: 5
    }
  }

  togglePhoneModal = () => {
    this.setState({
      call_recording_modal_toggled: !this.state.call_recording_modal_toggled
    })
  }

  pregnancyIdCallHistory = () => {
    getPregnancyIdCallHistory(this.props.pregnant_id).then((res) => {
      if (res.data) {
        console.log(res.data, 'CALL HISTORY')
        this.setState({
          rows: res.data.call_history,
          columns: columnProperties
        })
      }
    })
  }

  handleButtonClick = (target, data) => {
    console.log(this.props.validation_of_personal_data_messages_string, 'ASDASDASDSDASDASDAS')
    return this.props.validation_of_personal_data_messages_string
      ? this.props.notify('error', this.props.validation_of_personal_data_messages_string)
      : this.props.setCurrentModal(target, data)
  }

  componentDidMount () {
    this.pregnancyIdCallHistory()
  }

  render () {
    return (
      <Card className='pregnant_card_block'>
        <CardHeader className='block_title' title='История звонков' />
        <CardContent>
          <div style={{ height: '32.3vh' }}>
            <PregnantTable
              onClick={this.togglePhoneModal}
              rows={this.state.rows}
              columns={this.state.columns}
              className='pregnant_card_table'
              hideFooterPagination={true}
              hideFooterSelectedRowCount={true}
            ></PregnantTable>
          </div>
          <div className='button_row'>
            <Button
              style={{ textTransform: 'none', fontSize: '16px' }}
              onClick={() => this.handleButtonClick('all_history', {})}
              variant='contained'
              color='primary'
            >
              Все записи
            </Button>
          </div>
        </CardContent>
        <CallRecordingModal onClick={this.togglePhoneModal} toggled={this.state.call_recording_modal_toggled} />
      </Card>
    )
  }
}
