import axios from 'axios'

/**
 * Получаем ссылку на авторизацию в ZOOM.
 */

const getPersonalMeetingLink = () => {
  // Берем ссылку авторизации ZOOM
  const request = axios.get('zoom/get_personal_meeting_link')
  return request
}

export default getPersonalMeetingLink
