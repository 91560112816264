import axios from 'axios'

export default async function postQualityChecklist (data) {
  const request = await axios({
    method: 'post',
    url: 'quality_checklist/',
    data
  })
  return request
}
