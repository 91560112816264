import Modal from 'react-bootstrap/Modal'
import GaveBirthList from 'views/gave_birth_info_about_pregnant/gave-birth-list'

export function PregnantInfoModal({ show, iin, onHide }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='xl'
      // aria-labelledby='contained-modal-title-vcenter'
      enforceFocus={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Заполнение информации о родившей</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GaveBirthList iin={iin} closePregnantInfoModal={onHide} />
      </Modal.Body>
    </Modal>
  )
}
