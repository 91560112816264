export default function parseDataForPostHealthChecklist (data) {
  const clearData = {}
  if (data.ChecklistOfHealth.complaint.length > 0) {
    Object.assign(clearData, { complaint: data.ChecklistOfHealth.complaint })
  }
  if (data.ChecklistOfHealth.danger_sign.length > 0) {
    Object.assign(clearData, { danger_sign: data.ChecklistOfHealth.danger_sign })
  }
  if (data.ChecklistOfHealth.taking_drugs.length > 0) {
    Object.assign(clearData, { taking_drugs: data.ChecklistOfHealth.taking_drugs })
  }
  Object.assign(clearData, { taking_drugs: data.ChecklistOfHealth.taking_drugs })
  Object.assign(clearData, { diagnosis: data.ChecklistOfHealth.diagnosis })
  Object.assign(clearData, { diagnosis_status: data.ChecklistOfHealth.diagnosis_status })
  Object.assign(clearData, { record_type: data.ChecklistOfHealth.record_type })
  Object.assign(clearData, { weight: data.ChecklistOfHealth.weight })
  Object.assign(clearData, { doctor_username: data.doctor_username })
  Object.assign(clearData, { pregnancy_id: data.pregnancy_id })
  Object.assign(clearData, { term_of_pregnancy: data.data.term_of_pregnancy })
  return clearData
}
