import axios from 'axios'

export async function getQualityChecklistViolationsId (violationId) {
  const request = await axios({
    method: 'get',
    url: `quality_checklist/violations/${violationId}`
  })

  return request
}
