import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PregnantsListView from 'views/pregnants_list/pregnants_list'
import ComplicationsListView from 'views/complications_list/complications_list'
import ViolationsListView from 'views/violations_list/violations_list'
import ReportsView from 'views/reports/reports'
import PregnantCardView from 'views/pregnant_card/pregnant_card'
import RouteGuard from './route-guard'
import { Login } from 'views/login/login'
import { history } from './history'
import GaveBirthList from 'views/gave_birth_list/gave-birth-list'
import Zoom from 'views/zoom/zoom'
import ZoomJoinUrl from 'views/zoom_join_url/zoom-join-url'

const Router = () => {
  return (
    <Routes history={history}>
      <Route path='/login' element={<Login />}></Route>
      <Route
        path='/'
        element={
          <RouteGuard>
            <PregnantsListView />
          </RouteGuard>
        }
      ></Route>
      <Route
        path='/complications'
        element={
          <RouteGuard>
            <ComplicationsListView />
          </RouteGuard>
        }
      ></Route>
      <Route
        path='/violations'
        element={
          <RouteGuard>
            <ViolationsListView />
          </RouteGuard>
        }
      ></Route>
      <Route
        path='/reports'
        element={
          <RouteGuard>
            <ReportsView />
          </RouteGuard>
        }
      ></Route>
      <Route
        path='/card/:id'
        element={
          <RouteGuard>
            <PregnantCardView />
          </RouteGuard>
        }
      ></Route>
      <Route
        path='/gave_birth'
        element={
          <RouteGuard>
            <GaveBirthList />
          </RouteGuard>
        }
      ></Route>
      <Route
        path='/zoom'
        element={
          <RouteGuard>
            <Zoom />
          </RouteGuard>
        }
      ></Route>
      <Route path='/zoom_redirect' element={<ZoomJoinUrl />}></Route>
    </Routes>
  )
}

export default Router
