import './App.css'
import CoolSidebar from './components/sidebar/sidebar'
import Router from './components/router/router'
import { React } from 'react'
import { LoadingProvider } from 'components/loading/loading'
import { setAuthToken } from 'index'

function App() {
  const access = localStorage.getItem('access')
  const BASE_URL =
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASE_URL : process.env.REACT_APP_PROD_BASE_URL
  if (access) {
    setAuthToken(access)
  } else {
    if (window.location.href !== BASE_URL + '/login') {
      window.location.href = '/login'
    }
  }
  return (
    <div className='App' style={{ display: 'flex' }}>
      <CoolSidebar></CoolSidebar>
      <div className='content'>
        <LoadingProvider>
          <Router className='content'></Router>
        </LoadingProvider>
      </div>
    </div>
  )
}

export default App
