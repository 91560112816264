import validationObject from '../../data-structure/validation-object'
import { isEstimatedDateOfChildbirthLowerThanDateOfRegistration } from './is-estimated-date-of-childbirth-lower-than-date-of-registration'

export function AssertEstimatedDateOfChildbirthValidation (estimatedDateOfChildbirth, dateOfRegistration) {
  if (!estimatedDateOfChildbirth) {
    return validationObject.estimated_date_of_childbirth_helper_text
  }
  if (isEstimatedDateOfChildbirthLowerThanDateOfRegistration(estimatedDateOfChildbirth, dateOfRegistration)) {
    return 'Дата родов не может быть раньше даты взятия на учёт ТМЦ'
  }
  return ''
}
