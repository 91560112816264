const columns = [
  {
    field: 'pregnant',
    headerName: 'ФИО',
    width: 300,
    valueGetter: (params) => {
      return params.value.full_name
    },
  },
  {
    field: 'iin',
    headerName: 'ИИН',
    width: 150,
  },
  {
    field: 'info_about_pregnant_at_current_pregnancy',
    headerName: 'Дата родов',
    width: 150,
    valueGetter: (params) => {
      return params.value?.date_of_childbirth
    },
  },
  {
    field: 'date_of_last_call',
    headerName: 'Дата последнего звонка',
    width: 250,
  },
  {
    field: 'status_of_last_call',
    headerName: 'Статус последнего звонка',
    width: 300,
    valueGetter: (params) => {
      return params.value?.name
    },
  },
]

export default columns
