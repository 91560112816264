const columnsProperties = [
  {
    field: 'date_of_call',
    headerName: 'Дата',
    width: 350,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    disableColumnMenu: true
  },
  {
    field: 'doctor_full_name',
    headerName: 'Врач ТМЦ',
    width: 350,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    disableColumnMenu: true
  },
  {
    field: 'type_of_call',
    headerName: 'Тип',
    width: 350,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value?.name || ''
    }
  },
  {
    field: 'status_of_call',
    headerName: 'Статус',
    width: 350,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value?.name || ''
    }
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 350,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    disableColumnMenu: true
  }
]

export default columnsProperties
