export const interactionWithComplicationObject = {
  hospitalization_facility: {
    name: ''
  },
  interaction_result: {
    name: ''
  },
  comment: '',
  date_of_doctors_appointment_or_hospitalization: ''
}
