import { React, Component } from 'react'
import stateGetQualityChecklistViolationsFields from './api-state/state-get-quality-checklist-violations-fields'
import stateGetQualityChecklistViolationsCommonFields from './api-state/state-get-quality-checklist-violations-common-fields'
import parseDataForPost from './utils/parse-data-for-post-violations-common'
import '../popup_components.css'
import './violations.css'
import { PregnantSelect, PregnantDatepicker } from 'elements/'
import { Grid, TextField, Button } from '@mui/material'
import PatientHelpModal from './elements/patient-help-modal/patient-help-modal'
import { getPrivateDataPregnantId, postQualityChecklistViolationsCommon } from 'api/'
import { PropTypes } from 'prop-types'
import { Title, TextLabel, TextValue } from 'elements/text-elements'
import { buttonStyle } from './styles/button-style'

export default class NewViolations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pregnancy_id: this.props.pregnant_id,
      data: this.props.data,
      doctor_username: localStorage.getItem('username'),
      type_of_violations: [],
      violators: [],
      type_of_violation: { name: '' },
      violator: { name: '' },
      comment: '',
      doctor_full_name: localStorage.getItem('full_name'),
      //
      max_retries: 5,
      date_of_creation: new Date().toLocaleDateString('en-CA'),
      quality_checklist_violations_fields: [],
      quality_checklist_violations_fields_loading: true,
      quality_checklist_violations_data_loading: true,
      is_help_window_open: false,
      pregnant: null
    }
  }

  setCommentVal = (event) => {
    const val = event.target.value
    const id = event.target.id
    if (this.state[id] !== val) {
      this.setState({
        [id]: val
      })
    }
  }

  setSelectVal = (id, val) => {
    this.setState({
      [id]: val
    })
  }

  setDatePickerVal = (id, val) => {
    if (this.state[id] !== val) {
      this.setState({
        [id]: val
      })
    }
  }

  postQualityChecklistViolationsCommonWrap = (data) => {
    if (!data.type_of_violation.name) {
      return this.props.notify('error', 'Выберите замечания и пожелания!')
    }
    if (!data.violator.name) {
      return this.props.notify('error', 'Выберите нарушителя!')
    }
    parseDataForPost(data)
    postQualityChecklistViolationsCommon(data)
      .then((res) => {
        if (res.data) {
          this.props.notify('success', 'Успешно сохранено')
          this.props.updateCardBlockComponent('violations')
          this.props.setCurrentModal(null, {})
        }
      })
      .catch((err) => {
        this.props.notify('error', JSON.stringify(err.response.data))
      })
  }

  misPregnantId = (pregnantId) => {
    getPrivateDataPregnantId(pregnantId)
      .then((res) => this.setState({ pregnant: res?.data }))
      .catch(() => {})
  }

  componentDidMount () {
    this.misPregnantId(this.props.pregnant_id)
    stateGetQualityChecklistViolationsFields(this)
    stateGetQualityChecklistViolationsCommonFields(this)
  }

  render () {
    return (
      <Grid container>
        <Grid pl={3} textAlign='start' item xs={12} lg={12} md={12}>
          <Title>Замечания и пожелания</Title>
        </Grid>
        <div className='popup_card-block'>
          <Grid item container spacing={3}>
            <Grid textAlign='start' xs={3} lg={3} md={3} item>
              <TextLabel>Замечания и пожелания</TextLabel>
              <PregnantSelect
                id='type_of_violation'
                choices={this.state.type_of_violations}
                value={this.state.type_of_violation.name}
                onChange={this.setSelectVal}
              ></PregnantSelect>
            </Grid>
            <Grid textAlign='start' xs={3} lg={3} md={3} item>
              <TextLabel>Нарушитель</TextLabel>
              <PregnantSelect
                id='violator'
                value={this.state.violator.name}
                choices={this.state.violators}
                onChange={this.setSelectVal}
              ></PregnantSelect>
            </Grid>
            <Grid textAlign='start' xs={3} lg={3} md={3} item>
              <TextLabel>Дата нарушения</TextLabel>
              <PregnantDatepicker
                id='date_of_creation'
                value={this.state.date_of_creation}
                onChange={this.setDatePickerVal}
              ></PregnantDatepicker>
            </Grid>
            <Grid textAlign='start' xs={3} lg={3} md={3} item>
              <TextLabel>Фио врача ТМЦ</TextLabel>
              <TextValue>{localStorage.getItem('full_name') || localStorage.getItem('username') || '---'}</TextValue>
            </Grid>
            <Grid textAlign='start' xs={6} lg={6} md={6} item>
              <TextLabel>Комментарий</TextLabel>
              <TextField onChange={this.setCommentVal} fullWidth id='comment' multiline rows={4}></TextField>
            </Grid>
          </Grid>
        </div>
        <Grid mt={3} container style={{ justifyContent: 'flex-end', gap: 20 }}>
          <Button
            onClick={() => this.setState({ is_help_window_open: true })}
            style={buttonStyle}
            variant='contained'
            color='primary'
          >
            Сформировать справку
          </Button>
          <Button
            style={buttonStyle}
            variant='contained'
            color='primary'
            onClick={() => {
              this.postQualityChecklistViolationsCommonWrap(this.state)
            }}
          >
            Сохранить
          </Button>
        </Grid>
        <PatientHelpModal
          open={this.state.is_help_window_open}
          onClose={() => this.setState({ is_help_window_open: false })}
          data={{
            ...this.state.pregnant,
            week: this.props.current_week_of_pregnancy,
            type_of_violations: this.state.type_of_violations,
            violator: this.state.violator,
            date_of_creation: this.state.date_of_creation,
            doctor_full_name: this.state.doctor_full_name,
            comment: this.state.comment
          }}
        />
      </Grid>
    )
  }
}

NewViolations.propTypes = {
  notify: PropTypes.func,
  data: PropTypes.object,
  pregnant_id: PropTypes.number,
  current_week_of_pregnancy: PropTypes.number,
  updateCardBlockComponent: PropTypes.func,
  setCurrentModal: PropTypes.func
}
