const pregnancyObject = {
  name: '',
  pmsp: {
    id: 1,
    name: ''
  },
  therapist: {
    full_name: '',
    phone: []
  },
  health_visitor: {
    full_name: '',
    phone: [
      {
        phone: ''
      }
    ]
  }
}

export default pregnancyObject
