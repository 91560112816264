import { Checkbox } from '@mui/material'
import { React } from 'react'

export function ColumnsProperties (managePregnantsToRemove, pregnantsToRemove) {
  return [
    {
      field: 'can_delete_pregnancy',
      headerName: '',
      width: 100,
      renderCell: (params) => {
        return params.value || params.value === 0
          ? (
            <Checkbox
              size='large'
              defaultChecked={pregnantsToRemove.includes(params.value)}
              onChange={() => managePregnantsToRemove(params.value)}
            />
            )
          : (
              ''
            )
      }
    },
    { field: 'full_name', headerName: 'ФИО', width: 350 },
    { field: 'iin', headerName: 'ИИН', width: 150 },
    { field: 'current_week_of_pregnancy', headerName: 'Текущая неделя беременности', width: 250 },
    { field: 'pmsp', headerName: 'ПМСП', width: 150 },
    {
      field: 'status_of_call',
      headerName: 'Статус последнего звонка',
      width: 300,
      valueGetter: (params) => {
        return params.value?.name || ''
      }
    },
    { field: 'date_of_call', headerName: 'Дата последнего звонка', width: 300 },
    {
      field: 'date_of_registration_by_tmc',
      headerName: 'Дата взятия',
      width: 230,
      valueGetter: (params) => {
        return params.value?.split('T')[0]
      }
    }
  ]
}
