import { DataGrid } from '@mui/x-data-grid'
import { PropTypes } from 'prop-types'
import { React } from 'react'
import './table.css'

export default function PregnantTable (props) {
  return (
    <div style={{ height: 'inherit' }}>
      <DataGrid
        id='data-grid'
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 1 }
          }
        }}
        fullWidth={props.fullWidth || false}
        rowHeight={35}
        columns={props.columns || []}
        rows={props.rows || []}
        columnHeaderHeight={35}
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
      ></DataGrid>
    </div>
  )
}

PregnantTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  fullWidth: PropTypes.bool
}
