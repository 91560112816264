import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { React } from 'react'
import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import moment from 'moment/moment'

export function PregnantDateTimepicker (props) {
  return (
    <LocalizationProvider adapterLocale='ru' dateAdapter={AdapterDayjs}>
      <DateTimePicker
        onChange={(val) => {
          console.log(val)
          const d = new Date(val).toLocaleDateString('en-CA') + 'T' + new Date(val).toLocaleTimeString('it-IT')
          props.onChange(props.id, d)
        }}
        slotProps={{ textField: { size: 'small' } }}
        value={dayjs(moment(props.value, 'YYYY-MM-DD HH:mm:ss'))}
      ></DateTimePicker>
    </LocalizationProvider>
  )
}
