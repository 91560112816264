import {
  Grid,
  Dialog,
  TextField,
  Typography,
  Checkbox,
  Button,
  Divider
} from '@mui/material'
import styled from '@emotion/styled'
import validationObj from './validation/validation-obj'
import phoneHasFullPrefix from './validation/phone-has-full-prefix'
import phoneHasOnlyPlus from './validation/phone-has-only-plus'
import phoneIsValid from './validation/phone-is-valid'
import phoneNameIsValid from './validation/phone-name-is-valid'
import { React, Component } from 'react'
import phoneHasOnlyNumberAfterPlus from './validation/phone-has-only-numbers-after-plus'
import phoneHasOnlyCyrillicLetters from './validation/phone-has-only-cyrillic-letters'
import phoneIsEmptyWithSpaceExcluding from './validation/phone-is-not-empty-with-space-excluding'
import './phone-modal.css'

const TextLabel = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '18px'
})

export class PhoneEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      toggled: false,
      phone: this.props.phone,
      is_valid: false,
      phone_name: this.props.phone_name,
      is_main: this.props.is_main,
      validation: validationObj,
      edit_index: this.props.edit_index
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.phone !== prevState.phone || this.state.phone_name !== prevState.phone_name) {
      this.handlePhoneAndPhoneNameChanged()
    }
    if (this.props.toggled !== this.state.toggled) {
      this.setState({
        toggled: this.props.toggled,
        is_main: this.props.is_main,
        phone_name: this.props.phone_name,
        edit_index: this.props.edit_index,
        phone: this.props.phone
      })
    }
  }

  clearData = () => {
    return this.setState({
      is_valid: false,
      phone: '+7',
      phone_name: '',
      is_main: false
    })
  }

  handleClose = (event, reason) => {
    this.clearData()
    return reason && reason === 'backdropClick'
      ? this.props.toggleEditPhoneModal(this.props.subject, this.props.edit_index)
      : 0
  }

  handleConfirm = () => {
    this.clearData()
    this.props.toggleEditPhoneModal(this.props.subject, this.props.edit_index)
    return this.props.editPhone(
      this.state.phone,
      this.state.phone_name,
      this.state.is_main,
      this.props.subject,
      this.props.edit_index
    )
  }

  handlePhoneAndPhoneNameChanged = () => {
    return phoneIsValid(this.state.phone) && phoneNameIsValid(this.state.phone_name)
      ? this.setState({ is_valid: true })
      : this.setState({ is_valid: false })
  }

  setPhoneWithValidation = (val) => {
    let phone = this.state.phone
    let phoneHelperText = this.state.validation.phone_helper_text
    if (val) {
      if (phoneHasOnlyNumberAfterPlus(val)) {
        if (val.length === 12 && phoneHasFullPrefix(val)) {
          phone = val
          phoneHelperText = ''
        } else if (val.length < 12) {
          if (phoneHasFullPrefix(val)) {
            phone = val
          } else if (phoneHasOnlyPlus(val)) {
            phone = val.replace('+', '+7')
          } else if (phoneHasOnlyPlus(val)) {
            phone = '+7' + val.slice(1)
          }
        } else if (val.length > 12) {
          // setModalPhoneHelperText("Не более 12 символов")
        } else {
          phoneHelperText = 'Введите номер в формате +7XXXXXXXXXX'
        }
      }
    } else {
      phone = '+7' + val
      this.setState({ phone })
    }
    return this.setState({
      phone,
      validation: {
        ...this.state.validation,
        phone_helper_text: phoneHelperText
      }
    })
  }

  replaceMultipleEmptySpacesWithOneEmptySpace = (val) => {
    return val.replace(/\s+/g, ' ')
  }

  handlePhoneNameOnBlur = () => {
    return this.setState({ phone_name: this.replaceMultipleEmptySpacesWithOneEmptySpace(this.state.phone_name) })
  }

  setPhoneNameWithValidation = (val) => {
    let phoneName = this.state.phone_name
    if (val.length <= 20) {
      if (phoneHasOnlyCyrillicLetters(val)) {
        phoneName = val
      }
    } else {
      return 0
    }
    return this.setState({ phone_name: phoneName },
      () => this.assertIsPhoneNameValid(phoneName)
    )
  }

  assertIsPhoneNameValid = (val) => {
    if (phoneIsEmptyWithSpaceExcluding(val)) {
      return this.setState({
        validation: this.state.validation,
        phone_name_helper_text: 'Поле должно содержать хотя бы 3 символа'
      })
    }
  }

  setIsMain = () => {
    this.setState({ is_main: !this.state.is_main })
  }

  render () {
    return (
      <Dialog onClose={this.handleClose} open={this.props.toggled} style={{ width: '28%', marginLeft: '8%' }}>
        <Grid p={2} py={4} container gap={2}>
          <Grid item container md={12} xs={12} lg={12} spacing={1} style={{ minHeight: '7vh' }}>
            <Grid item>
              <TextLabel>Название: </TextLabel>
            </Grid>
            <Grid item>
              <TextField
                id='modal_phone_name'
                onChange={(e) => {
                  this.setPhoneNameWithValidation(e.target.value)
                }}
                value={this.state.phone_name}
                helperText={this.state.validation.phone_name_helper_text}
                onBlur={() => this.handlePhoneNameOnBlur()}
                inputProps={{
                  style: { height: '15px' }
                }}
                size='small'
              />
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Divider style={{ border: '1px solid #DBDBDB' }} />
          </Grid>
          <Grid item container xs={12} lg={12} md={12} spacing={1} style={{ minHeight: '7vh' }}>
            <Grid xs={5} lg={5} md={5} item>
              <TextLabel>Номер телефона: </TextLabel>
            </Grid>
            <Grid xs={6} lg={6} md={6} item>
              <TextField
                id='modal_phone_number'
                inputProps={{
                  style: {
                    height: '15px'
                  }
                }}
                size='small'
                helperText={this.state.phone_helper_text}
                onChange={(e) => {
                  this.setPhoneWithValidation(e.target.value)
                }}
                value={this.state.phone}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Divider style={{ border: '1px solid #DBDBDB' }} />
          </Grid>
          <Grid item container xs={10} lg={10} md={10} style={{ minHeight: '7vh' }}>
            <Grid item>
              <TextLabel>Основной: </TextLabel>
            </Grid>
            <Grid item>
              <Checkbox
                id='modal_phone_is_main'
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 35,
                    borderRadius: 20
                  }
                }}
                style={{
                  height: '15px'
                }}
                checked={this.state.is_main}
                onClick={() => {
                  this.setIsMain()
                }}
                size='large'
              />
            </Grid>
          </Grid>
          <Grid textAlign='center' item xs={12} lg={12} md={12}>
            <Button
              disabled={!this.state.is_valid}
              variant='contained'
              color='primary'
              style={{ textTransform: 'none', fontSize: '16px' }}
              onClick={() => {
                this.props.editPhone(
                  this.state.phone,
                  this.replaceMultipleEmptySpacesWithOneEmptySpace(this.state.phone_name),
                  this.state.is_main,
                  this.props.subject,
                  this.state.edit_index
                )
                this.handleConfirm()
              }}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}
