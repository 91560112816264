export const setFilterValues = (setFilters, name, value) => {
  switch (name) {
    case 'desired_week_of_pregnancy': {
      setFilters((prevState) => ({
        ...prevState,
        further_past_week_of_pregnancy: '',
        most_recent_week_of_pregnancy: '',
        [name]: value
      }))
      break
    }

    case 'further_past_week_of_pregnancy':
    case 'most_recent_week_of_pregnancy': {
      setFilters((prevState) => ({
        ...prevState,
        desired_week_of_pregnancy: '',
        [name]: value
      }))
      break
    }

    case 'date_of_filling_checklist_search': {
      setFilters((prevState) => ({
        ...prevState,
        further_past_date_of_filling_checklist_search: '',
        most_recent_date_of_filling_checklist_search: '',
        [name]: value
      }))
      break
    }

    case 'further_past_date_of_filling_checklist_search':
    case 'most_recent_date_of_filling_checklist_search': {
      setFilters((prevState) => ({
        ...prevState,
        date_of_filling_checklist_search: '',
        [name]: value
      }))
      break
    }

    case 'date_of_doctors_appointment_or_hospitalization_search': {
      setFilters((prevState) => ({
        ...prevState,
        most_recent_date_of_doctors_appointment_or_hospitalization_search: '',
        further_past_date_of_doctors_appointment_or_hospitalization_search: '',
        [name]: value
      }))
      break
    }

    case 'most_recent_date_of_doctors_appointment_or_hospitalization_search':
    case 'further_past_date_of_doctors_appointment_or_hospitalization_search': {
      setFilters((prevState) => ({
        ...prevState,
        date_of_doctors_appointment_or_hospitalization_search: '',
        [name]: value
      }))
      break
    }

    case 'date_of_last_interaction_search': {
      setFilters((prevState) => ({
        ...prevState,
        most_recent_date_of_last_interaction_search: '',
        further_past_date_of_last_interaction_search: '',
        [name]: value
      }))
      break
    }

    case 'most_recent_date_of_last_interaction_search':
    case 'further_past_date_of_last_interaction_search': {
      setFilters((prevState) => ({
        ...prevState,
        date_of_last_interaction_search: '',
        [name]: value
      }))
      break
    }

    default: {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value
      }))
      break
    }
  }
}
