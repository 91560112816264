import { React, Component } from 'react'
import './pregnant_modal_window.css'
import CallsHistory from './components/calls_history/calls_history'
import Card from './components/card/card'
import Complications from './components/complications/complications'
import NewComplications from './components/complications/new-complications'
import NewViolations from './components/violations/new-violations'
import HealthChecklist from './components/health_checklist/health-checklist'
import NewHealthChecklist from './components/health_checklist/new-health-checklist'
import Violations from './components/violations/violations'
import PregnantCardSidebar from './sidebar/pregnant_card_sidebar'
import ControlChecklist from './components/control_checklist/control_checklist'
import AllComplications from '../components/all_complications/all_complications'
import AllViolations from '../components/all_violations/all_violations'
import AllCalls from '../components/all_calls/all_calls'
import { PropTypes } from 'prop-types'

export default class PregnantModalWindow extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pregnant_id: null,
      current_modal_window: {
        name: null,
        data: {}
      },
      current_api: []
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.current_modal_window !== this.props.current_modal_window) {
      this.setState({ current_modal_window: this.props.current_modal_window })
    }
    if (this.props.pregnant_id !== this.state.pregnant_id) {
      this.setState({ pregnant_id: this.props.pregnant_id })
    }
  }

  render () {
    return this.state.current_modal_window.name !== null &&
      !['all_complications', 'all_violations', 'all_calls'].includes(this.state.current_modal_window.name)
      ? (
          <div className='pregnant_modal'>
            <div className='pregnant_modal_content'>
            <div className='pregnant_modal_popup'>
            {this.state.current_modal_window.name === 'calls_history'
              ? (
                  <CallsHistory
                    setCurrentModal={this.props.setCurrentModal}
                    current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                    pregnant_id={this.state.pregnant_id}
                    data={this.state.current_modal_window.data}
                  />
                )
              : this.state.current_modal_window.name === 'card'
                ? (
                  <Card
                    getMisValidationOfPersonalDataWrap={this.props.getMisValidationOfPersonalDataWrap}
                    updateCardBlockInfoAfterCardFormSave={this.props.updateCardBlockInfoAfterCardFormSave}
                    misPregnantIdWithParams={this.props.misPregnantIdWithParams}
                    filledPhonesSelect={this.props.filledPhonesSelect}
                    setCurrentModal={this.props.setCurrentModal}
                    updateCardBlockComponent={this.props.updateCardBlockComponent}
                    notify={this.props.notify}
                    current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                    pregnant_id={this.state.pregnant_id}
                    data={this.state.current_modal_window.data}
                  />
                  )
                : this.state.current_modal_window.name === 'complications'
                  ? (
                    <Complications
                      setCurrentModal={this.props.setCurrentModal}
                      updateCardBlockComponent={this.props.updateCardBlockComponent}
                      notify={this.props.notify}
                      current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                      pregnant_id={this.state.pregnant_id}
                      data={this.state.current_modal_window.data}
                    />
                    )
                  : this.state.current_modal_window.name === 'new_complications'
                    ? (
                      <NewComplications
                        setCurrentModal={this.props.setCurrentModal}
                        updateCardBlockComponent={this.props.updateCardBlockComponent}
                        notify={this.props.notify}
                        current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                        pregnant_id={this.state.pregnant_id}
                        data={this.state.current_modal_window.data}
                      />
                      )
                    : this.state.current_modal_window.name === 'health_checklist'
                      ? (
                        <HealthChecklist
                          setCurrentModal={this.props.setCurrentModal}
                          updateCardBlockComponent={this.props.updateCardBlockComponent}
                          notify={this.props.notify}
                          current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                          pregnant_id={this.state.pregnant_id}
                          data={this.state.current_modal_window.data}
                        />
                        )
                      : this.state.current_modal_window.name === 'new_health_checklist'
                        ? (
                          <NewHealthChecklist
                            setCurrentModal={this.props.setCurrentModal}
                            updateCardBlockComponent={this.props.updateCardBlockComponent}
                            notify={this.props.notify}
                            current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                            pregnant_id={this.state.pregnant_id}
                            data={this.state.current_modal_window.data}
                          />
                          )
                        : this.state.current_modal_window.name === 'control_checklist'
                          ? (
                            <ControlChecklist
                              setCurrentModal={this.props.setCurrentModal}
                              updateCardBlockComponent={this.props.updateCardBlockComponent}
                              notify={this.props.notify}
                              current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                              pregnant_id={this.state.pregnant_id}
                              data={this.state.current_modal_window.data}
                            />
                            )
                          : this.state.current_modal_window.name === 'violations'
                            ? (
                              <Violations
                                setCurrentModal={this.props.setCurrentModal}
                                updateCardBlockComponent={this.props.updateCardBlockComponent}
                                notify={this.props.notify}
                                current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                                pregnant_id={this.state.pregnant_id}
                                data={this.state.current_modal_window.data}
                              />
                              )
                            : this.state.current_modal_window.name === 'new_violations'
                              ? (
                                <NewViolations
                                  setCurrentModal={this.props.setCurrentModal}
                                  updateCardBlockComponent={this.props.updateCardBlockComponent}
                                  notify={this.props.notify}
                                  current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                                  pregnant_id={this.state.pregnant_id}
                                  data={this.state.current_modal_window.data}
                                />
                                )
                              : (
                                <div></div>
                                )}
              </div>
            </div>
            <div className='pregnant_modal_sidebar'>
              {this.state.current_modal_window !== null
                ? (
                  <PregnantCardSidebar
                    current_modal_window={this.state.current_modal_window}
                    setCurrentModal={this.props.setCurrentModal}
                  />
                  )
                : (
                  <div></div>
                  )}
            </div>
          </div>
        )
      : this.state.current_modal_window.name === 'all_violations'
        ? (
          <div className='pregnant_modal'>
            <AllViolations
              setCurrentModal={this.props.setCurrentModal}
              notify={this.props.notify}
              current_week_of_pregnancy={this.props.current_week_of_pregnancy}
              pregnant_id={this.state.pregnant_id}
              data={this.state.current_modal_window.data}
            />
          </div>
          )
        : this.state.current_modal_window.name === 'all_complications'
          ? (
          <div className='pregnant_modal'>
            <AllComplications
              setCurrentModal={this.props.setCurrentModal}
              notify={this.props.notify}
              current_week_of_pregnancy={this.props.current_week_of_pregnancy}
              pregnant_id={this.state.pregnant_id}
              data={this.state.current_modal_window.data}
            />
          </div>
            )
          : this.state.current_modal_window.name === 'all_calls'
            ? (
            <div className='pregnant_modal'>
              <AllCalls
                setCurrentModal={this.props.setCurrentModal}
                notify={this.props.notify}
                current_week_of_pregnancy={this.props.current_week_of_pregnancy}
                pregnant_id={this.state.pregnant_id}
                data={this.state.current_modal_window.data}
              />
            </div>
              )
            : (
            <></>
              )
  }
}

PregnantModalWindow.propTypes = {
  pregnant_id: PropTypes.number,
  current_modal_window: PropTypes.object,
  setCurrentModal: PropTypes.func,
  getMisValidationOfPersonalDataWrap: PropTypes.func,
  updateCardBlockInfoAfterCardFormSave: PropTypes.func,
  misPregnantIdWithParams: PropTypes.func,
  filledPhonesSelect: PropTypes.array,
  notify: PropTypes.func,
  current_week_of_pregnancy: PropTypes.number,
  updateCardBlockComponent: PropTypes.func
}
