import axios from 'axios'

export default async function postQualityChecklistViolationsStep (data) {
  const request = await axios({
    method: 'post',
    url: 'quality_checklist/violations_step',
    data
  })
  console.log(request)
  return request
}
