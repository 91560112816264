import { React, useEffect, useState } from 'react'
import postStartMeeting from 'views/zoom/api/post-start-meeting'
import getPersonalMeetingLink from 'views/zoom/api/get-personal-meeting-link'

const redirectToUrl = (url) => {
  return window.open(url, '_blank')
}

export default function Zoom () {
  const [zoomConferenceLink, setZoomConferenceLink] = useState('')
  const queryParameters = new URLSearchParams(window.location.search)
  const queryPhone = queryParameters.get('phone')
  const callInited = queryParameters.get('call_inited')

  const sendPersonalMeetingLinkRequest = () => {
    getPersonalMeetingLink()
      .then((res) => {
        if (res.data) {
          setZoomConferenceLink(res.data.link)
        }
      })
      .catch((err) => console.log(err))
  }
  const handleConferenceLinkChanged = (zoomConferenceLink) => {
    return redirectToUrl(zoomConferenceLink)
  }
  const handlePostStartMeeting = (phone) => {
    postStartMeeting(phone)
      .then((res) => {
        // if (res.data){
        // setZoomConferenceLink(res.data.join_url)
        // some api logic
        // }
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    if (callInited) {
      sendPersonalMeetingLinkRequest()
      handlePostStartMeeting(queryPhone)
    }
  })

  useEffect(() => {
    if (zoomConferenceLink) {
      setTimeout(() => {
        handleConferenceLinkChanged(zoomConferenceLink)
      }, 1000)
    }
  }, [zoomConferenceLink])

  return <></>
}
