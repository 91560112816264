import { Box } from '@mui/material'
import { React } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import moment from 'moment/moment'
import { getFormattedDate } from '../utils'

export default function DateInputValue (props) {
  const { item, applyValue } = props

  const handleChange = (event) => {
    applyValue({ ...item, value: getFormattedDate(event) })
    props.handleFilterChange(props.getDateFilterName(item?.field), getFormattedDate(event))
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px'
      }}
    >
      <LocalizationProvider id={`date-localization-${item?.field}`} adapterLocale='ru' dateAdapter={AdapterDayjs}>
        <DatePicker
          id={`date-${item?.field}`}
          onChange={handleChange}
          slotProps={{
            textField: { size: 'small', placeholder: 'Дата', label: 'Дата', name: 'date-input', error: false }
          }}
          value={dayjs(moment(item.value, 'YYYY-MM-DD'))}
        />
      </LocalizationProvider>
    </Box>
  )
}
