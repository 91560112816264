const columns = [
  {
    field: 'health_complaints',
    headerName: 'Жалобы по здоровью',
    width: 300,
    valueGetter: (params) => {
      return params.value.map((el) => el.name).join(', ')
    }
  },
  {
    field: 'medical_procedures',
    headerName: 'Врачебные мероприятия',
    width: 300,
    valueGetter: (params) => {
      return params.value?.name
    }
  },
  {
    field: 'request_status',
    headerName: 'Оператор (статус обращения)',
    width: 300,
    valueGetter: (params) => {
      return params.value?.name
    }
  },
  {
    field: 'description_of_health_complaints',
    headerName: 'Описание жалобы по здоровью',
    width: 300
  },
  {
    field: 'health_complaints_comment',
    headerName: 'Комментарий',
    width: 300
  },
  {
    field: 'complaints_and_suggestions_about_pmsp_work',
    headerName: 'Замечания или пожелания по работе ПМСП',
    width: 300,
    valueGetter: (params) => {
      return params.value.map((el) => el.name).join(', ')
    }
  },
  {
    field: 'description_of_complaints_and_suggestions_about_pmsp_work',
    headerName: 'Описание замечания или пожелания по работе ПМСП',
    width: 300
  },
  {
    field: 'complaints_and_suggestions_about_pmsp_work_comment',
    headerName: 'Комментарий',
    width: 300
  },
  {
    field: 'date_of_creation',
    headerName: 'Дата создания',
    width: 300
  },
  {
    field: 'doctor_full_name',
    headerName: 'ФИО Врача',
    width: 300
  }
]

export default columns
