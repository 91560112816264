import { React, Component } from 'react'
import { Button, Grid as GridBase } from '@mui/material'
import styled from '@emotion/styled'
import CallOutlinedIcon from '@mui/icons-material/Call'
import DuoOutlinedIcon from '@mui/icons-material/Duo'
import PhonesSelect from './phones_select'
import videoCallUsers from 'video-call-users'
import { PropTypes } from 'prop-types'
import { Title } from 'elements/text-elements'
import './header.css'

const BASE_URL =
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASE_URL : process.env.REACT_APP_PROD_BASE_URL
const videoCallUsers_ = videoCallUsers.users

const Grid = styled(GridBase)({
  textAlign: 'start',
  justifyContent: 'flex-start'
})

export default class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      personal_data: {
        full_name: '---------',
        current_week_of_pregnancy: 0
      },
      call_target: '',
      choices: this.props.choices
    }
  }

  call = (callTarget) => {
    const infoArray = callTarget.split(' ')
    const phone = infoArray[infoArray.length - 1]
    this.props.initBrightPatternCall(phone)
  }

  setCallTargetVal = (id, val) => {
    if (this.state[id] !== val) {
      this.setState({ [id]: val })
      this.props.chosePhone(id, val)
    }
  }

  initZoomVideoCall = (callTarget) => {
    const infoArray = callTarget.split(' ')
    const phone = infoArray[infoArray.length - 1]
    if (!phone) {
      return this.props.notify('error', 'Выберите номер телефона')
    }
    window.open(`${BASE_URL}/zoom?call_inited=true&phone=${phone}`, '_blank')
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(this.state.personal_data) !== JSON.stringify(this.props.personal_data)) {
      this.setState({ personal_data: this.props.personal_data })
    }
    if (prevProps.choices !== this.state.choices) {
      this.setState({ choices: this.props.choices })
    }
  }

  render () {
    return (
      <Grid style={{ alignContent: 'flex-start', alignItems: 'flex-start' }} my={1} spacing={1} container>
        <Grid xs={4} lg={4} md={4} item>
          <Grid
            style={{
              alignContent: 'flex-start',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              textAlign: 'end'
            }}
            spacing={2}
            item
            container
          >
            <Grid
              style={{ textAlign: 'end', alignItems: 'flex-start', alignContent: 'flex-start' }}
              xs={9}
              lg={9}
              md={9}
              item
            >
              <Title style={{ textTransform: 'ellipsis', textAlign: 'start' }} noWrap>
                {this.props.personal_data.full_name}
              </Title>
            </Grid>
            <Grid style={{ alignContent: 'flex-start', alignItems: 'flex-start' }} xs={3} lg={3} md={3} item>
              <p id='week_num' style={{ width: '100%', pointerEvents: 'none' }}>
                <p id='num'>{this.props.personal_data.current_week_of_pregnancy}</p>
                <p id='week'>неделя</p>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4} lg={4} md={4} item>
          <PhonesSelect
            id='call_target'
            value={this.state.call_target}
            onChange={this.setCallTargetVal}
            choices={this.props.phones}
          />
        </Grid>
        <Grid xs={4} lg={4} md={4} spacing={1} item container>
          <Grid xs={5} lg={5} md={5} item>
            <Button
              fullWidth
              id='call_button'
              variant='contained'
              size='small'
              color='success'
              disabled={this.props.call_button_disabled}
              onClick={() => {
                this.call(this.state.call_target)
              }}
            >
              <CallOutlinedIcon />
              Позвонить
            </Button>
          </Grid>
          {videoCallUsers_.includes(localStorage.getItem('username')) && (
            <Grid xs={5} lg={5} md={5} item>
              <Button
                onClick={() => this.initZoomVideoCall(this.state.call_target)}
                fullWidth
                id='videocall_button'
                variant='contained'
                size='small'
                color='primary'
              >
                <DuoOutlinedIcon />
                Видеозвонок
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
}

Header.propTypes = {
  choices: PropTypes.array,
  initBrightPatternCall: PropTypes.func,
  chosePhone: PropTypes.func,
  notify: PropTypes.func,
  personal_data: PropTypes.object,
  phones: PropTypes.array,
  call_button_disabled: PropTypes.bool
}
