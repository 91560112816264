export default function setWeightVal (parent, id, val) {
  if (!isNaN(parseInt(val)) || val === '') {
    let value
    if (val < 35) {
      value = val >= 0 ? val : 0
      parent.setState({
        validation: { ...parent.state.validation, weight_helper_text: 'в поле вес допустимое значение: от 35 до 300' }
      })
    } else if (val > 299) {
      value = 299
      // parent.setState({validation: {...parent.state.validation, weight_helper_text: "в поле вес допустимое значение: от 35 до 300"}})
    } else {
      parent.setState({ validation: { ...parent.state.validation, weight_helper_text: '' } })
      value = val
    }
    if (parent.state.ChecklistOfHealth[id] !== value) {
      parent.setState({
        ChecklistOfHealth: {
          ...parent.state.ChecklistOfHealth,
          [id]: value
        }
      })
    }
  }
}
