export function getDateInputValueOperator (InputComponent, InputComponentProps) {
  return {
    label: 'дата',
    value: 'date',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (params) => {
        return params.value
      }
    },
    InputComponent,
    InputComponentProps
  }
}
