import { React, Component } from 'react'
import { PregnantSelect, PregnantMultiSelect, PregnantDatepicker } from 'elements/'
import PregnantPhones from '../../elements/pregnant_phones/pregnant_phones'
import { Grid, TextField, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { PhoneAdd, PhoneEdit } from './phone-modal'
import { patchPrivateDataPregnantId, getPrivateDataPregnantId, getPrivateDataFields } from 'api/'
import PregnantButton from '../../elements/pregnant_button'
import Loading from '../../loading/loading'
import dataValidate from './utils/data-validate'
import parseData from './utils/parse-data'
import pregnancyObject from './data-structure/pmsp-object'
import fatherObject from './data-structure/father-object'
import pregnantObject from './data-structure/pregnant-object'
import infoAboutPregnantAtCurrentPregnancyObject from './data-structure/info-about-pregnant-at-current-pregnancy-object'
import choiceFieldsObject from './data-structure/choice-fields-object'
import validationObject from './data-structure/validation-object'
import setHeightVal from './state-logic/set-height-val'
import setWeightVal from './state-logic/set-weight-val'
import setValWithZeroToThirtyValidation from './state-logic/set-zero-to-thirty-val'
import setFioVal from './validation/set-fio-val'
import isDateLower from './utils/is-date-lower'
import removeItemByIndex from './utils/remove-item-by-index'
import '../popup_components.css'
import './card.css'
import { getIinStateData } from './validation/set-iin/get-iin-state-data'
import { AssertDateOfRegistrationValidation } from './validation/set-date-of-registration/assert-date-of-registration-validation'
import { AssertDateOfDeregistrationValidation } from './validation/set-date-of-deregistration/assert-date-of-deregistration-validation'
import { AssertEstimatedDateOfChildbirthValidation } from './validation/set-estimated-date-of-childbirth/assert-estimated-date-of-childbirth-validation'
import AssertFirstDayOfLastMenstruationValidation from './validation/set-first-lay-of-last-menstruation/assert-first-lay-of-last-menstruation-validation'
import AssertIin from './validation/set-iin/assert-iin'
import { Title, TextValue, TextLabel } from 'elements/text-elements'
import { PropTypes } from 'prop-types'

export default class Card extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: choiceFieldsObject,
      ...pregnantObject,
      ...infoAboutPregnantAtCurrentPregnancyObject,
      ...pregnancyObject,
      pregnancy_id: this.props.pregnant_id,
      current_modal_window: null,
      phone_modal_toggled: {
        father: {
          pregnant: {
            status: false,
            edit_status: false,
            edit_index: null,
            data: null,
          },
        },
        pregnant: {
          pregnant: {
            status: false,
            edit_status: false,
            edit_index: null,
            data: null,
          },
        },
      },
      fields_loading: true,
      pregnant_loading: true,
      max_retries: 5,
      is_save_button_disabled: false,
      save_button_text: 'Сохранить',
      validation: validationObject,
    }
  }

  changeSaveButton = (saved) => {
    if (saved) {
      this.setState({
        is_save_button_disabled: true,
        save_button_text: 'Сохранено',
      })
    } else {
      this.setState(
        {
          is_save_button_disabled: true,
          save_button_text: 'Ошибка',
        },
        () => {
          setTimeout(() => {
            this.setState({ is_save_button_disabled: false })
          }, 2000)
        }
      )
    }
  }

  misFields = () => {
    getPrivateDataFields()
      .then((res) => {
        if (res.data) {
          this.setState({
            fields: res.data,
            fields_loading: false,
          })
        }
      })
      .catch((err) => console.log(err))
  }

  misPregnantId = (pregnantId) => {
    getPrivateDataPregnantId(pregnantId)
      .then((res) => {
        if (res.data) {
          console.log('got data', res.data)
          const infoAboutPregnant = res.data.info_about_pregnant_at_current_pregnancy
          if (!infoAboutPregnant.father_info) {
            Object.assign(infoAboutPregnant, { father_info: fatherObject })
          }
          this.setState(
            {
              ...res.data.pregnant,
              ...infoAboutPregnant,
              ...res.data.pmsp_area,
              pregnant_loading: false,
            },
            () => {
              if (!this.state.father_info) {
                this.setState({ father_info: fatherObject })
              }
            }
          )
        }
      })
      .catch((err) => console.log(err))
  }

  componentDidMount() {
    this.misPregnantId(this.props.pregnant_id)
    this.misFields()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.date_of_registration_by_tmc !== this.state.date_of_registration_by_tmc ||
      prevState.estimated_date_of_childbirth !== this.state.estimated_date_of_childbirth ||
      prevState.first_day_of_last_menstruation !== this.state.first_day_of_last_menstruation ||
      prevState.date_of_deregistration_by_tmc !== this.state.date_of_deregistration_by_tmc
    ) {
      this.handlePregnancyDatesChanged()
    }
  }

  setCommentVal = (commentVal) => {
    const commentHelperText = commentVal.length <= 1000 ? '' : 'Комментарий должен содержать до 1000 символов'
    const val = commentVal.length <= 1000 ? commentVal : this.state.comment
    if (this.state.comment !== val) {
      this.setState({ comment: val })
    }
    if (this.state.validation.comment_helper_text !== commentHelperText) {
      this.setState({ validation: { ...this.state.validation, comment_helper_text: commentHelperText } })
    }
  }

  setPregnantSelectValOfPregnant = (id, val) => {
    this.setState({ [id]: val })
  }

  setPregnantSelectValOfFather = (id, val) => {
    this.setState({
      father_info: {
        ...this.state.father_info,
        [id]: val,
      },
    })
  }

  setDatePickerValPregnant = (id, val) => {
    if (this.state[id] !== val) {
      this.setState({ [id]: val })
    }
  }

  setDateOfBirthVal = (id, val) => {
    if (this.state.pregnant.date_of_birth !== val) {
      this.setState({ date_of_birth: val })
    }
  }

  handleSetValWithZeroToThirtyValidation = (id, val) => {
    const value = setValWithZeroToThirtyValidation(val)
    if (this.state[id] !== value) {
      this.setState({ [id]: value })
    }
  }

  setDateValidation = (id, text) => {
    this.setState({
      validation: {
        ...this.state.validation,
        [id + '_helper_text']: text,
      },
    })
  }

  setDatePickerValinfo_about_pregnant_at_current_pregnancy = (id, val) => {
    if (id === 'date_of_registration_by_tmc') {
      if (isDateLower(this.state.date_of_deregistration_by_tmc, val)) {
        this.setDateValidation(id, 'Дата взятия не может быть позже даты снятия')
      } else if (!isDateLower(val, this.state.estimated_date_of_childbirth)) {
        if (this.state.estimated_date_of_childbirth) {
          this.setDateValidation(id, 'Дата взятия на учёт ТМЦ не может быть позже предполагаемой даты родов')
        }
      } else {
        this.setDateValidation(id, '')
      }
    } else if (id === 'reason_of_deregistration') {
      if (!this.state.date_of_deregistration_by_tmc) {
        this.setDateValidation('date_of_deregistration_by_tmc', 'Необходимо заполнить дату снятия с учёта')
      } else if (!this.state.validation.date_of_deregistration_by_tmc_helper_text) {
        this.setDateValidation('date_of_deregistration_by_tmc', '')
      }
    }
    if (this.state[id] !== val) {
      this.setState({ [id]: val })
    }
  }

  setMultiSelectValOfPregnant = (id, val) => {
    this.setState({ [id]: val })
  }

  setMultiSelectValOfFather = (id, val) => {
    this.setState({
      father_info: {
        [id]: val,
      },
    })
  }

  toggleAddPhoneModal = (subject) => {
    const target = this.state.phone_modal_toggled[subject]
    target.status = !target.status
    target.data = null
    return this.setState({
      phone_modal_toggled: {
        ...this.state.phone_modal_toggled,
        [subject]: target,
      },
    })
  }

  toggleEditPhoneModal = (subject, index) => {
    const target = this.state.phone_modal_toggled[subject]
    let data = null
    if (subject === 'pregnant') {
      data = this.state.phone[index]
    } else if (subject === 'father') {
      data = this.state.father_info.phone[index]
    }
    target.edit_status = !target.edit_status
    target.edit_index = index
    target.status = false
    target.data = data
    if (data) {
      return this.setState({
        phone_modal_toggled: {
          ...this.state.phone_modal_toggled,
          [subject]: target,
        },
      })
    }
  }

  addNewPhone = (modalPhone, modalPhoneName, isMain, subject) => {
    let phoneList =
      subject === 'pregnant'
        ? this.getArrayIfArrayElseEmptyArray(this.state.phone)
        : this.getArrayIfArrayElseEmptyArray(this.state.father_info.phone)
    const isMainExistsinPhoneList = this.getArrayWithFlatValuesByKey(phoneList, 'is_main').includes(true)
    if (isMain && isMainExistsinPhoneList) {
      phoneList = this.getArrayWithChangedValuesByKeysOfAllObjects(phoneList, 'is_main', false)
    }
    phoneList.push({
      id: phoneList[phoneList.length - 1] ? phoneList[phoneList.length - 1].id + 1 : 1,
      phone: modalPhone,
      name: modalPhoneName,
      is_main: isMainExistsinPhoneList ? isMain : true,
    })
    if (subject === 'pregnant') {
      this.setState({ phone: phoneList })
    } else if (subject === 'father') {
      const fatherInfo = this.state.father_info
      fatherInfo.phone = phoneList
      this.setState({ father_info: fatherInfo })
    }
    const target = this.state.phone_modal_toggled[subject]
    target.status = false
    target.data = null
    this.setState({ phone_modal_toggled: { ...this.state.phone_modal_toggled, [subject]: target } }, () =>
      this.handlePhonesChanged()
    )
  }

  getArrayWithFlatValuesByKey = (array, key) => {
    const result = []
    array.reduce((accumulator, currentVal, index) => {
      return result.push(currentVal[key])
    }, 0)
    return result
  }

  getArrayIfArrayElseEmptyArray = (array) => {
    return array && array.length ? array : []
  }

  getArrayWithChangedValuesByKeysOfAllObjects = (array, key, value) => {
    const result = []
    array.reduce((accumulator, currentVal, index) => {
      return result.push(Object.assign({}, currentVal, { [key]: value }))
    }, 0)
    return result
  }

  getArrayWithoutObjectByIndex = (array, index) => {
    const phoneListWithoutEditingPhone = []
    array.reduce((accumulator, currentVal, arrIndex) => {
      return arrIndex !== index && phoneListWithoutEditingPhone.push(currentVal)
    }, 0)
    return phoneListWithoutEditingPhone
  }

  editPhone = (modalPhone, modalPhoneName, isMain, subject, index) => {
    let phoneList =
      subject === 'pregnant'
        ? this.getArrayIfArrayElseEmptyArray(this.state.phone)
        : this.getArrayIfArrayElseEmptyArray(this.state.father_info.phone)
    if (isMain) {
      phoneList = this.getArrayWithChangedValuesByKeysOfAllObjects(phoneList, 'is_main', false)
    } else {
      const phoneListWithoutEditingPhone = this.getArrayWithoutObjectByIndex(phoneList, index)
      isMain = !phoneListWithoutEditingPhone.includes(true) && phoneList[index].is_main !== isMain
    }
    const currentPhone = {
      id: phoneList[phoneList.length - 1].id + 1 || 1,
      phone: modalPhone,
      name: modalPhoneName,
      is_main: isMain,
    }
    phoneList[index] = currentPhone
    if (subject === 'pregnant') {
      this.setState({ phone: phoneList })
    } else if (subject === 'father') {
      const fatherInfo = this.state.father_info
      phoneList[index] = currentPhone
      fatherInfo.phone = phoneList
      this.setState({ father_info: fatherInfo })
    }
    const target = this.state.phone_modal_toggled[subject]
    target.status = false
    target.data = null
    target.index = null
    this.setState({ phone_modal_toggled: { ...this.state.phone_modal_toggled, [subject]: target } }, () =>
      this.handlePhonesChanged(subject)
    )
  }

  handlePhonesChanged = (subject) => {
    let helperText = ''
    if (subject === 'pregnant') {
      const phoneList = this.state.phone
      if (phoneList.length === 0) {
        helperText = 'Должен быть хотя бы один номер телефона'
      }
      return this.setState({
        validation: {
          ...this.state.validation,
          pregnant_phones_helper_text: helperText,
        },
      })
    } else if (subject === 'father') {
      const phoneList = this.state.father_info
      if (phoneList.length === 0) {
        helperText = 'Должен быть хотя бы один номер телефона'
      }
      return this.setState({
        validation: {
          ...this.state.validation,
          father_phones_helper_text: helperText,
        },
      })
    }
  }

  removePhone = (index, subject) => {
    let phoneList =
      subject === 'pregnant'
        ? this.getArrayIfArrayElseEmptyArray(this.state.phone)
        : this.getArrayIfArrayElseEmptyArray(this.state.father_info.phone)
    if (phoneList.length === 1) {
      this.props.notify('error', 'Вы не можете удалить последний телефон')
      return 0
    }
    if (phoneList[index].is_main) {
      this.props.notify('error', 'Вы не можете удалить главный телефон')
      return 0
    } else {
      phoneList = removeItemByIndex(phoneList, index)
    }
    if (subject === 'pregnant') {
      this.setState({ phone: phoneList })
    } else if (subject === 'father') {
      const fatherInfo = this.state.father_info
      fatherInfo.phone = phoneList
      this.setState({ father_info: fatherInfo })
    }
    const target = this.state.phone_modal_toggled[subject]
    target.status = false
    this.setState({
      phone_modal_toggled: {
        ...this.state.phone_modal_toggled,
        [subject]: target,
      },
    })
  }

  checkIsValid = (data) => {
    const validationKeys = Object.keys(data)
    const notValid = []
    for (let i = 0; i < validationKeys.length; i++) {
      if (data[validationKeys[i]] !== '') {
        notValid.push(data[validationKeys[i]])
      }
    }
    return [!notValid.length, notValid]
  }

  setAddressVal = (val) => {
    if (val) {
      return this.setState({ residential_address: val })
    } else {
      if (val === '') {
        return this.setState({ residential_address: '' })
      } else {
        return 0
      }
    }
  }

  handleSetHeightVal = (val) => {
    const [height, heightHelperText] = setHeightVal(val)
    this.setState({
      validation: { ...this.state.validation, height_helper_text: heightHelperText },
      height,
    })
  }

  handleSetWeightVal = (val) => {
    const [weight, weightHelperText] = setWeightVal(val)
    this.setState({
      validation: { ...this.state.validation, weight_helper_text: weightHelperText },
      weight,
    })
  }

  setFioVal = (val, target) => {
    if (target === 'pregnant') {
      let helperText = ''
      val = val.replace(/ +(?= )/g, '')
      if (val === ' ') {
        return 0
      }
      if (val === '') {
        helperText = 'Поле ФИО не может быть пустым'
        return this.setState({
          full_name: val,
          validation: { ...this.state.validation, pregnant_fio_helper_text: helperText },
        })
      }
      if (/^[А-яа-яA-za-z-ӘәЁёҚқІіҒғҰұӨөҮүҺһҢң\s]*$/.test(val)) {
        if (val.length > 75) {
          return 0
        }
        if (val.length < 3) {
          helperText = 'Поле ФИО должно содержать не менее 3 символов'
          return this.setState({
            full_name: val,
            validation: { ...this.state.validation, pregnant_fio_helper_text: helperText },
          })
        }
        return this.setState({
          full_name: val,
          validation: { ...this.state.validation, pregnant_fio_helper_text: helperText },
        })
      } else {
        helperText = 'В ФИО допустимы только буквы и тире'
      }
      this.setState({
        full_name: val,
        validation: { ...this.state.validation, pregnant_fio_helper_text: helperText },
      })
    } else if (target === 'father') {
      let helperText = ''
      val = val.replace(/ +(?= )/g, '')
      if (val === ' ') {
        return 0
      }
      if (val === '') {
        return this.setState({
          father_info: { ...this.state.father_info, full_name: val },
          validation: { ...this.state.validation, father_fio_helper_text: helperText },
        })
      }
      if (/^[А-яа-яA-za-z-ӘәЁёҚқІіҒғҰұӨөҮүҺһҢң\s]*$/.test(val)) {
        if (val.length > 75) {
          return 0
        }
        if (val.length < 3) {
          helperText = 'Поле ФИО должно содержать не менее 3 символов'
          return this.setState({
            father_info: { ...this.state.father_info, full_name: val },
            validation: { ...this.state.validation, father_fio_helper_text: helperText },
          })
        }
        return this.setState({
          father_info: { ...this.state.father_info, full_name: val },
          validation: { ...this.state.validation, father_fio_helper_text: helperText },
        })
      }
    }
  }

  patchPregnant = (data) => {
    const [isValid, notValidData] = this.checkIsValid(data.validation)
    if (!isValid) {
      let errorText = ''
      for (let i = 0; i < notValidData.length; i++) {
        errorText += notValidData[i] + '\n'
      }
      this.props.notify('error', errorText)
      return 0
    }
    const validationResult = dataValidate(data)
    if (!validationResult[0]) {
      this.props.notify('error', validationResult[2])
      return 0
    }
    const patchObject = parseData(data)
    patchPrivateDataPregnantId(this.props.pregnant_id, patchObject)
      .then((res) => {
        this.changeSaveButton(true)
        this.props.misPregnantIdWithParams()
        this.props.filledPhonesSelect()
        this.props.updateCardBlockComponent('pregnant_card')
        this.props.notify('success', 'Успешно сохранено')
        this.props.setCurrentModal(null, {})
        this.props.getMisValidationOfPersonalDataWrap()
        this.props.updateCardBlockInfoAfterCardFormSave()
        window.location.reload()
      })
      .catch((err) => {
        if (err?.response?.status !== 401) {
          this.props.notify('error', `Возникли ошибки ${JSON.stringify(err?.response?.data)}`)
        } else {
          this.props.notify('error', `Возникли ошибки ${JSON.stringify(err?.response?.data)}`)
          this.changeSaveButton(false)
        }
      })
  }

  handlePregnancyIinOrDateOfBirthChanged = () => {
    const [iinHelperText, dateOfBirthHelperText] = AssertIin(this.state.iin, this.state.date_of_birth)
    return this.setState({
      validation: {
        ...this.state.validation,
        date_of_birth_helper_text: dateOfBirthHelperText,
        iin_helper_text: iinHelperText,
      },
    })
  }

  setIinVal = (val) => {
    if (getIinStateData(val, this.state.date_of_birth)) {
      const [iin, dateOfBirth] = getIinStateData(val, this.state.date_of_birth)
      return this.setState({ iin, date_of_birth: dateOfBirth })
    }
  }

  handlePregnancyDatesChanged = () => {
    const firstDayOfLastMenstruation = AssertFirstDayOfLastMenstruationValidation(
      this.state.first_day_of_last_menstruation,
      this.state.estimated_date_of_childbirth
    )
    const estimatedDateOfChildbirthText = AssertEstimatedDateOfChildbirthValidation(
      this.state.estimated_date_of_childbirth,
      this.state.date_taken_on_registration_on_pmsp
    )
    const dateTakenOnRegistrationOnPmspHelperText = AssertDateOfRegistrationValidation(
      this.state.date_taken_on_registration_on_pmsp,
      this.state.first_day_of_last_menstruation
    )
    const dateOfDeregistrationByTmcHelperText = AssertDateOfDeregistrationValidation(
      this.state.date_of_registration_by_tmc,
      this.state.date_of_deregistration_by_tmc
    )
    return this.setState({
      validation: {
        ...this.state.validation,
        first_day_of_last_menstruation_helper_text: firstDayOfLastMenstruation,
        estimated_date_of_childbirth_helper_text: estimatedDateOfChildbirthText,
        date_taken_on_registration_on_pmsp_helper_text: dateTakenOnRegistrationOnPmspHelperText,
        date_of_deregistration_by_tmc_helper_text: dateOfDeregistrationByTmcHelperText,
      },
    })
  }

  render() {
    return (
      <div className='pregnant_card_popup'>
        {!this.state.pregnant_loading && !this.state.fields_loading ? (
          <>
            <div className='pregnant_card_popup_card'>
              <div className='popup_card'>
                <div className='pregnant_card_sidebar_effect-card'>
                  <div className='main_block'>
                    <Grid container textAlign='start'>
                      <Title>Личные данные</Title>
                    </Grid>
                    <Grid container gap={3}>
                      <Grid textAlign='start' xs={3} lg={3} md={3} item>
                        <TextLabel>ФИО</TextLabel>
                        <TextField
                          id='full_name'
                          name='full_name'
                          size='small'
                          fullWidth
                          value={this.state.full_name}
                          onChange={(event) => this.setFioVal(event.target.value, 'pregnant')}
                        />
                      </Grid>
                      <Grid textAlign='start' xs={2} lg={2} md={2} item>
                        <TextLabel>ИИН</TextLabel>
                        <TextField
                          id='iin'
                          helperText={this.state.validation.iin_helper_text}
                          onBlur={this.handlePregnancyIinOrDateOfBirthChanged}
                          name='iin'
                          size='small'
                          value={this.state.iin}
                          onChange={(event) => this.setIinVal(event.target.value)}
                        />
                      </Grid>
                      <Grid textAlign='start' xs={2} lg={2} md={2} item>
                        <TextLabel>Дата рождения</TextLabel>
                        <PregnantDatepicker
                          id='date_of_birth'
                          onBlur={this.handlePregnancyIinOrDateOfBirthChanged}
                          helperText={this.state.validation.date_of_birth_helper_text}
                          name='date_of_birth'
                          onChange={this.setDateOfBirthVal}
                          value={this.state.date_of_birth}
                          disabled={!(this.state.iin.length > 6 && parseInt(this.state.iin.slice(6, 7)) === 0)}
                          disableOpenPicker={true}
                        />
                      </Grid>
                      <Grid textAlign='start' xs={2} lg={2} md={2} item>
                        <TextLabel>Район</TextLabel>
                        <PregnantSelect
                          fullWidth
                          id='area_of_residence'
                          name='area_of_residence'
                          value={this.state.area_of_residence}
                          onChange={this.setPregnantSelectValOfPregnant}
                          choices={this.state.fields.AreaOfResidence}
                        />
                      </Grid>
                      <Grid textAlign='start' xs={2} lg={2} md={2} item>
                        <TextLabel>Адрес</TextLabel>
                        <TextField
                          id='residential_address'
                          size='small'
                          helperText={this.state.validation.residential_address_helper_text}
                          error={Boolean(this.state.validation.residential_address_helper_text)}
                          onChange={(event) => this.setAddressVal(event.target.value)}
                          value={this.state.residential_address}
                        />
                      </Grid>
                    </Grid>
                    <div className='main_block_form_row' style={{ alignItems: 'baseline !important' }}>
                      <div style={{ width: '35%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2 }}>
                          <TextLabel>Номер телефона</TextLabel>
                          <IconButton
                            size='small'
                            onClick={() => this.toggleAddPhoneModal('pregnant')}
                            style={{
                              textTransform: 'none',
                              backgroundColor: '#5280FF',
                              color: 'white',
                              fontWeight: 'bolder',
                              height: '1.2em',
                              width: '1.2em',
                              borderRadius: '25%',
                              border: '2px solid #c5d7fb',
                            }}
                            variant='contained'
                          >
                            <AddIcon style={{ fontSize: '1.15em' }} />
                          </IconButton>
                        </div>
                        <PregnantPhones
                          key={1}
                          id='residential_address'
                          target='pregnant'
                          error={Boolean(this.state.validation.pregnant_phones_helper_text)}
                          helperText={this.state.validation.pregnant_phones_helper_text}
                          removePhone={this.removePhone}
                          toggleEditPhoneModal={this.toggleEditPhoneModal}
                          size='small'
                          value=''
                          choices={this.state.phone}
                        />
                      </div>
                      <div style={{ display: 'flex', alignItems: 'end' }}>
                        <PhoneAdd
                          id='phone_modal'
                          subject='pregnant'
                          data={this.state.phone_modal_toggled.pregnant.data}
                          toggled={this.state.phone_modal_toggled.pregnant.status}
                          addPhone={this.addNewPhone}
                          toggleAddPhoneModal={this.toggleAddPhoneModal}
                          clearPhoneModalEditData={this.clearPhoneModalEditData}
                          handlePhonesChanged={this.handlePhonesChanged}
                        />
                        <PhoneEdit
                          id='phone_modal'
                          subject='pregnant'
                          phone={this.state.phone_modal_toggled.pregnant.data?.phone}
                          phone_name={this.state.phone_modal_toggled.pregnant.data?.name}
                          is_main={this.state.phone_modal_toggled.pregnant.data?.is_main}
                          edit_index={this.state.phone_modal_toggled.pregnant.edit_index}
                          toggled={this.state.phone_modal_toggled.pregnant.edit_status}
                          toggleEditPhoneModal={this.toggleEditPhoneModal}
                          editPhone={this.editPhone}
                          clearPhoneModalEditData={this.clearPhoneModalEditData}
                          handlePhonesChanged={this.handlePhonesChanged}
                        />
                      </div>
                      <div style={{ display: 'flex', alignItems: 'end' }}></div>
                    </div>
                  </div>
                  <div className='main_block'>
                    <div className='main_block_form_row'>
                      <Title>Социальный статус</Title>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <TextLabel>Семейное положение</TextLabel>
                        <PregnantSelect
                          id='family_status'
                          size='small'
                          value={this.state.family_status}
                          choices={this.state.fields.FamilyStatus}
                          onChange={this.setPregnantSelectValOfPregnant}
                        />
                      </div>
                      <div>
                        <TextLabel>Социальный статус</TextLabel>
                        <PregnantSelect
                          id='social_status'
                          size='small'
                          value={this.state.social_status}
                          choices={this.state.fields.SocialStatus}
                          onChange={this.setPregnantSelectValOfPregnant}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='main_block'>
                    <div className='main_block_form_row'>
                      <Title>Информация о ПМСП</Title>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <TextLabel>ПМСП</TextLabel>
                        <TextValue>{this.state.pmsp.name}</TextValue>
                      </div>
                      <div>
                        <TextLabel>Участок</TextLabel>
                        <TextValue>{this.state.name}</TextValue>
                      </div>
                      {this.state.therapist[0]?.full_name && (
                        <div>
                          <TextLabel>Лечащий врач</TextLabel>
                          <TextValue>{this.state.therapist[0]?.full_name}</TextValue>
                        </div>
                      )}
                      {this.state.therapist[0]?.phone?.length && (
                        <div>
                          <TextLabel>Телефон лечащего врача</TextLabel>
                          <TextValue>
                            {this.state.therapist[0]?.phone?.length ? this.state.therapist[0]?.phone[0]?.phone : ''}
                          </TextValue>
                        </div>
                      )}
                    </div>
                    {
                      <Grid container>
                        {this.state.health_visitor[0]?.full_name ? (
                          <Grid xs={2} lg={2} md={2} item>
                            <TextLabel>Патронажная сестра</TextLabel>
                            <TextValue>{this.state.health_visitor[0]?.full_name}</TextValue>
                          </Grid>
                        ) : (
                          ''
                        )}
                        {this.state.health_visitor[0]?.phone?.length ? (
                          <Grid xs={2} lg={2} md={2} item>
                            <TextLabel>Телефон патронажной сестры</TextLabel>
                            <TextValue>
                              {this.state.health_visitor[0]?.phone?.length
                                ? this.state.health_visitor[0].phone[0].phone
                                : ''}
                            </TextValue>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                    }
                  </div>
                  <div className='main_block'>
                    <div className='main_block_form_row'>
                      <Title>Анамнез</Title>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <TextLabel>Группа крови</TextLabel>
                        <PregnantSelect
                          className='anamnesis_small_input'
                          id='blood_type'
                          size='small'
                          type='text'
                          variant='outlined'
                          choices={this.state.fields.BloodType}
                          value={this.state.blood_type}
                          onChange={this.setPregnantSelectValOfPregnant}
                        />
                      </div>
                      <div>
                        <TextLabel>Резус</TextLabel>
                        <PregnantSelect
                          className='anamnesis_small_input'
                          id='rh_factor'
                          size='small'
                          type='text'
                          variant='outlined'
                          choices={this.state.fields.RhFactor}
                          value={this.state.rh_factor}
                          onChange={this.setPregnantSelectValOfPregnant}
                        />
                      </div>
                      <div>
                        <TextLabel>Вес до беременности</TextLabel>
                        <TextField
                          className='anamnesis_small_input'
                          id='weight_before_pregnancy'
                          helperText={this.state.validation.weight_helper_text}
                          size='small'
                          variant='outlined'
                          value={this.state.weight_before_pregnancy}
                          onChange={(event) => this.handleSetWeightVal(event.target.value)}
                        />
                      </div>
                      <div>
                        <TextLabel>Рост</TextLabel>
                        <TextField
                          className='anamnesis_small_input'
                          helperText={this.state.validation.height_helper_text}
                          id='height'
                          size='small'
                          variant='outlined'
                          value={this.state.height}
                          onChange={(event) => this.handleSetHeightVal(event.target.value)}
                        />
                      </div>
                      <div>
                        <TextLabel>Всего беременностей</TextLabel>
                        <TextField
                          className='total_pregnancies'
                          helperText={this.state.validation.total_pregnancies_helper_text}
                          id='total_pregnancies'
                          name='Всего беременностей'
                          size='small'
                          variant='outlined'
                          value={this.state.total_pregnancies}
                          onChange={(event) =>
                            this.handleSetValWithZeroToThirtyValidation(event.target.id, event.target.value)
                          }
                        />
                      </div>
                      <div>
                        <TextLabel>Роды</TextLabel>
                        <TextField
                          name='Роды'
                          helperText={this.state.validation.total_births_helper_text}
                          className='anamnesis_small_input'
                          id='total_births'
                          size='small'
                          variant='outlined'
                          value={this.state.total_births}
                          onChange={(event) =>
                            setValWithZeroToThirtyValidation(
                              this,
                              event.target.id,
                              event.target.value,
                              'total_births_helper_text',
                              'Роды'
                            )
                          }
                        />
                      </div>
                      <div>
                        <TextLabel>Аборты</TextLabel>
                        <TextField
                          name='Аборты'
                          className='anamnesis_small_input'
                          helperText={this.state.validation.total_abortions_helper_text}
                          id='total_abortions'
                          size='small'
                          variant='outlined'
                          value={this.state.total_abortions}
                          onChange={(event) =>
                            setValWithZeroToThirtyValidation(
                              this,
                              event.target.id,
                              event.target.value,
                              'total_abortions_helper_text',
                              'Аборты'
                            )
                          }
                        />
                      </div>
                      <div>
                        <TextLabel>Выкидыши</TextLabel>
                        <TextField
                          name='Выкидыши'
                          className='anamnesis_small_input'
                          id='total_miscarriages'
                          helperText={this.state.validation.total_miscarriages_helper_text}
                          size='small'
                          variant='outlined'
                          value={this.state.total_miscarriages}
                          onChange={(event) =>
                            setValWithZeroToThirtyValidation(
                              this,
                              event.target.id,
                              event.target.value,
                              'total_miscarriages_helper_text',
                              'Выкидыши'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className='main_block_form_row align-items-bottom'>
                      <div>
                        <TextLabel>Тип беременности</TextLabel>
                        <PregnantSelect
                          id='type_of_pregnancy'
                          size='small'
                          type='text'
                          variant='outlined'
                          choices={this.state.fields.TypeOfPregnancy}
                          value={this.state.type_of_pregnancy}
                          onChange={this.setPregnantSelectValOfPregnant}
                        />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <TextLabel>Осложнения предыдущей беременности</TextLabel>
                        <PregnantMultiSelect
                          id='complication_of_previous_pregnancy'
                          size='small'
                          type='text'
                          variant='outlined'
                          multiple={true}
                          choices={this.state.fields.ComplicationOfPreviousPregnancy}
                          value={this.state.complication_of_previous_pregnancy}
                          onChange={this.setMultiSelectValOfPregnant}
                        />
                      </div>
                      <div>
                        <TextLabel>Факторы риска</TextLabel>
                        <PregnantMultiSelect
                          key={1}
                          id='risk_factor'
                          size='small'
                          multiple={true}
                          type='text'
                          variant='outlined'
                          value={this.state.risk_factor}
                          choices={this.state.fields.RiskFactor}
                          onChange={this.setMultiSelectValOfPregnant}
                        />
                      </div>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <TextLabel>Сопутствующие заболевания</TextLabel>
                        <PregnantMultiSelect
                          id='accompanying_illness'
                          size='small'
                          type='text'
                          variant='outlined'
                          choices={this.state.fields.AccompanyingIllness}
                          value={this.state.accompanying_illness}
                          onChange={this.setMultiSelectValOfPregnant}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='main_block'>
                    <div className='main_block_form_row'>
                      <Title>Информация об отце</Title>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <TextLabel>ФИО отца</TextLabel>
                        <TextField
                          id='full_name'
                          helperText={this.state.validation.father_fio_helper_text}
                          size='small'
                          type='text'
                          variant='outlined'
                          value={this.state.father_info?.full_name}
                          onChange={(event) => setFioVal(this, event.target.id, event.target.value, 'father')}
                        />
                      </div>
                      <div>
                        <TextLabel>Группа крови</TextLabel>
                        <PregnantSelect
                          id='blood_type'
                          size='small'
                          type='text'
                          variant='outlined'
                          choices={this.state.fields.BloodType}
                          value={this.state.father_info.blood_type}
                          onChange={this.setPregnantSelectValOfFather}
                        />
                      </div>
                      <div>
                        <TextLabel>Резус</TextLabel>
                        <PregnantSelect
                          id='rh_factor'
                          size='small'
                          type='text'
                          variant='outlined'
                          value={this.state.father_info.rh_factor}
                          choices={this.state.fields.RhFactor}
                          onChange={this.setPregnantSelectValOfFather}
                        />
                      </div>
                      <div>
                        <TextLabel>Социальный статус</TextLabel>
                        <PregnantSelect
                          id='social_status'
                          size='small'
                          type='text'
                          variant='outlined'
                          value={this.state.father_info.social_status}
                          choices={this.state.fields.SocialStatus}
                          onChange={this.setPregnantSelectValOfFather}
                        />
                      </div>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <TextLabel>Сопутствующие заболевания</TextLabel>
                        <PregnantMultiSelect
                          key={3}
                          id='accompanying_illness'
                          size='small'
                          type='text'
                          variant='outlined'
                          choices={this.state.fields.AccompanyingIllness}
                          value={this.state.father_info.accompanying_illness}
                          onChange={this.setMultiSelectValOfFather}
                        />
                      </div>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2 }}>
                          <TextLabel>Номер телефона отца</TextLabel>
                          <IconButton
                            size='small'
                            onClick={() => this.toggleAddPhoneModal('father')}
                            style={{
                              textTransform: 'none',
                              backgroundColor: '#5280FF',
                              color: 'white',
                              fontWeight: 'bolder',
                              height: '1.2em',
                              width: '1.2em',
                              borderRadius: '25%',
                              border: '2px solid #c5d7fb',
                            }}
                            variant='contained'
                          >
                            <AddIcon style={{ fontSize: '1.15em' }} />
                          </IconButton>
                        </div>
                        <PregnantPhones
                          error={Boolean(this.state.validation.father_phones_helper_text)}
                          helperText={this.state.validation.father_phones_helper_text}
                          removePhone={this.removePhone}
                          editPhone={this.editPhone}
                          toggleEditPhoneModal={this.toggleEditPhoneModal}
                          key={2}
                          id='father_phones'
                          target='father'
                          value=''
                          size='small'
                          type='text'
                          variant='outlined'
                          choices={this.state.father_info?.phone}
                          // onChange={this.setSelectVal_info_about_pregnant_at_current_pregnancy}
                        />
                      </div>
                      <div>
                        <PhoneAdd
                          id='phone_modal_father'
                          subject='father'
                          data={this.state.phone_modal_toggled.father.data}
                          toggled={this.state.phone_modal_toggled.father.status}
                          addPhone={this.addNewPhone}
                          toggleAddPhoneModal={this.toggleAddPhoneModal}
                          clearPhoneModalEditData={this.clearPhoneModalEditData}
                          handlePhonesChanged={this.handlePhonesChanged}
                        />
                        <PhoneEdit
                          id='phone_modal_father'
                          subject='father'
                          phone={this.state.phone_modal_toggled.father.data?.phone}
                          phone_name={this.state.phone_modal_toggled.father.data?.name}
                          is_main={this.state.phone_modal_toggled.father.data?.is_main}
                          edit_index={this.state.phone_modal_toggled.father.edit_index}
                          toggled={this.state.phone_modal_toggled.father.edit_status}
                          toggleEditPhoneModal={this.toggleEditPhoneModal}
                          editPhone={this.editPhone}
                          clearPhoneModalEditData={this.clearPhoneModalEditData}
                          handlePhonesChanged={this.handlePhonesChanged}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='main_block'>
                    <div className='main_block_form_row'>
                      <Title>Хронологическая сводка</Title>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <TextLabel>Первый день посл.менструации</TextLabel>
                        <PregnantDatepicker
                          id='first_day_of_last_menstruation'
                          name='first_day_of_last_menstruation'
                          helperText={this.state.validation.first_day_of_last_menstruation_helper_text}
                          error={Boolean(this.state.validation.first_day_of_last_menstruation_helper_text)}
                          onChange={this.setDatePickerValinfo_about_pregnant_at_current_pregnancy}
                          value={this.state.first_day_of_last_menstruation}
                        />
                      </div>
                      <div>
                        <TextLabel>Дата родов (предполагаемая)</TextLabel>
                        <PregnantDatepicker
                          id='estimated_date_of_childbirth'
                          name='estimated_date_of_childbirth'
                          helperText={this.state.validation.estimated_date_of_childbirth_helper_text}
                          error={Boolean(this.state.validation.estimated_date_of_childbirth_helper_text)}
                          onChange={this.setDatePickerValinfo_about_pregnant_at_current_pregnancy}
                          value={this.state.estimated_date_of_childbirth}
                        />
                      </div>
                      <div>
                        <TextLabel>Дата взятия на учёт ТМЦ</TextLabel>
                        <PregnantDatepicker
                          id='date_of_registration_by_tmc'
                          name='date_of_registration_by_tmc'
                          disabled={true}
                          helperText={this.state.validation.date_of_registration_by_tmc_helper_text}
                          error={Boolean(this.state.validation.date_of_registration_by_tmc_helper_text)}
                          onChange={this.setDatePickerValinfo_about_pregnant_at_current_pregnancy}
                          // value={this.state.info_about_pregnant_at_current_pregnancy.date_of_registration_by_tmc}
                          value={this.state.date_of_registration_by_tmc}
                        />
                      </div>
                    </div>
                    <div className='main_block_form_row'>
                      <div>
                        <TextLabel>Дата снятия с учёта</TextLabel>
                        <PregnantDatepicker
                          id='date_of_deregistration_by_tmc'
                          name='date_of_deregistration_by_tmc'
                          helperText={this.state.validation.date_of_deregistration_by_tmc_helper_text}
                          error={Boolean(this.state.validation.date_of_deregistration_by_tmc_helper_text)}
                          onChange={this.setDatePickerValinfo_about_pregnant_at_current_pregnancy}
                          value={this.state.date_of_deregistration_by_tmc}
                        />
                      </div>
                      <div>
                        <TextLabel>Причина снятия</TextLabel>
                        <PregnantSelect
                          id='reason_of_deregistration'
                          name='reason_of_deregistration'
                          error={this.state.validation.reason_of_deregistration_helper_text}
                          choices={this.state.fields.ReasonOfDeregistration}
                          onChange={this.setPregnantSelectValOfPregnant}
                          value={this.state.reason_of_deregistration}
                        />
                      </div>
                      <div>
                        <TextLabel>Дата родов (фактическая)</TextLabel>
                        <PregnantDatepicker
                          id='date_of_childbirth'
                          name='date_of_childbirth'
                          error={this.state.validation.date_of_childbirth_helper_text}
                          onChange={this.setDatePickerValinfo_about_pregnant_at_current_pregnancy}
                          value={this.state.date_of_childbirth}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pregnant_card_popup_card_comment'>
              <Grid mt={1} container style={{ justifyContent: 'flex-start' }}>
                <Grid xs={2} md={2} lg={2} item>
                  <Title>Комментарий</Title>
                </Grid>
              </Grid>
              <TextField
                id='comment'
                onChange={(event) => this.setCommentVal(event.target.value)}
                helperText={this.state.validation.comment_helper_text}
                aria-disabled
                value={this.state.comment}
                multiline
                rows={4}
                fullWidth
              ></TextField>
            </div>
            <Grid mt={1} style={{ justifyContent: 'flex-end' }} container>
              <PregnantButton
                disabled={this.state.is_save_button_disabled}
                fullWidth={true}
                title={this.state.save_button_text}
                onClick={() => {
                  this.patchPregnant(this.state)
                }}
              />
            </Grid>
          </>
        ) : (
          <Loading />
        )}
      </div>
    )
  }
}

Card.propTypes = {
  pregnant_id: PropTypes.number,
  notify: PropTypes.func,
  misPregnantIdWithParams: PropTypes.func,
  updateCardBlockComponent: PropTypes.func,
  getMisValidationOfPersonalDataWrap: PropTypes.func,
  filledPhonesSelect: PropTypes.func,
  updateCardBlockInfoAfterCardFormSave: PropTypes.func,
  setCurrentModal: PropTypes.func,
}
