import removeEmptyData from './remove-empty-data'

export default function parseDataForPost (data) {
  return removeEmptyData({
    type_of_violation: data.type_of_violation,
    violator: data.violator,
    comment: data.comment,
    pregnancy_id: data.pregnancy_id,
    date_of_creation: data.date_of_creation,
    doctor_username: data.doctor_username
  })
}
