const validationObject = {
  total_abortions_helper_text: '',
  total_births_helper_text: '',
  total_miscarriages_helper_text: '',
  total_pregnancies_helper_text: '',
  height_helper_text: '',
  weight_helper_text: '',
  iin_helper_text: '',
  date_of_birth_helper_text: '',
  father_fio_helper_text: '',
  pregnant_fio_helper_text: '',
  comment_helper_text: '',
  residential_address_helper_text: '',
  date_of_registration_helper_text: '',
  date_of_deregistration_helper_text: '',
  first_day_of_last_menstruation_helper_text: '',
  estimated_date_of_childbirth_helper_text: '',
  date_taken_on_registration_on_pmsp_helper_text: '',
  pregnant_phones_helper_text: ''
}

export default validationObject
