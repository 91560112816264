import fatherObject from './father-object'

const infoAboutPregnantAtCurrentPregnancyObject = {
  accompanying_illness: [],
  area_of_residence: {},
  blood_type: {},
  comment: '',
  complication_of_previous_pregnancy: [],
  date_of_deregistration_by_tmc: '',
  date_of_registration_by_tmc: '',
  estimated_date_of_childbirth: '',
  family_status: {},
  father_info: fatherObject,
  first_day_of_last_menstruation: null,
  height: 0,
  rh_factor: {},
  reason_of_deregistration: {},
  residential_address: '',
  risk_factor: [],
  social_status: {},
  total_abortions: 0,
  total_births: 0,
  total_miscarriages: 0,
  total_pregnancies: 0,
  type_of_pregnancy: {},
  weight_before_pregnancy: 0,
  date_of_childbirth: ''
}

export default infoAboutPregnantAtCurrentPregnancyObject
