import axios from 'axios'

export function postQualityChecklistViolationsCommon (data) {
  const request = axios({
    method: 'post',
    url: 'quality_checklist/violations/common',
    data
  })
  return request
}
