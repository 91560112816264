import { Container } from '@mui/material'
import { DataGrid as DataGridBase } from '@mui/x-data-grid'
import { React, useEffect, useState } from 'react'
import styled from '@emotion/styled'

export default function PregnantResultsTableDesktop (props) {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])

  const DataGrid = styled(DataGridBase)(({ theme }) => ({
    border: 'none',
    [`& .MuiDataGrid-cell`]: {/* eslint-disable-line quotes  */
      // border: "none",
    },
    [`& .MuiDataGrid-columnHeaders`]: { /* eslint-disable-line quotes  */
      backgroundColor: 'unset'
    },
    [`& .MuiDataGrid-virtualScroller`]: { /* eslint-disable-line quotes  */
      minHeight: '15vh'
    },
    [`& .MuiDataGrid-main`]: { /* eslint-disable-line quotes  */
      border: '1px solid #ced4da',
      borderRadius: '20px'
    }
  }))

  useEffect(() => {
    if (rows !== props.rows) {
      setRows(props.rows)
    }
    if (columns !== props.columns) {
      setColumns(props.columns)
    }
  })
  return (
    <Container>
      <DataGrid
        rows={rows}
        columns={columns}
        // onRowClick={(row) => props.handleRowClick(row.pregnancy_id)}
        getRowId={(row) => row.pregnancy_id}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        hideFooter
        disableRowSelectionOnClick
        editMode='row'
      />
    </Container>
  )
}
