export default function parseData (data) {
  if (data !== undefined && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      data[i].record_type = data[i].record_type ? data[i].record_type.name : data[i].record_type
      data[i].date_of_creation = data[i].date_of_creation ? data[i].date_of_creation.split('T')[0] : ''
      data[i].interaction_result = data[i].interaction_result ? data[i].interaction_result.name : ''
      data[i].doctor = data[i].doctor ? data[i].doctor.full_name : ''
    }
    return data
  } else {
    return []
  }
}
