import removeEmptyData from './remove-empty-data'
import * as _ from 'lodash'

const ObjectAssign = (target, ...sources) => {
  sources.forEach(source => {
    Object.keys(source).forEach(key => {
      const sVal = source[key]
      const tVal = target[key]
      target[key] = tVal && sVal && typeof tVal === 'object' && typeof sVal === 'object'
        ? ObjectAssign(tVal, sVal)
        : sVal
    })
  })
  return target
}

export default function parseData (data) {
  const patchObject = {}
  const pregnant = {}
  const pregnantObjectKeys = [
    'full_name',
    'iin',
    'date_of_birth',
    'phone'
  ]
  pregnantObjectKeys.reduce((accumulator, currentVal, index) => {
    return Object.assign(pregnant, { [currentVal]: data[currentVal] })
  }, 0)
  const infoAboutPregnantAtCurrentPregnancy = {}
  const infoAboutPregnantAtCurrentPregnancyObjectKeys = [
    'accompanying_illness',
    'area_of_residence',
    'blood_type',
    'comment',
    'complication_of_previous_pregnancy',
    'date_of_deregistration_by_tmc',
    'date_of_registration_by_tmc',
    'estimated_date_of_childbirth',
    'date_of_childbirth',
    'family_status',
    'first_day_of_last_menstruation',
    'height',
    'rh_factor',
    'reason_of_deregistration',
    'residential_address',
    'risk_factor',
    'social_status',
    'total_abortions',
    'total_births',
    'total_miscarriages',
    'total_pregnancies',
    'type_of_pregnancy',
    'weight_before_pregnancy'
  ]
  infoAboutPregnantAtCurrentPregnancyObjectKeys.reduce((accumulator, currentVal, index) => {
    console.log(currentVal, ' ', data[currentVal])
    return Object.assign(infoAboutPregnantAtCurrentPregnancy, { [currentVal]: data[currentVal] })
  }, 0)
  const fatherInfo = _.cloneDeep(data.father_info)
  ObjectAssign(infoAboutPregnantAtCurrentPregnancy, { father_info: fatherInfo })
  Object.assign(patchObject, { info_about_pregnant_at_current_pregnancy: infoAboutPregnantAtCurrentPregnancy })
  Object.assign(patchObject, { pregnant })
  Object.assign(patchObject, {
    info_about_pregnant_at_current_pregnancy: removeEmptyData(patchObject.info_about_pregnant_at_current_pregnancy)
  })
  patchObject.pregnant = removeEmptyData(patchObject.pregnant)
  patchObject.pregnancy_id = data.pregnancy_id
  return patchObject
}
