import { React, Component } from 'react'
import { Card } from '@mui/material'
import { getPregnancyIdHealthChecklist } from 'api/'
import parseData from './utils/parse-data'
import { columnsProperties } from './utils/columns-properties'

export default class HealthChecklist extends Component {
  constructor (props) {
    super(props)
    this.state = {
      max_retries: 5,
      rows: [],
      columns: [],
      loading: true,
      pregnant_id: this.props.pregnant_id
    }
  }

  pregnancyHealthChecklistWithParams = () => {
    getPregnancyIdHealthChecklist(this.state.pregnant_id)
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              rows: parseData(res.data.health_checklists),
              columns: columnsProperties(),
              loading: false
            }
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  componentDidMount () {
    this.pregnancyHealthChecklistWithParams()
  }

  render () {
    return (
      <Card className='pregnant_card_block'>
      </Card>
    )
  }
}
