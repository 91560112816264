import axios from 'axios'

export async function postHealthChecklistComplicationInteraction (data) {
  const request = await axios({
    method: 'post',
    url: 'health_checklist/complication/interaction',
    data
  })
  return request
}
