export const pregnancyComplicationObject = {
  anamnesis: '',
  complaint: {
    name: ''
  },
  danger_sign: [],
  date_of_filling_checklist_of_health: '',
  diagnosis: {
    name: ''
  },
  diagnosis_status: {
    name: ''
  },
  record_type: {
    name: ''
  },
  taking_drugs: [],
  week_of_pregnancy_on_date_of_filling: 0
}
