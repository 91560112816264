import { Box, TextField } from '@mui/material'
import { React, useEffect, useRef } from 'react'

export default function NumberInputValue (props) {
  const { item, applyValue } = props
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.querySelector(`#input-number-filter-${item?.field}`).focus()
  })

  const handleChange = (event) => {
    applyValue({ ...item, value: event.target.value })
    props.handleFilterChange(props.getNumberFilterName(item?.field), event.target.value)
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px'
      }}
    >
      <TextField
        id={`input-number-filter-${item?.field}`}
        name='input-value'
        placeholder='Значение'
        label='Значение'
        variant='standard'
        value={item.value}
        onChange={handleChange}
        type='number'
        ref={inputRef}
      />
    </Box>
  )
}
