const statusColors = {
  'БЖ (Перезвонить)': 'yellow',
  'БЖ (Не дозвон)': 'gray',
  'БЖ (Обработанный звонок)': 'green',
  'БЖ (Не корректный номер)': 'purple',
  'БЖ (Не беспокоить)': 'red',
  'БЖ (Родила)': 'pink',
  'БЖ (Прервано)': 'turquoise',
  'БЖ (Госпитализирована)': 'orange'
}

export default statusColors
