import {
  isDateOfRegistrationLowerThanFirstDate,
  isDateOfRegistrationLowerThanNowDateForThenMonths,
  isDataOfRegistrationTodayOrLaterThanNowDate
} from '.'
import validationObject from '../../data-structure/validation-object'

export function AssertDateOfRegistrationValidation (dateOfRegistration, firstDayOfLastMenstruation) {
  if (!dateOfRegistration) {
    return validationObject.date_taken_on_registration_on_pmsp_helper_text
  }
  if (isDateOfRegistrationLowerThanFirstDate(dateOfRegistration, firstDayOfLastMenstruation)) {
    return 'Дата взятия не может быть раньше ПДПМ'
  }
  if (isDateOfRegistrationLowerThanNowDateForThenMonths(dateOfRegistration)) {
    return 'Дата взятия не может быть раньше 10 месяцев от текущей даты'
  }
  if (isDataOfRegistrationTodayOrLaterThanNowDate(dateOfRegistration)) {
    return 'Дата взятия на учёт должна быть раньше сегодняшней'
  }
  return ''
}
