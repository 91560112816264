import { React, Component } from 'react'
import Fab from '@mui/material/Fab'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import './widget.css'
import brightpatternConfig from 'brightpattern-config'

export default class Widget extends Component {
  constructor (props) {
    super(props)
    this.state = {
      widget_status: 'moved_in',
      bp_widget_height: '0vh',
      bp_widget_width: '0vh',
      bp_widget_zIndex: '-1'
    }
  }

  toggleBPWidget = () => {
    if (this.state.widget_status === 'moved_in') {
      this.setState({
        widget_status: 'moved_out',
        bp_widget_height: '60vh',
        bp_widget_width: '40vh'
      })
    } else {
      this.setState({
        widget_status: 'moved_in',
        bp_widget_height: '0vh',
        bp_widget_width: '0vh'
      })
    }
  }

  createAndToggle = () => {
    if (!this.props.loaded) {
      this.props.setIframeActive()
      setTimeout(() => {
        this.toggleBPWidget()
        this.props.initBrightPatternInterface()
      }, 1000)
    } else {
      this.toggleBPWidget()
    }
  }

  render () {
    return (
      <div
        id='bp_widget'
        style={{ width: this.state.bp_widget_width, height: this.state.bp_widget_height, zIndex: '10' }}
      >
        {this.props.loaded && (
          <div id='bp_widget_iframe' className={this.state.widget_status}>
            <iframe
              id='repeater'
              style={{ width: '40vh', height: '60vh' }}
              src={
                process.env.NODE_ENV === 'development'
                  ? brightpatternConfig.prod_repeater
                  : brightpatternConfig.repeater
              }
              frameBorder='0'
            ></iframe>
          </div>
        )}
        <Fab style={{ zIndex: '10 !important' }} id='bp_widget_button' onClick={() => this.createAndToggle()}>
          <LocalPhoneIcon />
        </Fab>
      </div>
    )
  }
}
