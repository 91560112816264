import { React, Component } from 'react'
import '../popup_components.css'

export default class CallsHistory extends Component {
  render () {
    return (
      <div className='pregnant_card_popup'>
        <div>
          <div></div>
        </div>
      </div>
    )
  }
}
