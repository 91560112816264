import axios from 'axios'

export async function postCallHistoryCreateCallHistory (callData) {
  const request = await axios({
    method: 'post',
    url: 'call_history/create_call_history',
    data: callData
  })
  return request
}
