export default function setWeightVal (weight) {
  if (/^\d+$/.test(weight) || weight === '') {
    let outputWeight
    let weightHelperText
    if (weight < 35) {
      outputWeight = weight >= 0 ? weight : 0
      weightHelperText = 'в поле вес допустимое значение: от 35 до 300'
      this.setState({
        validation: { ...this.state.validation, weight_helper_text: 'в поле вес допустимое значение: от 35 до 300' }
      })
    } else if (weight >= 299) {
      outputWeight = 299
    } else {
      weightHelperText = ''
      outputWeight = weight
    }
    if (this.state.weight !== outputWeight) {
      this.setState({ weight: outputWeight })
    }
    return [outputWeight, weightHelperText]
  }
}
