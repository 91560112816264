import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { React } from 'react'

export default function PregnantChosePhone(props) {
  if (!props.choices) {
    return <></>
  }
  return (
    <Select size='small' fullWidth onChange={(event) => props.onChange(event.target.value)}>
      {props.choices.map((obj, index) => {
        return (
          <MenuItem key={index} value={obj.phone}>
            {obj.name + ' ' + obj.phone}
          </MenuItem>
        )
      })}
    </Select>
  )
}
