import { Button, Typography, Grid, Dialog } from '@mui/material'
import { PregnantDatepicker, PregnantSelect, PregnantMultiSelect } from 'elements/'
import { React, Component } from 'react'
import childFieldsObject from '../objects/child-fields-object'
import isOnlyNumbersIncluded from '../validation/is-only-numbers-included'
import isLowerOrEqualToTwelve from '../validation/is-lower-or-equal-to-twelve'
import isOnlyOneSymbole from '../validation/is-only-one-symbole'
import ChildRequestsTable from '../elements/child-requests-table'
import columns from '../utils/child-requests-columns'
import { gaveBirthChildHistoryGet, gaveBirthChildFields, gaveBirthChildSave, gaveBirthChildGetId } from 'api/'
import { borderStyles, borderStylesWithMaxWidth, blockTitleStyles, infoTitleStyles, inputLabelStyles } from './styles'
import PropTypes from 'prop-types'
import { TextField } from '../elements/text-field'
import { convertArrayDataToValidArray, convertObjectDataToValidObject, convertToNameStr } from './common'

export default class Kid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggled: undefined,
      child_id: this.props.child_id,
      full_name: '',
      iin: '',
      pmsp: { name: '' },
      place_of_residence: '',
      residential_address: '',
      date_of_birth: '',
      pregnancy_id: this.props.pregnancy_id,
      risk_factor: [],
      accompanying_illness: [],
      vaccinations_in_the_hospital: [],
      date_of_last_patronage: '',
      total_number_of_patronages: 0,
      health_complaints_child: [],
      description_of_health_complaints: '',
      health_complaints_comment: '',
      complaints_and_suggestions_about_pmsp_work_child: [],
      description_of_complaints_and_suggestions_about_pmsp_work: '',
      complaints_and_suggestions_about_pmsp_work_comment: '',
      doctor_username: localStorage.getItem('username'),
      child_fields: childFieldsObject,
      columns,
      medical_procedures: { name: '' },
      request_status: { name: '' },
      rows: [],
      comment: '',
    }
  }

  handleClose = () => {
    this.props.handleKidModalClose()
  }

  setComment = (val) => {
    this.setState({ comment: val })
  }

  getChild = (childId) => {
    if (!childId) {
      return 0
    }
    gaveBirthChildGetId(childId)
      .then((res) => {
        this.setState({
          full_name: res.data.full_name,
          iin: res.data.iin ? res.data.iin : '',
          date_of_birth: res.data.date_of_birth,
          place_of_residence: res.data.place_of_residence ? res.data.place_of_residence : '',
          residential_address: res.data.residential_address ? res.data.residential_address : '',
          pmsp: res.data.pmsp,
          risk_factor: res.data.risk_factor,
          accompanying_illness: res.data.accompanying_illness,
          vaccinations_in_the_hospital: res.data.vaccinations_in_the_hospital,
          date_of_last_patronage: res.data.date_of_last_patronage,
          total_number_of_patronages: res.data.total_number_of_patronages,
          comment: res.data.comment ? res.data.comment : '',
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  saveChild = () => {
    const data = {
      child_id: this.state.child_id,
      full_name: this.state.full_name,
      pregnancy_id: this.state.pregnancy_id,
      risk_factor: this.state.risk_factor,
      accompanying_illness: this.state.accompanying_illness,
      vaccinations_in_the_hospital: this.state.vaccinations_in_the_hospital,
      total_number_of_patronages: this.state.total_number_of_patronages || 0,
      health_complaints_child: this.state.health_complaints_child,
      complaints_and_suggestions_about_pmsp_work_child: convertArrayDataToValidArray(
        this.state.complaints_and_suggestions_about_pmsp_work_child
      ),
      doctor_username: this.state.doctor_username,
    }
    if (this.state.date_of_birth) {
      data.date_of_birth = this.state.date_of_birth
    }
    if (this.state.iin) {
      data.iin = this.state.iin
    }
    if (this.state.place_of_residence) {
      data.place_of_residence = this.state.place_of_residence
    }
    if (this.state.pmsp?.name) {
      data.pmsp = this.state.pmsp
    }
    if (this.state.residential_address) {
      data.residential_address = this.state.residential_address
    }
    if (this.state.date_of_last_patronage) {
      data.date_of_last_patronage = this.state.date_of_last_patronage
    }
    if (this.state.description_of_health_complaints) {
      data.description_of_health_complaints = this.state.description_of_health_complaints
    }
    if (this.state.health_complaints_comment) {
      data.health_complaints_comment = this.state.health_complaints_comment
    }
    if (this.state.description_of_complaints_and_suggestions_about_pmsp_work) {
      data.description_of_complaints_and_suggestions_about_pmsp_work =
        this.state.description_of_complaints_and_suggestions_about_pmsp_work
    }
    if (this.state.complaints_and_suggestions_about_pmsp_work_comment) {
      data.complaints_and_suggestions_about_pmsp_work_comment =
        this.state.complaints_and_suggestions_about_pmsp_work_comment
    }
    if (this.state.medical_procedures.name) {
      data.medical_procedures = convertObjectDataToValidObject(this.state.medical_procedures)
    }
    if (this.state.request_status.name) {
      data.request_status = convertObjectDataToValidObject(this.state.request_status)
    }
    if (this.state.comment) {
      data.comment = this.state.comment
    }
    gaveBirthChildSave(data)
      .then((res) => {
        if (res.data) {
          this.props.setKey(this.props.setKeyProp + 1)
          this.handleClose()
          this.getChildHistory()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getChildHistory = (childId) => {
    gaveBirthChildHistoryGet(childId)
      .then((res) => {
        if (res.data) {
          const data = res.data
          res.data.reduce((accumulator, currentVal, index) => {
            currentVal.id = index
            return Object.assign({}, data[index], currentVal)
          })
          this.setState({ rows: res.data })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getChildFields = () => {
    gaveBirthChildFields()
      .then((res) => {
        if (res.data) {
          this.setState({ child_fields: res.data })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  handlesetComplaintsAndSuggestionsAboutPmspWorkChild = (id, val) => {
    if (this.state.complaints_and_suggestions_about_pmsp_work_child !== convertToNameStr(val)) {
      this.setState({ complaints_and_suggestions_about_pmsp_work_child: val })
    }
  }

  handleSetHealthComplaintsChild = (id, val) => {
    if (this.state.health_complaints_child !== convertToNameStr(val)) {
      this.setState({ health_complaints_child: val })
    }
  }

  handleSetMedicalProcedures = (id, val) => {
    this.setState({ medical_procedures: { name: val } })
  }

  handleSetRequestStatus = (id, val) => {
    this.setState({ request_status: { name: val } })
  }

  handleSetDateOfLastPatronage = (id, val) => {
    if (this.state.date_of_last_patronage !== val) {
      this.setState({ date_of_last_patronage: val })
    }
  }

  handleAccomplanyingIllness = (id, val) => {
    if (convertToNameStr(val) !== this.state.accompanying_illness) {
      this.setState({ accompanying_illness: val })
    }
  }

  handleSetDateOfBirth = (id, val) => {
    if (this.state.date_of_birth !== val) {
      this.setState({ date_of_birth: val })
    }
  }

  handleSetPmsp = (id, val) => {
    if (val !== this.state.pmsp?.name) {
      this.setState({ pmsp: val })
    }
  }

  setHealthComplaintsComment = (val) => {
    if (this.state.health_complaints_comment !== val) {
      this.setState({ health_complaints_comment: val })
    }
  }

  handleSetVaccinationsInTheHospital = (id, val) => {
    if (this.state.vaccinations_in_the_hospital !== convertToNameStr(val)) {
      this.setState({ vaccinations_in_the_hospital: val })
    }
  }

  setIin = (val) => {
    if (!isOnlyNumbersIncluded(val)) {
      return 0
    }
    if (!isLowerOrEqualToTwelve(val)) {
      return 0
    }

    this.setState({ iin: val })
  }

  handleSetTotalNumberOfPatronages = (val) => {
    if (isOnlyNumbersIncluded(val)) {
      if (isOnlyOneSymbole(val)) {
        this.setTotalNumberOfPatronages(val)
      }
    }
  }

  setFullName = (val) => {
    if (val !== this.state.full_name) {
      this.setState({ full_name: val })
    }
  }

  handleSetRiskFactor = (id, val) => {
    if (this.state.risk_factor !== convertToNameStr(val)) {
      this.setState({ risk_factor: val })
    }
  }

  setResidentialAddress = (val) => {
    if (this.state.residential_address !== val) {
      this.setState({ residential_address: val })
    }
  }

  setChildId = (val) => {
    if (this.state.child_id !== val && val) {
      this.setState({ child_id: val })
    }
  }

  setPregnancyId = (val) => {
    if (this.state.pregnancy_id !== val) {
      this.setState({ pregnancy_id: val })
    }
  }

  setPlaceOfResidence = (val) => {
    if (this.state.place_of_residence !== val) {
      this.setState({ place_of_residence: val })
    }
  }

  setTotalNumberOfPatronages = (val) => {
    if (this.state.total_number_of_patronages !== val) {
      this.setState({ total_number_of_patronages: val })
    }
  }

  setDescriptionOfComplaintsAndSuggestionsAboutPmspWork = (val) => {
    if (this.state.description_of_complaints_and_suggestions_about_pmsp_work !== val) {
      this.setState({ description_of_complaints_and_suggestions_about_pmsp_work: val })
    }
  }

  setComplaintsAndSuggestionsAboutPmspWorkComment = (val) => {
    if (this.state.complaints_and_suggestions_about_pmsp_work_comment !== val) {
      this.setState({ complaints_and_suggestions_about_pmsp_work_comment: val })
    }
  }

  setDescriptionOfHealthComplaints = (val) => {
    if (this.state.description_of_health_complaints !== val) {
      this.setState({ description_of_health_complaints: val })
    }
  }

  componentDidMount() {
    // this.getChildFields()
    // this.getChildHistory()
    // this.getChild(this.props.child_id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.pregnancy_id !== this.state.pregnancy_id) {
      this.setPregnancyId(this.props.pregnancy_id)
    }
    if (this.props.child_id !== this.state.child_id) {
      this.setState({ child_id: this.props.child_id })
      if (!isNaN(parseInt(this.props.child_id))) {
        this.getChildFields()
        this.getChildHistory(this.props.child_id)
        this.getChild(this.props.child_id)
      }
    }
  }

  render() {
    return (
      <Dialog
        onClose={this.handleClose}
        fullScreen
        open={this.props.toggled}
        style={{
          maxWidth: '95vw',
          maxHeight: '90vh',
          marginTop: '4vh',
          marginLeft: '2.4vw',
        }}
      >
        <Grid container p={3}>
          <Grid xs={12} md={12} lg={12} item container justifyContent='space-between'>
            <Grid xs={6} md={6} lg={6} item>
              <Typography style={blockTitleStyles}>Заполнить информацию о ребенке</Typography>
            </Grid>
            <Grid textAlign='end' xs={6} md={6} lg={6} item>
              <Typography style={{ cursor: 'pointer' }} onClick={() => this.handleClose()}>
                X
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} md={12} lg={12} style={borderStyles} item container p={3}>
            <Grid xs={12} lg={12} md={12} item container spacing={2}>
              <Grid xs={12} md={12} lg={12} item>
                <Typography style={infoTitleStyles}>Личные данные</Typography>
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>ФИО</Typography>
                <TextField value={this.state.full_name} onChange={this.setFullName}></TextField>
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>ИИН</Typography>
                <TextField value={this.state.iin} onChange={this.setIin}></TextField>
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>Дата рождения</Typography>
                <PregnantDatepicker value={this.state.date_of_birth} onChange={this.handleSetDateOfBirth} />
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>ПМСП</Typography>
                <PregnantSelect
                  key={1}
                  choices={this.state.child_fields.pmsp}
                  value={this.state.pmsp}
                  onChange={this.handleSetPmsp}
                />
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>Адрес прописки</Typography>
                <TextField value={this.state.place_of_residence} onChange={this.setPlaceOfResidence}></TextField>
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>Адрес фактического проживания</Typography>
                <TextField value={this.state.residential_address} onChange={this.setResidentialAddress}></TextField>
              </Grid>
            </Grid>
            <Grid xs={12} lg={12} md={12} item container spacing={2}>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>Факторы риска ребёнка</Typography>
                <PregnantMultiSelect
                  value={this.state.risk_factor.map((el) => el)}
                  onChange={this.handleSetRiskFactor}
                  choices={this.state.child_fields.risk_factors_child}
                />
              </Grid>
              <Grid xs={3} md={3} lg={3} item>
                <Typography style={inputLabelStyles}>Сопутствующие заболевания ребёнка</Typography>
                <PregnantMultiSelect
                  value={this.state.accompanying_illness.map((el) => el)}
                  onChange={this.handleAccomplanyingIllness}
                  choices={this.state.child_fields.accompanying_illnesses_child}
                />
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>Прививки в роддоме</Typography>
                <PregnantMultiSelect
                  value={this.state.vaccinations_in_the_hospital.map((el) => el)}
                  onChange={this.handleSetVaccinationsInTheHospital}
                  choices={this.state.child_fields.vaccinations_in_the_hospital_child}
                />
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>Дата последнего патронажа</Typography>
                <PregnantDatepicker
                  onChange={this.handleSetDateOfLastPatronage}
                  value={this.state.date_of_last_patronage || ''}
                />
              </Grid>
              <Grid xs={2} md={2} lg={2} item>
                <Typography style={inputLabelStyles}>Общее количество патронажей</Typography>
                <TextField
                  value={this.state.total_number_of_patronages || 0}
                  onChange={this.handleSetTotalNumberOfPatronages}
                ></TextField>
              </Grid>
              <Grid item xs={12} lg={12} md={12} container>
                <Typography style={inputLabelStyles}>Комментарий</Typography>
                <TextField value={this.state.comment} onChange={this.setComment} fullWidth={true} />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} md={12} lg={12} justifyContent='space-between' mt={4} item container>
            <Grid xs={6} md={6} lg={6} item container style={borderStylesWithMaxWidth} direction='columns' p={3}>
              <Typography style={infoTitleStyles}>Жалобы по здоровью</Typography>
              <Grid xs={12} md={12} lg={12} item container>
                <Grid xs={4} md={4} lg={4} item>
                  <Typography style={inputLabelStyles}>Категория жалобы по здоровью</Typography>
                  <PregnantMultiSelect
                    choices={this.state.child_fields.health_complaints_child}
                    value={this.state.health_complaints_child.map((el) => el)}
                    onChange={this.handleSetHealthComplaintsChild}
                    fullWidth={false}
                  />
                </Grid>
                <Grid xs={4} md={4} lg={4} item>
                  <Typography style={inputLabelStyles}>Врачебные мероприятия</Typography>
                  <PregnantSelect
                    key={2}
                    id='medical_procedures'
                    choices={this.state.child_fields?.medical_procedures}
                    value={this.state.medical_procedures.name}
                    onChange={this.handleSetMedicalProcedures}
                  />
                </Grid>
                <Grid xs={4} md={4} lg={4} item>
                  <Typography style={inputLabelStyles}>Оператор (статус обращения)</Typography>
                  <PregnantSelect
                    key={3}
                    id='request_status'
                    choices={this.state.child_fields?.request_status}
                    value={this.state.request_status.name}
                    onChange={this.handleSetRequestStatus}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <Typography style={inputLabelStyles}>Описание жалобы по здоровью</Typography>
                <TextField
                  value={this.state.description_of_health_complaints}
                  onChange={this.setDescriptionOfHealthComplaints}
                  fullWidth={true}
                ></TextField>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <Typography style={inputLabelStyles}>Комментарий</Typography>
                <TextField
                  value={this.state.health_complaints_comment}
                  onChange={this.setHealthComplaintsComment}
                  fullWidth={true}
                ></TextField>
              </Grid>
            </Grid>
            <Grid xs={6} md={6} lg={6} item container style={borderStylesWithMaxWidth} direction='columns' p={3}>
              <Typography style={infoTitleStyles}>Замечания и пожелания по работе ПМСП</Typography>
              <Grid xs={12} md={12} lg={12} item>
                <Typography style={inputLabelStyles}>Категория замечаний или пожеланий по работе ПМСП</Typography>
                <PregnantMultiSelect
                  choices={this.state.child_fields.complaints_and_suggestions_about_pmsp_work_child}
                  onChange={this.handlesetComplaintsAndSuggestionsAboutPmspWorkChild}
                  value={this.state.complaints_and_suggestions_about_pmsp_work_child.map((el) => el)}
                  fullWidth={false}
                />
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <Typography style={inputLabelStyles}>Описание замечаний или пожеланий по работе ПМСП</Typography>
                <TextField
                  onChange={this.setDescriptionOfComplaintsAndSuggestionsAboutPmspWork}
                  value={this.state.description_of_complaints_and_suggestions_about_pmsp_work}
                  fullWidth={true}
                ></TextField>
              </Grid>
              <Grid xs={12} md={12} lg={12} item>
                <Typography style={inputLabelStyles}>Комментарий</Typography>
                <TextField
                  onChange={this.setComplaintsAndSuggestionsAboutPmspWorkComment}
                  value={this.state.complaints_and_suggestions_about_pmsp_work_comment}
                  fullWidth={true}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <ChildRequestsTable rows={this.state.rows} columns={this.state.columns} />
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid xs={12} md={12} lg={12} item textAlign='end'>
              <Button
                onClick={() => {
                  this.saveChild()
                }}
                variant='contained'
                style={{ fontWeight: 'bold', fontSize: '16px', width: '15%', borderRadius: '6.7px' }}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}

Kid.propTypes = {
  child_id: PropTypes.number,
  toggled: PropTypes.bool,
  pregnancy_id: PropTypes.number,
  onChange: PropTypes.func,
  setKey: PropTypes.func,
  handleKidModalClose: PropTypes.func,
  setKeyProp: PropTypes.number,
}
