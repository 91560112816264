import { React, useState, useEffect } from 'react'
import { Grid, Dialog } from '@mui/material'
import AudioPlayer from './audio-player/audio-player'

export default function CallRecordingModal (props) {
  const [toggled, setToggled] = useState(props.toggled)

  const handleClose = (event, reason) => {
    return reason && reason === 'backdropClick' ? props.onClick() : 0
  }

  useEffect(() => {
    if (toggled !== props.toggled) {
      setToggled(props.toggled)
    }
  })
  // #TODO CREATE RECORD LISTENING LINK
  // const link =
  //   'https://smart-ticket.kz' +
  //   '/admin/tenantmgmt/download/records' +
  //   '?audiotag=1' +
  //   '&token=F801751F-A39B-4DCB-A885-57EEE0DD516A' +
  //   '&id=35455032784845819E217E902B22B5D3' +
  //   '&segments=' +
  //   '{' +
  //   '"3F5EA0C9428E48C994421A9B9E0C9E71' +
  //   '":"35455032784845819E217E902B22B5D3' +
  //   '}' +
  //   '&useRecInfoUtils=true' +
  //   '&interactionIds=["11F820F47EA2466880FF23F1008E6938'
  return (
    <Dialog onClose={handleClose} open={props.toggled} style={{ width: '28%', marginLeft: '8%' }}>
      <Grid p={2} py={4} container gap={2}>
        <Grid item container md={12} xs={12} lg={12} spacing={1}>
          <AudioPlayer
            // trackLink="https://eu.morsmusic.org/load/1612563569/ATL_-_Pod_nebom_salatovym_(musmore.com).mp3"
            // trackLink="https://smart-ticket.kz/admin/tenantmgmt/download/records?audiotag=1&amp;token=F801751F-A39B-4DCB-A885-57EEE0DD516A&amp;id=35455032784845819E217E902B22B5D3&amp;segments={&quot;3F5EA0C9428E48C994421A9B9E0C9E71&quot;:&quot;35455032784845819E217E902B22B5D3&quot;}&amp;useRecInfoUtils=true&amp;interactionIds=[&quot;11F820F47EA2466880FF23F1008E6938&quot;]"
            // trackLink={link}
            trackLink='https://smart-ticket.kz/admin/tenantmgmt/download/records?audiotag=1&amp;token=F801751F-A39B-4DCB-A885-57EEE0DD516A&amp;id=35455032784845819E217E902B22B5D3&amp;segments={"3F5EA0C9428E48C994421A9B9E0C9E71":"35455032784845819E217E902B22B5D3"}&amp;useRecInfoUtils=true&amp;interactionIds=["11F820F47EA2466880FF23F1008E6938"]'
            // trackLink="https://smart-ticket.kz/agentdesktop/msgplayback/?tenant_id=D3E9D306-1BB9-4A5F-88A8-498A76EA2077&rtp_processor_id=0D0D31B7-6294-435B-B9D0-DDF42956A69D&message=recordings/smart-ticket.kz/2023-09-11/11F820F4-7EA2-4668-80FF-23F1008E6938-77082254301-0B47F4F0-F00D-4A22-A14F-54305AFF8D74&key_id=0B47F4F0-F00D-4A22-A14F-54305AFF8D74&CRC=b48fa61506113d6df6420c11982a4fb5"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
