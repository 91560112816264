import { Button, Typography, Grid, Dialog } from '@mui/material'
import { PregnantSelect, PregnantDatepicker, PregnantMultiSelect } from 'elements/'
import { React, useState, useEffect } from 'react'
import childFieldsObject from '../objects/child-fields-object'
import { gaveBirthChildFields, gaveBirthChildSave } from 'api/'
import isOnlyNumbersIncluded from '../validation/is-only-numbers-included'
import isLowerOrEqualToTwelve from '../validation/is-lower-or-equal-to-twelve'
import isOnlyOneSymbole from '../validation/is-only-one-symbole'
import { borderStyles, borderStylesWithMaxWidth, blockTitleStyles, infoTitleStyles, inputLabelStyles } from './styles'
import { PropTypes } from 'prop-types'
import { TextField } from '../elements/text-field'
import { convertArrayDataToValidArray, convertObjectDataToValidObject, convertToNameStr } from './common'

export default function NewKid(props) {
  const [childId] = useState(null)
  const [fullName, setFullName] = useState('')
  const [iin, setIin] = useState('')
  const [pmsp, setPmsp] = useState({ name: '' })
  const [placeOfResidence, setPlaceOfResidence] = useState('')
  const [residentialAddress, setResidentialAddress] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [pregnancyId, setPregnancyId] = useState('')
  const [riskFactor, setRiskFactor] = useState([])
  const [accompanyingIllness, setAccompanyingIllness] = useState([])
  const [vaccinationsInTheHospital, setVaccinationsInTheHospital] = useState([])
  const [dateOfLastPatronage, setDateOfLastPatronage] = useState('')
  const [dateOfChildbirth] = useState(props.date_of_childbirth)
  const [totalNumberOfPatronages, setTotalNumberOfPatronages] = useState(0)
  const [healthComplaintsChild, setHealthComplaintsChild] = useState([])
  const [descriptionOfHealthComplaints, setDescriptionOfHealthComplaints] = useState('')
  const [healthComplaintsComment, setHealthComplaintsComment] = useState('')
  const [complaintsAndSuggestionsAboutPmspWorkChild, setComplaintsAndSuggestionsAboutPmspWorkChild] = useState([])
  const [descriptionOfComplaintsAndSuggestionsAboutPmspWork, setDescriptionOfComplaintsAndSuggestionsAboutPmspWork] =
    useState('')
  const [complaintsAndSuggestionsAboutPmspWorkComment, setComplaintsAndSuggestionsAboutPmspWorkComment] = useState('')
  const [doctorUsername] = useState(localStorage.getItem('username'))
  const [childFields, setChildFields] = useState(childFieldsObject)
  const [medicalProcedures, setMedicalProcedures] = useState({ name: '' })
  const [requestStatus, setRequestStatus] = useState({ name: '' })
  const [comment, setComment] = useState('')

  const handleClose = () => {
    props.handleNewKidModalClose()
  }

  const handleSetIin = (val) => {
    if (!isIinCorrect(val)) {
      return 0
    }
    return setIin(val)
  }

  const isIinCorrect = (val) => {
    return isOnlyNumbersIncluded(val) && isLowerOrEqualToTwelve(val)
  }
  const isTotalNumberOfPatronagesCorrect = (val) => {
    return isOnlyNumbersIncluded(val) && isOnlyOneSymbole(val)
  }

  const handleSetTotalNumberOfPatronages = (val) => {
    if (!isTotalNumberOfPatronagesCorrect(val)) {
      return 0
    }
    return setTotalNumberOfPatronages(val)
  }

  const saveChild = () => {
    const data = {
      child_id: childId,
      full_name: fullName,
      pregnancy_id: pregnancyId,
      risk_factor: convertArrayDataToValidArray(riskFactor),
      accompanying_illness: convertArrayDataToValidArray(accompanyingIllness),
      vaccinations_in_the_hospital: convertArrayDataToValidArray(vaccinationsInTheHospital),
      total_number_of_patronages: totalNumberOfPatronages || 0,
      health_complaints_child: convertArrayDataToValidArray(healthComplaintsChild),
      complaints_and_suggestions_about_pmsp_work_child: convertArrayDataToValidArray(
        complaintsAndSuggestionsAboutPmspWorkChild
      ),
      doctor_username: doctorUsername,
    }
    if (dateOfLastPatronage) {
      Object.assign(data, { date_of_last_patronage: dateOfLastPatronage })
    }
    if (descriptionOfHealthComplaints) {
      Object.assign(data, { description_of_health_complaints: descriptionOfHealthComplaints })
    }
    if (healthComplaintsComment) {
      Object.assign(data, { health_complaints_comment: healthComplaintsComment })
    }
    if (complaintsAndSuggestionsAboutPmspWorkComment) {
      Object.assign(data, {
        complaints_and_suggestions_about_pmsp_work_comment: complaintsAndSuggestionsAboutPmspWorkComment,
      })
    }
    if (descriptionOfComplaintsAndSuggestionsAboutPmspWork) {
      Object.assign(data, {
        description_of_complaints_and_suggestions_about_pmsp_work: descriptionOfComplaintsAndSuggestionsAboutPmspWork,
      })
    }
    if (dateOfBirth) {
      Object.assign(data, { date_of_birth: dateOfBirth })
    }
    if (iin) {
      Object.assign(data, { iin })
    }
    if (placeOfResidence) {
      Object.assign(data, { place_of_residence: placeOfResidence })
    }
    if (pmsp.name) {
      Object.assign(data, { pmsp: convertObjectDataToValidObject(pmsp) })
    }
    if (residentialAddress) {
      Object.assign(data, { residential_address: residentialAddress })
    }
    if (medicalProcedures.name) {
      Object.assign(data, { medical_procedures: convertObjectDataToValidObject(medicalProcedures) })
    }
    if (requestStatus.name) {
      Object.assign(data, { request_status: convertObjectDataToValidObject(requestStatus) })
    }
    if (comment) {
      Object.assign(data, { comment })
    }
    gaveBirthChildSave(data)
      .then((res) => {
        if (res.data) {
          props.getData(props.query)
          handleClose()
        }
      })
      .catch((err) => console.log(err))
  }

  const getChildFields = () => {
    gaveBirthChildFields()
      .then((res) => {
        if (res.data) {
          setChildFields(res.data)
        }
      })
      .catch((err) => console.log(err))
  }
  const handlesetComplaintsAndSuggestionsAboutPmspWorkChild = (id, val) => {
    setComplaintsAndSuggestionsAboutPmspWorkChild(convertToNameStr(val))
  }

  const handleSetHealthComplaintsChild = (id, val) => {
    setHealthComplaintsChild(convertToNameStr(val))
  }

  const handleSetDateOfLastPatronage = (id, val) => {
    setDateOfLastPatronage(val)
  }

  const handleAccomplanyingIllness = (id, val) => {
    setAccompanyingIllness(convertToNameStr(val))
  }

  const handleSetDateOfBirth = (id, val) => {
    setDateOfBirth(val)
  }

  const handleSetPmsp = (id, val) => {
    setPmsp({ name: val })
  }
  const handleSetMedicalProcedures = (id, val) => {
    setMedicalProcedures({ name: val })
  }
  const handleSetRequestStatus = (id, val) => {
    setRequestStatus({ name: val })
  }
  const handleSetVaccinationsInTheHospital = (id, val) => {
    setVaccinationsInTheHospital(convertToNameStr(val))
  }
  const handleSetRiskFactor = (id, val) => {
    setRiskFactor(convertToNameStr(val))
  }
  useEffect(() => {
    if (props.pregnancy_id !== pregnancyId) {
      setPregnancyId(props.pregnancy_id)
    }
  })
  useEffect(() => {
    if (!isNaN(parseInt(pregnancyId))) {
      getChildFields()
    }
  }, [pregnancyId])

  return (
    <Dialog
      onClose={handleClose}
      fullScreen
      open={props.toggled}
      style={{ maxWidth: '95vw', maxHeight: '90vh', marginTop: '4vh', marginLeft: '2.4vw' }}
    >
      <Grid container p={3}>
        <Grid xs={12} md={12} lg={12} item container justifyContent='space-between'>
          <Grid xs={6} md={6} lg={6} item>
            <Typography style={blockTitleStyles}>Заполнить информацию о ребенке</Typography>
          </Grid>
          <Grid textAlign='end' xs={6} md={6} lg={6} item>
            <Typography style={{ cursor: 'pointer' }} onClick={() => handleClose()}>
              X
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={12} lg={12} style={borderStyles} item container p={3}>
          <Grid xs={12} lg={12} md={12} item container spacing={2}>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={infoTitleStyles}>Личные данные</Typography>
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>ФИО</Typography>
              <TextField value={fullName} onChange={setFullName}></TextField>
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>ИИН</Typography>
              <TextField value={iin} onChange={handleSetIin}></TextField>
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>Дата рождения</Typography>
              <PregnantDatepicker value={dateOfBirth || props.date_of_childbirth} onChange={handleSetDateOfBirth} />
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>ПМСП</Typography>
              <PregnantSelect onChange={handleSetPmsp} choices={childFields.pmsp} value={pmsp.name} />
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>Адрес прописки</Typography>
              <TextField value={placeOfResidence} onChange={setPlaceOfResidence}></TextField>
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>Адрес фактического проживания</Typography>
              <TextField value={residentialAddress} onChange={setResidentialAddress}></TextField>
            </Grid>
          </Grid>
          <Grid xs={12} lg={12} md={12} item container spacing={2}>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>Факторы риска ребёнка</Typography>
              <PregnantMultiSelect
                value={riskFactor.map((el) => el.name)}
                onChange={handleSetRiskFactor}
                choices={childFields.risk_factors_child}
              />
            </Grid>
            <Grid xs={3} md={3} lg={3} item>
              <Typography style={inputLabelStyles}>Сопутствующие заболевания ребёнка</Typography>
              <PregnantMultiSelect
                value={accompanyingIllness.map((el) => el.name)}
                onChange={handleAccomplanyingIllness}
                choices={childFields.accompanying_illnesses_child}
              />
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>Прививки в роддоме</Typography>
              <PregnantMultiSelect
                value={vaccinationsInTheHospital.map((el) => el.name)}
                onChange={handleSetVaccinationsInTheHospital}
                choices={childFields.vaccinations_in_the_hospital_child}
              />
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>Дата последнего патронажа</Typography>
              <PregnantDatepicker onChange={handleSetDateOfLastPatronage} value={dateOfLastPatronage || ''} />
            </Grid>
            <Grid xs={2} md={2} lg={2} item>
              <Typography style={inputLabelStyles}>Общее количество патронажей</Typography>
              <TextField value={totalNumberOfPatronages} onChange={handleSetTotalNumberOfPatronages}></TextField>
            </Grid>
            <Grid item xs={12} lg={12} md={12} container>
              <Typography style={inputLabelStyles}>Комментарий</Typography>
              <TextField value={comment} onChange={setComment} fullWidth={true} />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={12} lg={12} justifyContent='space-between' mt={4} item container>
          <Grid xs={6} md={6} lg={6} item container style={borderStylesWithMaxWidth} direction='columns' p={3}>
            <Typography style={infoTitleStyles}>Жалобы по здоровью</Typography>
            <Grid xs={12} md={12} lg={12} item container>
              <Grid xs={4} md={4} lg={4} item>
                <Typography style={inputLabelStyles}>Категория жалобы по здоровью</Typography>
                <PregnantMultiSelect
                  choices={childFields.health_complaints_child}
                  value={healthComplaintsChild.map((el) => el.name)}
                  onChange={handleSetHealthComplaintsChild}
                  fullWidth={false}
                />
              </Grid>
              <Grid xs={4} md={4} lg={4} item>
                <Typography style={inputLabelStyles}>Врачебные мероприятия</Typography>
                <PregnantSelect
                  id='medical_procedures'
                  choices={childFields?.medical_procedures}
                  value={medicalProcedures.name}
                  onChange={handleSetMedicalProcedures}
                />
              </Grid>
              <Grid xs={4} md={4} lg={4} item>
                <Typography style={inputLabelStyles}>Оператор (статус обращения)</Typography>
                <PregnantSelect
                  id='request_status'
                  choices={childFields?.request_status}
                  value={requestStatus.name}
                  onChange={handleSetRequestStatus}
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Описание жалобы по здоровью</Typography>
              <TextField
                value={descriptionOfHealthComplaints}
                onChange={setDescriptionOfHealthComplaints}
                fullWidth={true}
              ></TextField>
            </Grid>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Комментарий</Typography>
              <TextField
                value={healthComplaintsComment}
                onChange={setHealthComplaintsComment}
                fullWidth={true}
              ></TextField>
            </Grid>
          </Grid>
          <Grid xs={6} md={6} lg={6} item container style={borderStylesWithMaxWidth} direction='columns' p={3}>
            <Typography style={infoTitleStyles}>Замечания и пожелания по работе ПМСП</Typography>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Категория замечаний или пожеланий по работе ПМСП</Typography>
              <PregnantMultiSelect
                choices={childFields.complaints_and_suggestions_about_pmsp_work_child}
                onChange={handlesetComplaintsAndSuggestionsAboutPmspWorkChild}
                value={complaintsAndSuggestionsAboutPmspWorkChild.map((el) => el.name)}
                fullWidth={false}
              />
            </Grid>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Описание замечаний или пожеланий по работе ПМСП</Typography>
              <TextField
                onChange={setDescriptionOfComplaintsAndSuggestionsAboutPmspWork}
                value={descriptionOfComplaintsAndSuggestionsAboutPmspWork}
                fullWidth={true}
              ></TextField>
            </Grid>
            <Grid xs={12} md={12} lg={12} item>
              <Typography style={inputLabelStyles}>Комментарий</Typography>
              <TextField
                onChange={setComplaintsAndSuggestionsAboutPmspWorkComment}
                value={complaintsAndSuggestionsAboutPmspWorkComment}
                fullWidth={true}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid xs={12} md={12} lg={12} item textAlign='end'>
            <Button
              onClick={() => {
                saveChild()
              }}
              variant='contained'
              style={{ fontWeight: 'bold', fontSize: '16px', width: '15%', borderRadius: '6.7px' }}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

NewKid.propTypes = {
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  toggled: PropTypes.bool,
  getData: PropTypes.func,
  date_of_childbirth: PropTypes.string,
  // query: PropTypes.string,
  pregnancy_id: PropTypes.number,
  handleNewKidModalClose: PropTypes.func,
}
