const checklistOfHealthComplicationObject = {
  complaint: [],
  danger_sign: [],
  taking_drugs: [],
  record_type: { name: '' },
  diagnosis: { name: '' },
  diagnosis_status: { name: '' },
  anamnesis: ''
}

export default checklistOfHealthComplicationObject
