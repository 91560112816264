const pregnantFieldsObject = {
  health_complaints: [
    {
      name: ''
    }
  ],
  complaints_and_suggestions_about_pmsp_work: [
    {
      name: ''
    }
  ],
  medical_procedures: [
    {
      name: ''
    }
  ],
  request_status: [
    {
      name: ''
    }
  ]
}

export default pregnantFieldsObject
