export const getNumberUpperFilterName = (columnName) => {
  switch (columnName) {
    case 'age':
      return 'last_age'
    case 'current_week_of_pregnancy':
      return 'further_past_week_of_pregnancy'
    default:
      return ''
  }
}
