import { React, Component } from 'react'
import getHealthChecklistFields from './api/get-health-checklist-fields'
import {
  getPregnancyIdPersonalData,
  getPrivateDataPregnantId,
  getHealthChecklistComplicationFields
} from 'api/'
import postHealthChecklist from './api/post-health-checklist'
import postHealthChecklistComplication from './api/post-health-checklist-complication'
import '../popup_components.css'
import PregnantButton from '../../elements/pregnant_button'
import PregnantMultiSelect from '../../elements/pregnant_multiselect/pregnant-multiselect'
import PregnantSelect from '../../elements/pregnant_select/pregnant_select'
import serializeDataForMultiselectState from './utils/serialize-data-for-multiselect-state'
import { Grid, TextField, Typography, Container } from '@mui/material'
import getHealthChecklistId from './api/get-health-checklist-id'
import healthChecklistComplicationFields from './data-structure/health-checklist-complication-fields'
import healthChecklistComplicationValues from './data-structure/health-checklist-complication-values'
import checklistOfHealthObject from './data-structure/checklist-of-health-object'
import checklistOfHealthSelectValues from './data-structure/checklist-of-health-select-values'
import checklistOfHealthValidationObject from './data-structure/checklist-of-health-validation-object'
import pregnancyObject from './data-structure/pregnancy-object'
import healthChecklistFields from './data-structure/health-checklist-fields'
import setWeightVal from './state-logic/set-weight-val'
import Loading from '../../loading/loading'
import checklistOfHealthComplicationObject from './data-structure/checklist-of-health-complication-object'
import checklistOfHealthUpdate from './state-logic/checklist-of-health-update'
import parseDataForPostHealthChecklist from './utils/parse-data-for-post-health-checklist'
import parseDataForPostHealthChecklistComplication from './utils/parse-data-for-post-health-checklist-complication'
import { PropTypes } from 'prop-types'

export default class HealthChecklist extends Component {
  constructor (props) {
    super(props)
    this.state = {
      asdasd: [],
      pregnant_id: this.props.pregnant_id,
      doctor_username: localStorage.getItem('username'),
      current_week_of_pregnancy: 0,
      health_checklist_fields: healthChecklistFields,
      ChecklistOfHealth: checklistOfHealthObject,
      PregnancyComplication: checklistOfHealthComplicationObject,
      checklist_of_health_select_values: checklistOfHealthSelectValues,
      Pregnancy: pregnancyObject,
      data: this.props.data,
      health_checklist_complication_fields: healthChecklistComplicationFields,
      health_checklist_complication_values: healthChecklistComplicationValues,
      checklist_id: this.props.data.id,
      pregnancy_id: parseInt(this.props.pregnant_id),
      max_retries: 5,
      pregnant_loading: true,
      health_checklist_fields_loading: true,
      personal_data_loading: true,
      health_checklist_complication_fields_loading: true,
      health_checklist_loading: true,
      validation: checklistOfHealthValidationObject
    }
  }

  healthChecklistFields = () => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getHealthChecklistFields()
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  health_checklist_fields: res.data,
                  health_checklist_fields_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  misPregnant = (pregnantId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getPrivateDataPregnantId(pregnantId)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  Pregnancy: res.data.Pregnancy,
                  pregnant_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  pregnancyPersonalData = (pregnantId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getPregnancyIdPersonalData(pregnantId)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  current_week_of_pregnancy: res.data.current_week_of_pregnancy,
                  personal_data_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  healthChecklistComplicationFields = () => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getHealthChecklistComplicationFields()
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  health_checklist_complication_fields: res.data,
                  health_checklist_complication_fields_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  healthChecklist = (dataId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getHealthChecklistId(dataId)
          .then((res) => {
            if (res.data) {
              checklistOfHealthUpdate(res, this, timeoutResponsesCache)
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  checkIsValid = (data) => {
    const validationKeys = Object.keys(data)
    const notValid = []
    for (let i = 0; i < validationKeys.length; i++) {
      if (data[validationKeys[i]] !== '') {
        notValid.push(data[validationKeys[i]])
      }
    }
    return [!notValid.length, notValid]
  }

  postHealthChecklistWrap = (data) => {
    const [isValid, notValidData] = this.checkIsValid(data.validation)
    if (!isValid) {
      let errorText = ''
      for (let i = 0; i < notValidData.length; i++) {
        errorText += notValidData[i] + '\n'
      }
      this.props.notify('error', errorText)
      return 0
    }
    const parsedData = parseDataForPostHealthChecklist(data)
    postHealthChecklist(parsedData)
      .then((res) => {
        const parsedDataComplication = parseDataForPostHealthChecklistComplication(data)
        postHealthChecklistComplication(parsedDataComplication)
        this.props
          .updateCardBlockComponent('health_checklist')
          .then((res) => {
            this.props.notify('success', 'Успешно сохранено')
            this.props.updateCardBlockComponent('complications')
            this.props.setCurrentModal(null, {})
          })
          .catch((err) => {
            this.props.notify('error', `Возникли ошибки ${JSON.stringify(err.response.data)}`)
          })
      })
      .catch((err) => {
        this.props.notify('error', `Возникли ошибки ${JSON.stringify(err.response)}`)
      })
  }

  componentDidMount () {
    this.healthChecklistFields()
    this.misPregnant(this.props.pregnant_id)
    this.pregnancyPersonalData(this.props.pregnant_id)
    this.healthChecklistComplicationFields()
    this.healthChecklist(this.props.data.id)
  }

  setSelectVal = (id, val) => {
    this.setState({
      ChecklistOfHealth: {
        ...this.state.ChecklistOfHealth,
        [id]: val
      },
      PregnancyComplication: {
        ...this.state.PregnancyComplication,
        [id]: val
      }
    })
  }

  setAnamnesisVal = (id, val) => {
    this.setState({
      PregnancyComplication: {
        ...this.state.PregnancyComplication,
        [id]: val
      }
    })
  }

  setMultiSelectVal = (id, val) => {
    this.setState({
      ChecklistOfHealth: {
        ...this.state.ChecklistOfHealth,
        [id]: serializeDataForMultiselectState(val)
      }
    })

    this.setState({
      checklist_of_health_select_values: {
        ...this.state.checklist_of_health_select_values,
        [id]: val
      }
    })

    this.setState({
      PregnancyComplication: {
        ...this.state.PregnancyComplication,
        [id]: serializeDataForMultiselectState(val)
      }
    })
  }

  setSelectValComplications = (id, val) => {
    this.setState({
      health_checklist_complication_values: {
        ...this.state.health_checklist_complication_values,
        [id]: val
      },
      validation: { ...this.state.validation, [id]: '' }
    })
    this.setState({ PregnancyComplication: { ...this.state.PregnancyComplication, [id]: { name: val } } })
    this.setState({ ChecklistOfHealth: { ...this.state.ChecklistOfHealth, [id]: { name: val } } })
  }

  render () {
    return (
      <Container>
        {this.state.health_checklist_fields_loading &&
        this.state.personal_data_loading &&
        this.state.pregnant_loading
          ? (
            <Loading></Loading>
            )
          : (
            <>
              <div style={{ maxHeight: '75vh', overflowY: 'auto', padding: '1%' }}>
                <div className='popup_card-block' style={{ minHeight: '30vh' }}>
                  <Grid gap={17} style={{ justifyContent: 'flex-start' }} container>
                    <Grid lg={2} md={2} sm={2} item>
                      <p className='row_input_title'>Жалобы</p>
                      <PregnantMultiSelect
                        choices={this.state.health_checklist_fields.Complaint}
                        id='complaint'
                        size='small'
                        multiple={true}
                        type='text'
                        variant='outlined'
                        value={this.state.checklist_of_health_select_values.complaint}
                        onChange={this.setMultiSelectVal}
                      />
                    </Grid>
                    <Grid lg={2} md={2} sm={2} item>
                      <p className='row_input_title'>Опасные признаки</p>
                      <PregnantMultiSelect
                        id='danger_sign'
                        size='small'
                        multiple={true}
                        type='text'
                        variant='outlined'
                        value={this.state.checklist_of_health_select_values.danger_sign}
                        onChange={this.setMultiSelectVal}
                        choices={this.state.health_checklist_fields.DangerSign}
                      />
                    </Grid>
                    <Grid lg={2} md={2} sm={2} item>
                      <p className='row_input_title'>Прием препаратов</p>
                      <PregnantMultiSelect
                        id='taking_drugs'
                        size='small'
                        multiple={true}
                        type='text'
                        variant='outlined'
                        value={this.state.checklist_of_health_select_values.taking_drugs}
                        onChange={this.setMultiSelectVal}
                        choices={this.state.health_checklist_fields.Drugs}
                      />
                    </Grid>
                    <Grid lg={1} md={1} sm={1} item>
                      <p className='row_input_title'>Вес</p>
                      <TextField
                        error={Boolean(this.state.validation.weight_helper_text)}
                        value={this.state.ChecklistOfHealth.weight}
                        onChange={(e) => setWeightVal(this, e.target.id, e.target.value)}
                        id='weight'
                        size='small'
                        helperText={this.state.validation.weight_helper_text}
                        type='number'
                      ></TextField>
                    </Grid>
                  </Grid>
                </div>
                <div className='popup_card-block' style={{ marginTop: '3%' }}>
                  <Grid container gap={4}>
                    <Grid lg={2} md={2} xs={2} item>
                      <Typography style={{ fontWeight: 'bold' }}>Неделя беременности на дату заполнения</Typography>
                      <Typography>{this.state.current_week_of_pregnancy}</Typography>
                    </Grid>
                    <Grid lg={4} md={4} xs={4} item>
                      <Typography style={{ fontWeight: 'bold' }}>Диагноз</Typography>
                      <PregnantSelect
                        error={this.state.validation.diagnosis}
                        id='diagnosis'
                        onChange={this.setSelectValComplications}
                        value={this.state.health_checklist_complication_values.diagnosis}
                        choices={this.state.health_checklist_complication_fields.Diagnosis}
                      />
                    </Grid>
                    <Grid lg={2} md={2} xs={2} item>
                      <Typography style={{ fontWeight: 'bold' }}>Тип записи</Typography>
                      <PregnantSelect
                        error={this.state.validation.record_type}
                        id='record_type'
                        onChange={this.setSelectValComplications}
                        value={this.state.health_checklist_complication_values.record_type}
                        choices={this.state.health_checklist_complication_fields.RecordType}
                      />
                    </Grid>
                    <Grid lg={2} md={2} xs={2} item>
                      <Typography style={{ fontWeight: 'bold' }}>Статус диагноза</Typography>
                      <PregnantSelect
                        error={this.state.validation.diagnosis_status}
                        id='diagnosis_status'
                        onChange={this.setSelectValComplications}
                        value={this.state.health_checklist_complication_values.diagnosis_status}
                        choices={this.state.health_checklist_complication_fields.DiagnosisStatus}
                      />
                    </Grid>
                  </Grid>
                  <Grid mt={3} container>
                    <Grid lg={12} md={12} xs={12} item>
                      <Typography style={{ fontWeight: 'bold' }}>Анамнез</Typography>
                      <TextField
                        id='anamnesis'
                        onChange={(e) => this.setAnamnesisVal(e.target.id, e.target.value)}
                        value={this.state.PregnancyComplication?.anamnesis}
                        multiline
                        rows={6}
                        fullWidth
                      ></TextField>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Grid style={{ justifyContent: 'flex-end' }} mt={5} gap={1} container>
                <PregnantButton
                  onClick={() => {
                    this.postHealthChecklistWrap(this.state)
                  }}
                  title='Сохранить'
                />
              </Grid>
            </>
            )}
      </Container>
    )
  }
}

HealthChecklist.propTypes = {
  pregnant_id: PropTypes.number,
  data: PropTypes.object,
  notify: PropTypes.func,
  updateCardBlockComponent: PropTypes.func,
  setCurrentModal: PropTypes.func
}
