import { React, Component } from 'react'
import { CardContent, Card } from '@mui/material'
import columnsProperties from './utils/columns-properties'
import { getPregnancyIdCallHistory } from 'api/'
import PregnantTable from './table/table'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import './all_calls.css'

export default class AllCalls extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      columns: [],
      data: [],
      max_retries: 5
    }
  }

  pregnancyIdCallHistory = () => {
    getPregnancyIdCallHistory(this.props.pregnant_id).then((res) => {
      if (res.data) {
        this.setState({
          rows: res.data.call_history,
          columns: columnsProperties
        })
      }
    })
  }

  componentDidMount () {
    this.pregnancyIdCallHistory()
  }

  render () {
    return (
      <Card>
        <CardContent>
          <div
            style={{
              height: '80vh',
              width: '90vw',
              position: 'relative',
              left: '99.5%',
              margin: '0 -100%',
              marginRight: '0 -100%'
            }}
          >
            <div className='sidebar_close_button' style={{ padding: '0.5%', width: '100%' }}>
              <a onClick={() => this.props.setCurrentModal(null, {})} style={{ pointerEvents: 'auto !important' }}>
                <CloseOutlinedIcon></CloseOutlinedIcon>
              </a>
            </div>
            <PregnantTable
              fullWidth={this.props.fullWidth}
              rows={this.state.rows}
              columns={this.state.columns}
              openRow={this.props.setCurrentModal}
              className='pregnant_card_table'
              hideFooterSelectedRowCount={true}
            ></PregnantTable>
          </div>
        </CardContent>
      </Card>
    )
  }
}
