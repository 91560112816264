import { Button } from '@mui/material'
import { React } from 'react'

export default function columnsProperties () {
  const colors = {
    'Приём к врачу': '#FF7817',
    Мониторинг: '#FFB320',
    'Вызов врача': '#FF7817',
    'Плановая госпитализация': '#E22828',
    Госпитализация: '#E22828'
  }
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 40,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'date_of_creation',
      headerName: 'Дата',
      width: 105,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'doctor_full_name',
      headerName: 'Врач ТМЦ',
      width: 160,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'record_type',
      headerName: 'Статус обращения',
      width: 135,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value === 'Плановая госпитализация') {
          params.value = 'Госпитализация'
        }
        if (!params.value) {
          return ''
        }
        return (
          <Button
            style={{
              cursor: 'auto',
              textTransform: 'none',
              backgroundColor: colors[params.value]
            }}
            disableRipple
            disableElevation
            disableFocusRipple
            variant='contained'
          >
            {params.value}
          </Button>
        )
      }
    },
    {
      field: 'last_interaction_result',
      headerName: 'Результат',
      width: 125,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) => {
        return params.value?.name || ''
      }
    }
  ]
}
