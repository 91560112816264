import { React, Component } from 'react'
import { CardContent, Card } from '@mui/material'
import columnsProperties from './utils/columns-properties'
import { getPregnancyIdViolations } from 'api/'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import PregnantTable from 'components/elements/table/table'

export default class AllViolations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      columns: [],
      loading: true,
      data: [],
      max_retries: 5
    }
  }

  pregnancyViolationsWithParams = () => {
    this.pregnancyViolations(this.props.pregnant_id)
  }

  pregnancyViolations = (pregnantId) => {
    getPregnancyIdViolations(pregnantId)
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              rows: res.data.violations,
              columns: columnsProperties(),
              loading: false
            }
          )
        }
      })
      .catch((err) => console.log(err))
  }

  componentDidMount () {
    this.pregnancyViolationsWithParams()
  }

  render () {
    return (
      <Card>
        <CardContent>
          <div
            style={{
              height: '80vh',
              width: '90vw',
              position: 'relative',
              left: '99.5%',
              margin: '0 -100%',
              marginRight: '0 -100%'
            }}
          >
            <div className='sidebar_close_button' style={{ padding: '0.5%', width: '100%' }}>
              <a onClick={() => this.props.setCurrentModal(null, {})} style={{ pointerEvents: 'auto !important' }}>
                <CloseOutlinedIcon></CloseOutlinedIcon>
              </a>
            </div>
            <PregnantTable
              loading={this.state.loading}
              fullWidth={this.props.fullWidth}
              rows={this.state.rows}
              columns={this.state.columns}
              openRow={this.props.setCurrentModal}
              className='pregnant_card_table'
              hideFooterSelectedRowCount={true}
            ></PregnantTable>
          </div>
        </CardContent>
      </Card>
    )
  }
}
