import checkIinWithBirthDate from '../check-iin-with-birthday'
import {
  checkIinWithZeroWithBirthDate
} from '../set-iin'
import { isDateOfBirthIsLowerThanNowForSeventyFiveYearsAndMore } from './is-date-of-birth-lower-than-now-for-seventy-five-years-and-more'
import { isDateOfBirthIsLowerThanNowForTenYears } from './is-date-of-birth-is-lower-than-now-for-ten-years'
import validationObject from '../../data-structure/validation-object'

export default function AssertDateOfBirthValidation (dateOfBirth, iin) {
  if (iin.slice(6, 7) === '0') {
    if (!checkIinWithZeroWithBirthDate(iin, dateOfBirth)) {
      return 'Дата не совпадает с ИИН'
    }
  }
  if (isDateOfBirthIsLowerThanNowForTenYears(dateOfBirth)) {
    return 'БЖ не может быть моложе 10 лет '
  }
  if (isDateOfBirthIsLowerThanNowForSeventyFiveYearsAndMore(dateOfBirth)) {
    return 'БЖ не может быть старше 75 лет '
  }
  if (!checkIinWithBirthDate(iin, dateOfBirth)) {
    return 'Дата рождения не соответствует ИИН '
  } else {
    if (!dateOfBirth) {
      return validationObject.date_of_birth_helper_text
    }
    return ''
  }
}
