import phoneIsEmptyWithSpaceExcluding from './phone-is-not-empty-with-space-excluding'

const phoneNameIsValid = (phoneName) => {
  if (!phoneName || phoneIsEmptyWithSpaceExcluding(phoneName)) {
    return false
  }
  return phoneName.length >= 3 && phoneName.length <= 20 && /^[А-яа-яA-za-z- ]*$/.test(phoneName)
}

export default phoneNameIsValid
