import { React, useState, useEffect } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { PregnantMultiselectValuesPlaceholder } from './pregnant-multiselect-values-placeholder'
import './pregnant-multiselect.css'

export function PregnantMultiSelect(props) {
  const [choices, setChoices] = useState(props.choices)
  const [selectValue, setSelectValue] = useState(props.value)
  useEffect((prevProps) => {
    if (props.choices !== choices) {
      setChoices(props.choices)
    }
    if (props.value !== prevProps) {
      setSelectValue(props.value)
    }
  })

  useEffect(() => {}, []) //! возможно удалить, тут были console.log - непонятно зачем

  const handleMultiSelectValue = (val) => {
    const valArrayWithNameKeys = []
    val.reduce((accumulator, currentVal, index) => {
      return valArrayWithNameKeys.push({ name: currentVal })
    }, 0)
    props.onChange(props.id, valArrayWithNameKeys)
  }

  return choices !== undefined && selectValue !== undefined ? (
    <div>
      <Select
        sx={{ maxWidth: 260 }}
        key={1}
        error={props.error}
        size='small'
        id={props.id}
        multiple
        value={selectValue.map((el) => el.name) || []}
        onChange={(event) => handleMultiSelectValue(event.target.value)}
        style={{ width: '100%' }}
      >
        {choices.map((obj, index) => {
          return (
            <MenuItem key={index} value={obj.name}>
              {obj.name}
            </MenuItem>
          )
        })}
      </Select>
      <PregnantMultiselectValuesPlaceholder chosen={selectValue.map((el) => el.name)} />
    </div>
  ) : (
    <div>
      <Select key={2} style={{ width: '100%' }}></Select>
    </div>
  )
}
