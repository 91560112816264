export default function setValWithZeroToThirtyValidation (id, val) {
  let outputValue
  if (/^\d+$/.test(val) || val === '') {
    if (val < 30) {
      outputValue = val >= 0 ? val : 0
    } else {
      outputValue = 29
    }
    return outputValue
  }
}
