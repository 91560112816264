const fatherObject = {
  id: '',
  accompanying_illness: [],
  blood_type: {},
  rh_factor: {},
  phone: [],
  social_status: {},
  full_name: '',
  date_of_birth: ''
}

export default fatherObject
