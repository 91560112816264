export function convertArrayDataToValidArray(arrayOfData) {
  const resArray = []
  arrayOfData.reduce((accumulator, currentVal) => {
    let val = currentVal['name']
    return resArray.push(val)
  }, 0)
  return resArray
}

export function convertObjectDataToValidObject(arrayOfData) {
  return arrayOfData['name']
}

export const convertToNameStr = (arr) => {
  const resArray = []
  arr.reduce((accumulator, currentVal) => {
    return resArray.push({ name: currentVal })
  }, 0)
  return resArray
}

// export const convertToNameStr = (arr) => {
//   const resArray = []
//   arr.reduce((accumulator, currentVal) => {
//     return resArray.push({ name: currentVal })
//   }, 0)
//   return resArray
// }
