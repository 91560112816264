import { React } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { DataGrid, ruRU } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import './table.css'

export default function PregnantTable(props) {
  const theme = createTheme(ruRU)
  const navigate = useNavigate()

  const columns = () => {
    return props.columns.map((col) => {
      switch (col.field) {
        default:
          return { ...col, hideable: false, sortable: true, disableColumnMenu: true }
      }
    })
  }

  function navigateToPregnant(path) {
    navigate(path)
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <DataGrid
          editMode='row'
          onRowClick={(params) => {
            navigateToPregnant(`/card/${params.row.pregnancy_id}`)
          }}
          disableRowSelectionOnClick
          columnVisibilityModel={{ id: false }}
          // columns={props.columns || []}
          columns={columns()}
          rows={props.rows || []}
          rowCount={props.totalItems}
          loading={props.loading}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          disableColumnFilter
        />
      </ThemeProvider>
    </div>
  )
}
