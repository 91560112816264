import { checklistWeekRows } from './checklist-week-rows'

const getChecklistWeekRows = checklistWeekRows

export default function parseData (data) {
  const dictData = {}
  for (let i = 0; i < data.length; i++) {
    data[i].term_of_pregnancy = data[i].term_of_pregnancy ? data[i].term_of_pregnancy.name : ''
    data[i].date_of_creation = data[i].date_of_creation.split('T')[0]
    dictData[data[i].term_of_pregnancy] = data[i]
  }
  const checklistWeekRows = getChecklistWeekRows()
  const checkListData = []
  for (let i = 0; i < checklistWeekRows.length; i++) {
    if (dictData[checklistWeekRows[i].term_of_pregnancy]) {
      checkListData.push(dictData[checklistWeekRows[i].term_of_pregnancy])
    } else {
      checkListData.push(checklistWeekRows[i])
    }
  }
  return checkListData
}
