import { React, useState, useEffect } from 'react'
import { Select, MenuItem } from '@mui/material/'

export default function PhonesSelect (props) {
  const [choices, setChoices] = useState(undefined)
  const [selectValue, setSelectValue] = useState(undefined)
  useEffect(() => {
    if (props.choices !== choices) {
      setChoices(props.choices)
    }
    if (props.value !== selectValue) {
      setSelectValue(props.value)
    }
  })

  return choices !== undefined && choices.length > 0
    ? (
      <div>
        <Select
          id={props.id}
          error={props.error || false}
          fullWidth={props.fullWidth || false}
          size='small'
          multiple={props.multiple}
          value={selectValue || ''}
          onChange={(value) => {
            props.onChange(props.id, value.target.value)
          }}
          style={{ width: '100%' }}
        >
          {choices.map((obj, index) => {
            return (
              <MenuItem key={index} value={obj.phone}>
                {obj.target + ' ' + obj.fio + ' ' + obj.phone + ' ' + (obj.is_main ? 'Основной' : '')}
              </MenuItem>
            )
          })}
        </Select>
      </div>
      )
    : (
        <></>
      )
}
