import {
  Box,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography
} from '@mui/material'
import { React } from 'react'

export function PatientHelpModal (props) {
  const complaint = props.data?.PregnancyComplication?.complaint
  const dateOfCreation = props.data?.PregnancyComplication?.date_of_creation
  const dangerSign = props.data?.PregnancyComplication?.danger_sign
  const recordType = props.data?.PregnancyComplication?.record_type?.name
  const anamnesis = props.data?.PregnancyComplication?.anamnesis
  const residentialAddress = props.data?.info_about_pregnant_at_current_pregnancy?.residential_address
  const week = props.data?.week
  const dateOfBirth = props.data?.pregnant?.date_of_birth
  const phone = props.data?.pregnant?.phone
  const iin = props.data?.pregnant?.iin
  const fullName = props.data?.pregnant?.full_name
  const therapistPhone = props.data?.pmsp_area?.therapist[0]?.phone
  const therapistName = props.data?.pmsp_area?.therapist[0]?.full_name
  const pmspArea = props.data?.pmsp_area?.name
  const pmsp = props.data?.pmsp_area?.pmsp?.name

  const getFormattedDate = (date) => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = newDate.getMonth() + 1
    const formattedMonth = month < 10 ? `0${month}` : month
    const day = newDate.getDate()
    const formattedDay = day < 10 ? `0${day}` : day
    return Number.isNaN(year) ? '' : `${year}-${formattedMonth}-${formattedDay}`
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '1px solid #ссс',
          boxShadow: 24,
          p: 2
        }}
      >
        <List>
          <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
            Информация о ПМСП
          </Typography>
          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='ПМСП:'
              secondary={pmsp}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Участок:'
              secondary={pmspArea}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Лечащий врач:'
              secondary={therapistName}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Телефон:'
              secondary={
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  {Array.isArray(therapistPhone) &&
                    therapistPhone?.map((item, index) => <span key={index}>{item?.phone}</span>)}
                </Box>
              }
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <Typography variant='subtitle1' sx={{ fontWeight: 'bold', mt: '10px' }}>
            Личное дело пациентки
          </Typography>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='ФИО:'
              secondary={fullName}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='ИИН:'
              secondary={iin}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Телефон:'
              secondary={
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  {Array.isArray(phone) && phone?.map((item, index) => <span key={index}>{item?.phone}</span>)}
                </Box>
              }
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Дата рождения:'
              secondary={dateOfBirth}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Неделя беременности:'
              secondary={week}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Адрес:'
              secondary={residentialAddress}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Дата звонка:'
              secondary={getFormattedDate(dateOfCreation)}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Жалобы:'
              secondary={
                Array.isArray(complaint) && complaint?.map((item, index) => <span key={index}>{item.name} </span>)
              }
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Опасные признаки:'
              secondary={
                Array.isArray(dangerSign) && dangerSign?.map((item, index) => <span key={index}>{item.name} </span>)
              }
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Тип записи:'
              secondary={recordType}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>

          <ListItem sx={{ p: '4px' }}>
            <ListItemText
              sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', m: '0' }}
              primary='Анамнез:'
              secondary={anamnesis}
              secondaryTypographyProps={{ sx: { color: '#1976d2' } }}
            />
          </ListItem>
        </List>
      </Box>
    </Modal>
  )
}
