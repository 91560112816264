import { Button } from '@mui/material'
import { React } from 'react'

export function PregnantButton (props) {
  return (
    <Button
      variant='contained'
      disabled={props.disabled || false}
      onClick={props.onClick}
      color='primary'
      fullWidth={props.fullWidth || false}
      style={{
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'none',
        width: '20%'
      }}>
      {props.title}
    </Button>
  )
}
