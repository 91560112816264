import { getQualityChecklistViolationsCommonFields } from 'api/control-checklist'

export default function stateGetQualityChecklistViolationsCommonFields (parent) {
  getQualityChecklistViolationsCommonFields()
    .then((res) => {
      parent.setState({
        type_of_violations: res.data.type_of_violations,
        violators: res.data.violatiors
      })
    })
    .catch((err) => console.log(err))
}
