function setFioVal (val, target) {
  if (target === 'pregnant') {
    let helperText = ''
    val = val.replace(/ +(?= )/g, '')
    if (val === ' ') {
      return 0
    }
    if (val === '') {
      helperText = 'Поле ФИО не может быть пустым'
      return this.setState({
        full_name: val,
        validation: { ...this.state.validation, pregnant_fio_helper_text: helperText }
      })
    }
    if (/^[А-яа-яA-za-z-ӘәЁёҚқІіҒғҰұӨөҮүҺһҢң\s]*$/.test(val)) {
      if (val.length > 75) {
        return 0
      }
      if (val.length < 3) {
        helperText = 'Поле ФИО должно содержать не менее 3 символов'
        return this.setState({
          pregnant: { ...this.state.pregnant, full_name: val },
          validation: { ...this.state.validation, pregnant_fio_helper_text: helperText }
        })
      }
      return this.setState({
        pregnant: { ...this.state.pregnant, full_name: val },
        validation: { ...this.state.validation, pregnant_fio_helper_text: helperText }
      })
    } else {
      helperText = 'В ФИО допустимы только буквы и тире'
    }
    this.setState({
      full_name: val,
      validation: { ...this.state.validation, pregnant_fio_helper_text: helperText }
    })
  } else if (target === 'father') {
    let helperText = ''
    val = val.replace(/ +(?= )/g, '')
    if (val === ' ') {
      return 0
    }
    if (val === '') {
      return this.setState({
        father_info: { ...this.state.father_info, full_name: val },
        validation: { ...this.state.validation, father_fio_helper_text: helperText }
      })
    }
    if (/^[А-яа-яA-za-z-ӘәЁёҚқІіҒғҰұӨөҮүҺһҢң\s]*$/.test(val)) {
      if (val.length > 75) {
        return 0
      }
      if (val.length < 3) {
        helperText = 'Поле ФИО должно содержать не менее 3 символов'
        return this.setState({
          father_info: { ...this.state.father_info, full_name: val },
          validation: { ...this.state.validation, father_fio_helper_text: helperText }
        })
      }
      return this.setState({
        father_info: { ...this.state.father_info, full_name: val },
        validation: { ...this.state.validation, father_fio_helper_text: helperText }
      })
    }
  }
}

export default setFioVal
