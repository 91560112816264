export default function parseData (data) {
  const result = []
  if (data !== undefined && data.length) {
    data.reduce((accumulator, currentValue) => {
      currentValue.date_of_doctors_appointment_or_hospitalization = currentValue.date_of_doctors_appointment_or_hospitalization
        ? currentValue.date_of_doctors_appointment_or_hospitalization.split('T')[0]
        : ''
      currentValue.date_of_filling_checklist = currentValue.date_of_filling_checklist
        ? currentValue.date_of_filling_checklist.split('T')[0]
        : ''
      currentValue.date_of_last_interaction = currentValue.date_of_last_interaction
        ? currentValue.date_of_last_interaction.split('T')[0]
        : ''
      return result.push(currentValue)
    }, 0)
    return result
  }
  return []
}
