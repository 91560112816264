import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PregnantTable from './components/elements/table/table'
import getPregnants from '../../api/pregnants-list/get-pregnants'
import getPregnanyFields from '../../api/pregnants-list/get-pregnancy-fields'
import { parseData, debounce, ColumnsProperties } from './utils'
import { getSortValue } from './utils/get-sort-value'
import Header from './components/header/header'
import Notification from 'components/notification/notification'
import { setFilterValues } from './utils/set-filter-values'
import { useSearchParams } from 'react-router-dom'
import { checkIsThereSomeValueInObject } from '../../shortcuts/check-is-there-some-value-in-object'
import Buttons from './components/buttons/buttons'
import { Box, Button, Checkbox, Grid, Switch, Typography } from '@mui/material'
import './pregnants_list.css'
import { gridFilterModelSelector, useGridApiRef } from '@mui/x-data-grid'
import postPregnancyListDelete from '../../api/pregnants-list/post-pregnancy-list-delete'

function PregnantsListView() {
  const apiRef = useGridApiRef()
  const queryParamsObject = useRef(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [rows, setRows] = useState([])
  const [columns, setColuns] = useState([])
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 0)
  const [pageSize, setPageSize] = useState(parseInt(searchParams.get('page_size')) || 14)
  const [totalItems, setTotalItems] = useState(0)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [search, setSearch] = useState(searchParams.get('search') || '')
  const [sorting, setSorting] = useState(searchParams.get('order_by') || '')
  const [fields, setFields] = useState({
    pmsp: [],
    pmsp_area: [],
    area_of_residence: [],
    reason_of_deregistration: [],
    status_of_call: [],
  })
  const [filters, setFilters] = useState({
    age_search: searchParams.get('age_search') || '',
    first_age: searchParams.get('first_age') || '',
    last_age: searchParams.get('last_age') || '',
    desired_week_of_pregnancy: searchParams.get('desired_week_of_pregnancy') || '',
    further_past_week_of_pregnancy: searchParams.get('further_past_week_of_pregnancy') || '',
    most_recent_week_of_pregnancy: searchParams.get('most_recent_week_of_pregnancy') || '',
    pmsp_search: searchParams.get('pmsp_search') || '',
    pmsp_area_search: searchParams.get('pmsp_area_search') || '',
    area_of_residence_search: searchParams.get('area_of_residence_search') || '',
    date_of_registration_by_tmc_search: searchParams.get('date_of_registration_by_tmc_search') || '',
    further_past_date_of_registration_by_tmc_search:
      searchParams.get('further_past_date_of_registration_by_tmc_search') || '',
    most_recent_date_of_registration_by_tmc_search:
      searchParams.get('most_recent_date_of_registration_by_tmc_search') || '',
    status_of_call_search: searchParams.get('status_of_call_search') || '',
    date_of_call_day_search: searchParams.get('date_of_call_day_search') || '',
    has_at_least_one_call_search: searchParams.get('has_at_least_one_call_search') || '',
  })
  const [isSomeFilterActive, setIsSomeFilterActive] = useState(false)
  const [isFlagRegisteredSelected, setIsFlagRegisteredSelected] = useState(
    searchParams.get('get_deregistrated') || false
  )
  const [has_at_least_one_call_search, setHasAtLeastOneCall] = useState(
    searchParams.get('has_at_least_one_call_search') || 'True'
  )
  const [pregnantsToRemove, setPregnantsToRemove] = useState([])
  const [canDelete, setCanDelete] = useState(false)

  const managePregnantsToRemove = (index) => {
    if (pregnantsToRemove.includes(parseInt(index))) {
      if (pregnantsToRemove.length === 1) {
        pregnantsToRemove.pop()
      } else {
        pregnantsToRemove.splice(pregnantsToRemove.indexOf(index), pregnantsToRemove.indexOf(index))
      }
    } else {
      pregnantsToRemove.push(parseInt(index))
    }
    setPregnantsToRemove(pregnantsToRemove)
  }

  const removePregnantsByIndexes = () => {
    const pregnancyidsForDelete = []
    pregnantsToRemove.map((indexOfPregnantInRows) => {
      return pregnancyidsForDelete.push({ id: rows[indexOfPregnantInRows].id })
    })
    postPregnancyListDelete({ pregnancy_ids_for_delete: pregnancyidsForDelete })
      .then((res) => {
        if (res.data) {
          getData()
          setPregnantsToRemove([])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getData = useCallback((data) => {
    setLoading(true)
    getPregnants({ ...data, page: data?.page + 1 })
      .then((res) => {
        if (res.data) {
          setRows(parseData(res.data))
          setColuns(ColumnsProperties(managePregnantsToRemove, pregnantsToRemove))
          setTotalItems(res.data.count)
          setCanDelete(res.data.can_delete_pregnancy)
          setError('')
          setIsError(false)
        }
      })
      .catch((err) => {
        setError(err?.response?.statusText)
        setIsError(true)
      })
      .finally(() => setLoading(false))
  }, [])

  const debouncedGetData = useMemo(() => debounce(getData, 800), [getData])

  const handlePageChange = (data) => {
    setPage(data.page)
    setPageSize(data?.pageSize)
  }

  const handleFilterChange = (name, value) => {
    setFilterValues(setFilters, name, value)
    setPage(0)
    setPageSize(14)
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setPage(0)
    setPageSize(14)
  }

  const handleCloseError = () => {
    setError('')
    setIsError(false)
  }

  const handleSortChange = (data) => {
    setSorting(getSortValue(data))
    setPage(0)
    setPageSize(14)
  }

  const handleFlagRegisteredSelectedChange = (event) => {
    setIsFlagRegisteredSelected(!isFlagRegisteredSelected)
  }
  const handleHasAtLeastOneCallSelectedChange = () => {
    resetFilters()
    setPage(0)
    if (has_at_least_one_call_search === 'True') {
      setHasAtLeastOneCall('False')
    } else {
      setHasAtLeastOneCall('True')
    }
  }

  const resetFilters = () => {
    setFilters({
      age_search: '',
      first_age: '',
      last_age: '',
      desired_week_of_pregnancy: '',
      further_past_week_of_pregnancy: '',
      most_recent_week_of_pregnancy: '',
      pmsp_search: '',
      pmsp_area_search: '',
      area_of_residence_search: '',
      date_of_registration_by_tmc_search: '',
      further_past_date_of_registration_by_tmc_search: '',
      most_recent_date_of_registration_by_tmc_search: '',
      status_of_call_search: '',
      date_of_call_day_search: '',
      has_at_least_one_call_search: 'True',
      isFlagRegisteredSelected: false,
    })
  }

  // create (update) an object for sending a request
  const createQueryParamsObject = useCallback(() => {
    queryParamsObject.current = {
      page,
      page_size: pageSize,
      search,
      age_search: filters.age_search,
      first_age: filters.first_age,
      last_age: filters.last_age,
      desired_week_of_pregnancy: filters.desired_week_of_pregnancy,
      further_past_week_of_pregnancy: filters.further_past_week_of_pregnancy,
      most_recent_week_of_pregnancy: filters.most_recent_week_of_pregnancy,
      pmsp_search: filters.pmsp_search,
      date_of_registration_by_tmc_search: filters.date_of_registration_by_tmc_search,
      further_past_date_of_registration_by_tmc_search: filters.further_past_date_of_registration_by_tmc_search,
      most_recent_date_of_registration_by_tmc_search: filters.most_recent_date_of_registration_by_tmc_search,
      status_of_call_search: filters.status_of_call_search,
      date_of_call_day_search: filters.date_of_call_day_search,
      order_by: sorting,
      has_at_least_one_call_search,
    }
    if (isFlagRegisteredSelected) {
      queryParamsObject.current.get_deregistrated = isFlagRegisteredSelected
    }
  }, [page, pageSize, sorting, search, filters, isFlagRegisteredSelected, has_at_least_one_call_search])

  // creating (updating) an object for sending a request
  useEffect(() => {
    createQueryParamsObject()
  }, [
    page,
    pageSize,
    sorting,
    search,
    filters,
    isFlagRegisteredSelected,
    has_at_least_one_call_search,
    createQueryParamsObject,
  ])

  // set search params to url
  useEffect(() => {
    setSearchParams(queryParamsObject.current)
  }, [
    page,
    pageSize,
    sorting,
    search,
    filters,
    isFlagRegisteredSelected,
    has_at_least_one_call_search,
    setSearchParams,
  ])

  // data request without delay
  useEffect(() => {
    getData(queryParamsObject.current)
  }, [page, sorting, isFlagRegisteredSelected, has_at_least_one_call_search, getData])

  // delayed data request
  useEffect(() => {
    debouncedGetData(queryParamsObject.current)
  }, [search, filters, debouncedGetData])

  useEffect(() => {
    getPregnanyFields()
      .then((res) => {
        setFields({
          pmsp: res.data.pmsp,
          pmsp_area: res.data.pmsp_area,
          area_of_residence: res.data.area_of_residence,
          reason_of_deregistration: res.data.reason_of_deregistration,
          status_of_call: res.data.statuses_of_call,
        })
      })
      .catch(() => {})
  }, [])

  // set filter button is active/inactive
  useEffect(() => {
    setIsSomeFilterActive(checkIsThereSomeValueInObject(filters))
  }, [filters])

  return (
    <div style={{ height: '90vh' }}>
      {error && <Notification isOpen={isError} handleClose={handleCloseError} message={error} type='error' />}
      <Header handleQueryChange={handleSearchChange} query={search} />
      <Buttons
        isSomeFilterActive={isSomeFilterActive}
        isSortingActive={sorting}
        isSearchingActive={search}
        onResetFilters={() => {
          resetFilters()
          setPage(0)
          apiRef.current.setFilterModel({ ...gridFilterModelSelector(apiRef), items: [] })
        }}
        onResetSorting={() => {
          setSorting('')
          setPage(0)
          apiRef.current.setSortModel([])
        }}
        onResetSearching={() => setSearch('')}
      />
      <PregnantTable
        loading={loading}
        rows={rows}
        columns={columns}
        table_title={'Список беременных'}
        handlePageChange={handlePageChange}
        managePregnantsToRemove={managePregnantsToRemove}
        handleFilterChange={handleFilterChange}
        handleSortChange={handleSortChange}
        paginationModel={{
          page,
          pageSize,
        }}
        totalItems={totalItems}
        fields={fields}
        resetFilters={resetFilters}
        apiRef={apiRef}
      />
      <Grid container>
        <Grid xs={4} md={4} lg={4} item>
          <Box className='select-box'>
            <Typography className='select-box__text'>Сняты с учёта</Typography>
            <Switch
              checked={!isFlagRegisteredSelected}
              onChange={() => handleFlagRegisteredSelectedChange()}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography
              className={isFlagRegisteredSelected ? 'select-box__text select-box__text_active' : 'select-box__text'}
              onClick={() => setIsFlagRegisteredSelected(true)}
            >
              На учёте
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4} md={4} lg={4} item>
          <Box style={{ height: '3.7vh' }} className='select-box'>
            <Typography>Не был совершен звонок</Typography>
            <Checkbox
              size='large'
              checked={has_at_least_one_call_search === 'False'}
              onChange={() => handleHasAtLeastOneCallSelectedChange()}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Grid>
        {canDelete && (
          <Grid xs={4} md={4} lg={4} item>
            <Button
              onClick={() => {
                removePregnantsByIndexes()
              }}
              style={{ textTransform: 'none', fontWeight: 'bold' }}
            >
              Удалить
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default PregnantsListView
