import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { React } from 'react'

export default function PregnantMultiselectValuesPlaceholder (props) {
  return (
    <Grid mx='auto' sx={{ maxWidth: 260 }} mt={2} container>
      {props.chosen?.map((obj, index) => {
        return (
          <Grid
            textAlign='center'
            key={index}
            className={index % 2 === 0 ? 'odd-color' : ''}
            item
            xs={12}
            md={12}
            lg={12}
          >
            <Typography>#{obj.name}</Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}
