import axios from 'axios'

export default async function postHealthChecklistComplication (data) {
  const request = await axios({
    method: 'post',
    url: 'health_checklist/complication',
    data
  })
  return request
}
