import axios from 'axios'

const AXIOS_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_AXIOS_BASE_URL
    : process.env.REACT_APP_PROD_AXIOS_BASE_URL

const axiosToken = axios.create({
  baseURL: `${AXIOS_URL}/auth`
})

export async function apiToken (login, password) {
  const credentials = {
    username: login,
    password
  }
  const request = await axiosToken.post('token/', credentials)
  return request.data
}
