export default function checklistWeekRows () {
  const termsOfPregnancy = ['12-16', '16-20', '24-25', '30-32', '36', '41']
  const blankData = {
    id: undefined,
    term_of_pregnancy: '',
    date_of_filling: '-',
    has_complaint: '-',
    has_danger_sign: '-',
    doctor_full_name: '-'
  }
  const blankDataWithTermsOfPregnancy = []
  for (let i = 0; i < termsOfPregnancy.length; i++) {
    blankDataWithTermsOfPregnancy.push(Object.assign({}, blankData, { term_of_pregnancy: termsOfPregnancy[i] }))
  }
  console.log('blank_data_with_terms_of_pregnancy', blankDataWithTermsOfPregnancy)
  return blankDataWithTermsOfPregnancy
}
