import { React, Component, createRef } from 'react'
import PregnantModalWindow from './pregnant_modal_window/pregnant_modal_window'
import PregnantCard from './components/card/card'
import Complications from './components/complications/complications'
import Violations from './components/violations/violations'
import CallsHistory from './components/calls_history/calls_history'
import HealthChecklist from './components/health_checklist/health_checklist'
import ControlChecklist from './components/control_checklist/control_checklist'
import {
  getPregnancyIdPersonalData,
  postCallHistoryCreateCallHistory,
  getMisValidationOfPersonalData,
  getPrivateDataPregnantId,
} from 'api/'
import Header from './components/header/header'
import personalDataObject from './data-structure/personal-data-object'
import './pregnant_card.css'
import Notification from './notification/notification'
import Widget from './components/widget/widget'
import brightpatternConfig from 'brightpattern-config'
import brightpatternMessages from 'brightpattern-messages'
import { parseData, filledPhonesSelect } from './utils'

export default class PregnantCardView extends Component {
  constructor(props) {
    super(props)
    this.setCurrentModal = this.setCurrentModal.bind(this)
    this.violations = createRef()
    this.complications = createRef()
    this.pregnant_card = createRef()
    this.health_checklist = createRef()
    this.call_history = createRef()
    this.state = {
      personal_data: personalDataObject,
      pregnant_id: window.location.href.split('/')[4],
      phones: [],
      target_phone: '',
      complications: [],
      health_checklist: [],
      quality_checklist: [],
      call_ending_handler_inited: false,
      violations: [],
      current_modal_window: {
        name: null,
        data: {},
      },
      global_id: '',
      call_button_disabled: true,
      loading: true,
      max_retries: 5,
      notification: {
        message: '',
        type: '',
        open: false,
        timeout: null,
      },
      pregnant: false,
      window,
      page_loaded: false,
      frame: '',
      validation_of_personal_data: {},
      validation_of_personal_data_messages: [],
      validation_of_personal_data_messages_string: '',
    }
  }

  misPregnantId = (pregnantId) => {
    getPrivateDataPregnantId(pregnantId)
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              pregnant: res.data.pregnant,
              info_about_pregnant_at_current_pregnancy: res.data.info_about_pregnant_at_current_pregnancy,
              pmsp_area: res.data.pmsp_area,
              pregnant_loading: false,
            },
            () => {
              this.setState({
                phones: filledPhonesSelect(res.data),
              })
            }
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getMisValidationOfPersonalDataWrap = () => {
    getMisValidationOfPersonalData(this.state.pregnant_id)
      .then((res) => {
        if (res.data) {
          this.setState({ validation_of_personal_data: res.data }, () => {
            this.handleValidationOfPersonalDataUpdate()
          })
        }
      })
      .catch((err) => {
        if (err?.response?.data) {
          this.notify('error', JSON.stringify(err?.response?.data))
        }
      })
  }

  setIframeActive = () => {
    if (!this.state.page_loaded) {
      this.setState({ page_loaded: true })
    }
  }

  createCallHistory = (data) => {
    postCallHistoryCreateCallHistory(parseData(data))
      .then((res) => {
        if (res.data) {
          this.notify('success', 'Запись о звонке успешно добавлена')
          this.updateCardBlockComponent('call_history')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  initBrightPatternInterface = () => {
    const url = process.env.NODE_ENV === 'development' ? brightpatternConfig.prod_url : brightpatternConfig.url
    window.bpspat.api.init(url)
  }

  initBrightPatternCall = (phone) => {
    if (!phone) {
      this.notify('error', 'Сначала нужно выбрать номер!')
      return 0
    }
    const service =
      process.env.NODE_ENV === 'development' ? brightpatternConfig.prod_service : brightpatternConfig.service
    window.bpspat.api.selectService(service)
    window.bpspat.api.dialNumber(phone.replace('+7', '7'))
  }

  updateCardBlockComponent = (name) => {
    if (name === 'complications') {
      this.complications.current.pregnancyComplicationsWithParams()
    } else if (name === 'pregnant_card') {
      this.pregnant_card.current.misPregnantIdWithParams()
      this.pregnant_card.current.pregnancyPersonalDataWithParams()
    } else if (name === 'health_checklist') {
      this.health_checklist.current.pregnancyHealthChecklistWithParams()
    } else if (name === 'call_history') {
      this.call_history.current.pregnancyIdCallHistory()
    } else if (name === 'violations') {
      this.violations.current.pregnancyViolationsWithParams()
    }
  }

  misPregnantIdWithParams = () => {
    this.misPregnantId(this.state.pregnant_id)
  }

  setCurrentModal = (target, data) => {
    this.setState({
      current_modal_window: {
        ...this.state.current_modal_window,
        name: target,
        data,
      },
    })
  }

  notify = (type, message, close) => {
    if (close) {
      this.setState(
        {
          notification: {
            ...this.state.notification,
            open: false,
          },
        },
        () => {
          clearTimeout(this.state.notification.timeout)
        }
      )
    } else {
      this.setState({
        notification: {
          ...this.state.notification,
          type,
          message,
          open: true,
          timeout: setTimeout(() => {
            this.setState({ notification: { ...this.state.notification, open: true } })
          }, 3500),
        },
      })
    }
  }

  pregnancyPersonalData = (pregnantId) => {
    getPregnancyIdPersonalData(pregnantId)
      .then((res) => {
        if (res.data && JSON.stringify(res.data) !== '{}') {
          this.setState({
            personal_data: res.data,
            loading: false,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  setCallData = (data) => {
    localStorage.setItem('global_id', data.data.global_id)
    data.data.date_of_call = new Date().toLocaleDateString('en-CA') + 'T' + new Date().toLocaleTimeString('it-IT')
    data.data.doctor_username = localStorage.getItem('username')
    data.data.pregnancy_id = this.state.pregnant_id
    data.data.phone = this.state.target_phone
    this.createCallHistory(data.data)
  }

  filledPhonesSelect = () => {
    if (this.state.pregnant) {
      const phones = []
      const pregnantPhones = this.state.pregnant.phone || []
      const fatherPhones = this.state.info_about_pregnant_at_current_pregnancy.father_info?.phone || []
      const therapistPhones = this.state.pmsp_area.therapist?.phone || []
      const healthVisitorPhones = this.state.pmsp_area?.health_visitor?.phone || []

      for (let i = 0; i < pregnantPhones.length; i++) {
        pregnantPhones[i].target = 'БЖ'
        pregnantPhones[i].fio = this.state.pregnant.full_name
        phones.push(pregnantPhones[i])
      }

      for (let i = 0; i < fatherPhones.length; i++) {
        if (fatherPhones[i].is_main) {
          fatherPhones[i].target = 'Отец'
          fatherPhones[i].fio = this.state.info_about_pregnant_at_current_pregnancy.father_info.full_name || ''
          phones.push(fatherPhones[i])
        }
      }

      for (let i = 0; i < therapistPhones.length; i++) {
        therapistPhones[i].target = 'Терапевт'
        therapistPhones[i].fio = this.state.pmsp_area.therapist.full_name || ''
        phones.push(therapistPhones[i])
      }

      for (let i = 0; i < healthVisitorPhones.length; i++) {
        healthVisitorPhones[i].target = 'Медсестра/Медбрат'
        healthVisitorPhones[i].fio = this.state.pmsp_area.health_visitor.full_name || ''
        phones.push(healthVisitorPhones[i])
      }

      return this.setState({ phones })
    }
  }

  setCallButtonState = (state) => {
    if (this.state.call_button_disabled !== state) {
      this.setState({ call_button_disabled: state })
    }
  }

  testMessageEventHandler = (event) => {
    console.log(event)
  }

  BrightPatternMessageEventHandler = (event) => {
    const { authenticatedMessaged, isNotAuthenticatedMessages, isBusyMessages } = brightpatternMessages
    const url = process.env.NODE_ENV === 'development' ? brightpatternConfig.prod_url : brightpatternConfig.url
    if (event.origin === url) {
      console.log(event)
      let command = ''
      try {
        command = JSON.parse(event.data).command
      } catch {
        return 0
      }
      if (isNotAuthenticatedMessages.includes(command)) {
        this.setCallButtonState(true)
      }
      if (authenticatedMessaged.includes(command)) {
        this.setCallButtonState(false)
      }
      if (isBusyMessages.includes(command)) {
        this.setCallButtonState(true)
      }
      if (command === 'CALL_ENDED' || command === '_CALL_ENDED') {
        this.setCallButtonState(false)
        const data = JSON.parse(event.data)
        this.setCallData(data)
      }
      if (command === 'INTERACTION_COMPLETED') {
        const data = JSON.parse(event.data)
        if (localStorage.getItem('global_id') !== data.global_id) {
          localStorage.setItem('global_id', data.global_id)
          this.setCallData(data)
        }
        this.setCallButtonState(false)
      }
      if (command === 'HIDE_STATUS') {
        if (JSON.parse(event.data).data === 'Загрузка ...') {
          this.setCallButtonState(false)
        }
      }
    }
  }

  BrightPatternSendDialNumberMessage = (phone) => {
    const target = document.getElementById('repeater')
    const message = { command: 'DIAL_NUMBER', number: phone }
    target.contentWindow.postMessage(JSON.stringify(message))
  }

  BrightPatternSendSelectServiceMessage = (serviceName) => {
    const target = document.getElementById('repeater')
    const message = { command: 'SELECT_SERVICE', data: serviceName }
    target.contentWindow.postMessage(JSON.stringify(message))
  }

  chosePhone = (id, val) => {
    if (this.state.target_phone !== val) {
      this.setState({ target_phone: val })
    }
  }

  handleValidationOfPersonalDataUpdate = () => {
    const currentValidationOfPersonalDataMessages = []
    const validationFields = Object.keys(this.state.validation_of_personal_data)
    const validationOfPersonalData = this.state.validation_of_personal_data
    for (let i = 0; i < validationFields.length; i++) {
      if (!validationOfPersonalData[validationFields[i]].includes('валиден')) {
        currentValidationOfPersonalDataMessages.push(validationOfPersonalData[validationFields[i]])
      }
    }
    this.setState({ validation_of_personal_data_messages: currentValidationOfPersonalDataMessages })
    let currentValidationOfPersonalDataMessagesString = ''
    for (let i = 0; i < currentValidationOfPersonalDataMessages.length; i++) {
      currentValidationOfPersonalDataMessagesString += currentValidationOfPersonalDataMessages[i] + '\n'
    }
    if (currentValidationOfPersonalDataMessagesString) {
      this.setState({
        validation_of_personal_data_messages_string: currentValidationOfPersonalDataMessagesString,
      })
      this.notify('error', currentValidationOfPersonalDataMessagesString)
    }
  }

  updateCardBlockInfoAfterCardFormSave = () => {
    this.pregnant_card.current.misPregnantIdWithParams()
    this.pregnant_card.current.pregnancyPersonalDataWithParams()
    this.pregnancyPersonalData(this.state.pregnant_id)
    this.getMisValidationOfPersonalDataWrap()
  }

  componentDidMount() {
    this.misPregnantIdWithParams()
    this.getMisValidationOfPersonalDataWrap()
    this.pregnancyPersonalData(this.state.pregnant_id)
    this.filledPhonesSelect()
    window.addEventListener('message', this.testMessageEventHandler)
    window.addEventListener('message', this.BrightPatternMessageEventHandler)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.state.validation_of_personal_data).length !==
      Object.keys(prevState.validation_of_personal_data).length
    ) {
      this.handleValidationOfPersonalDataUpdate()
    }
    if (prevProps.current_modal_window !== this.props.current_modal_window) {
      this.setState({ current_modal_window: this.props.current_modal_window })
    }
  }

  componentWillUnmount() {
    window.addEventListener('message', this.testMessageEventHandler)
    window.removeEventListener('message', this.BrightPatternMessageEventHandler)
  }

  render() {
    return (
      <div>
        <Notification
          message={this.state.notification.message}
          open={this.state.notification.open}
          type={this.state.notification.type}
          onClose={this.notify}
        />
        <Header
          phones={this.state.phones}
          notify={this.notify}
          call_button_disabled={this.state.call_button_disabled}
          initBrightPatternCall={this.initBrightPatternCall}
          chosePhone={this.chosePhone}
          personal_data={this.state.personal_data}
        />
        <div className='page_row'>
          <PregnantCard
            ref={this.pregnant_card}
            current_week_of_pregnancy={this.state.personal_data.current_week_of_pregnancy}
            pregnant_id={this.state.pregnant_id}
            personal_data={this.state.personal_data}
            setCurrentModal={this.setCurrentModal}
          />
          <Complications
            ref={this.complications}
            current_week_of_pregnancy={this.state.personal_data.current_week_of_pregnancy}
            pregnant_id={this.state.pregnant_id}
            complications={this.state.complications}
            validation_of_personal_data_messages_string={this.state.validation_of_personal_data_messages_string}
            setCurrentModal={this.setCurrentModal}
            notify={this.notify}
          />
          <HealthChecklist
            ref={this.health_checklist}
            current_week_of_pregnancy={this.state.personal_data.current_week_of_pregnancy}
            pregnant_id={this.state.pregnant_id}
            health_checklist={this.state.health_checklist}
            validation_of_personal_data_messages_string={this.state.validation_of_personal_data_messages_string}
            setCurrentModal={this.setCurrentModal}
            notify={this.notify}
          />
        </div>
        <div className='page_row'>
          <CallsHistory
            ref={this.call_history}
            current_week_of_pregnancy={this.state.personal_data.current_week_of_pregnancy}
            pregnant_id={this.state.pregnant_id}
            calls_history={this.state.calls_history}
            validation_of_personal_data_messages_string={this.state.validation_of_personal_data_messages_string}
            setCurrentModal={this.setCurrentModal}
            notify={this.notify}
          />
          <Violations
            ref={this.violations}
            current_week_of_pregnancy={this.state.personal_data.current_week_of_pregnancy}
            pregnant_id={this.state.pregnant_id}
            violations={this.state.violations}
            validation_of_personal_data_messages_string={this.state.validation_of_personal_data_messages_string}
            setCurrentModal={this.setCurrentModal}
            notify={this.notify}
          />
          <ControlChecklist
            current_week_of_pregnancy={this.state.personal_data.current_week_of_pregnancy}
            pregnant_id={this.state.pregnant_id}
            quality_checklist={this.state.quality_checklist}
            setCurrentModal={this.setCurrentModal}
          />
        </div>
        <PregnantModalWindow
          misPregnantIdWithParams={this.misPregnantIdWithParams}
          filledPhonesSelect={this.filledPhonesSelect}
          updateCardBlockComponent={this.updateCardBlockComponent}
          updateCardBlockInfoAfterCardFormSave={this.updateCardBlockInfoAfterCardFormSave}
          initBrightPatternCall={this.initBrightPatternCall}
          notify={this.notify}
          current_week_of_pregnancy={this.state.personal_data.current_week_of_pregnancy}
          pregnant_id={this.state.pregnant_id}
          getMisValidationOfPersonalDataWrap={this.getMisValidationOfPersonalDataWrap}
          setCurrentModal={this.setCurrentModal}
          current_modal_window={this.state.current_modal_window}
        />
        <Widget
          notify={this.notify}
          setIframeActive={this.setIframeActive}
          loaded={this.state.page_loaded}
          initBrightPatternInterface={this.initBrightPatternInterface}
        />
      </div>
    )
  }
}
