import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { React, useEffect, useState } from 'react'

export default function PregnantMultiselectValuesPlaceholder (props) {
  const [chosen, setChosen] = useState([])
  useEffect(() => {
    if (chosen !== props.chosen) {
      setChosen(props.chosen)
    }
  })
  return (
    <Grid mx='auto' sx={{ maxWidth: 260 }} mt={2} container>
      {![undefined, [], null].includes(chosen) &&
        chosen.map((obj, index) => {
          return (
            <Grid
              textAlign='center'
              key={index}
              className={index % 2 === 0 ? 'odd-color' : ''}
              item
              xs={12}
              md={12}
              lg={12}
            >
              <Typography>#{obj}</Typography>
            </Grid>
          )
        })}
    </Grid>
  )
}
