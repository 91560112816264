export const getSortValue = (data) => {
  const field = data && data[0]?.field
  const sort = data && data[0]?.sort
  switch (field) {
    case 'id':
      return sort === 'asc' ? '-id' : 'id'
    case 'full_name':
      return sort === 'asc' ? '-full_name' : 'full_name'
    case 'date_of_registration_by_tmc':
      return sort === 'asc' ? '-date_of_registration_by_tmc' : 'date_of_registration_by_tmc'
    case 'current_week_of_pregnancy':
      return sort === 'asc' ? '-current_week_of_pregnancy' : 'current_week_of_pregnancy'
    case 'age':
      return sort === 'asc' ? '-age' : 'age'
    case 'pmsp':
      return sort === 'asc' ? '-pmsp' : 'pmsp'
    case 'pmsp_area':
      return sort === 'asc' ? '-pmsp_area' : 'pmsp_area'
    case 'area_of_residence':
      return sort === 'asc' ? '-area_of_residence' : 'area_of_residence'
    default:
      return ''
  }
}
