import { Box } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import moment from 'moment/moment'
import { getFormattedDate } from '../utils'
import { React, useState } from 'react'

export default function DateInputRange (props) {
  const { item, applyValue } = props
  const [filterValueState, setFilterValueState] = useState(item.value ?? [null, null])

  const updateFilterValue = (lowerBound, upperBound) => {
    setFilterValueState([lowerBound, upperBound])
    applyValue({ ...item, value: [lowerBound, upperBound] })
  }

  const handleLowerFilterChange = (event) => {
    const value = getFormattedDate(event)
    updateFilterValue(value, filterValueState[1])
    applyValue({ ...item, value })
    props.handleFilterChange(props.getDateLowerFilterName(item?.field), value)
  }

  const handleUpperFilterChange = (event) => {
    const value = getFormattedDate(event)
    updateFilterValue(filterValueState[0], value)
    applyValue({ ...item, value })
    props.handleFilterChange(props.getDateUpperFilterName(item?.field), value)
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px'
      }}
    >
      <LocalizationProvider id={`date-localization-${item?.field}`} adapterLocale='ru' dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ mr: 2 }}
          id={`date-from-${item?.field}`}
          onChange={handleLowerFilterChange}
          slotProps={{
            textField: {
              size: 'small',
              placeholder: 'От',
              label: 'От',
              name: 'date-input',
              error: false
            }
          }}
          value={dayjs(moment(filterValueState[0], 'YYYY-MM-DD'))}
        />
        <DatePicker
          id={'date-to'}
          onChange={handleUpperFilterChange}
          slotProps={{
            textField: { size: 'small', placeholder: 'До', label: 'До', name: 'date-input', error: false }
          }}
          value={dayjs(moment(filterValueState[1], 'YYYY-MM-DD'))}
        />
      </LocalizationProvider>
    </Box>
  )
}
