import { Box, Select, Typography, MenuItem, Button, CircularProgress } from '@mui/material'
import { getCallHistoryReport } from 'api/'
import './reports.css'
import { React, useState } from 'react'
import Notification from 'components/notification/notification'

const reportTypes = [
  {
    title: '№1. История звонков',
    value: 1
  }
]

export default function ReportsView (props) {
  const [reportResponse, setReportResponse] = useState({
    isLoading: false,
    isError: false,
    error: '',
    data: ''
  })

  const handleLoadButtonClick = () => {
    setReportResponse((prevState) => ({ ...prevState, isLoading: true }))
    getCallHistoryReport()
      .then((res) => {
        const blob = new Blob([res.data], { type: res.headers['content-type'] })
        const url = URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = `report_${new Date().getTime()}.xlsx`
        document.body.append(anchor)
        anchor.style = 'dispaly: none'
        anchor.click()
        anchor.remove()
        URL.revokeObjectURL(url)
        setReportResponse((prevState) => ({ ...prevState, data: 'Отчёт успешно выгружен', isError: false, error: '' }))
      })
      .catch((err) => {
        console.log(err)
        setReportResponse((prevState) => ({
          ...prevState,
          data: '',
          isError: true,
          error: 'При выгрузке отчёта произошла ошибка'
        }))
      })
      .finally(() => {
        setReportResponse((prevState) => ({ ...prevState, isLoading: false }))
      })
  }

  const handleCloseError = () => {
    setReportResponse((prevState) => ({ ...prevState, isError: false, error: '', data: '' }))
  }

  return (
    <Box className='container'>
      {reportResponse?.isError && (
        <Notification
          isOpen={reportResponse?.isError}
          handleClose={handleCloseError}
          message={reportResponse?.error}
          type='error'
        />
      )}
      {reportResponse?.data && (
        <Notification
          isOpen={reportResponse?.data}
          handleClose={handleCloseError}
          message={reportResponse?.data}
          type='success'
        />
      )}
      <Box className='report'>
        <Typography className='report__title' variant='h4'>
          Формирование отчётности
        </Typography>

        {/* TODO: Uncomment when it becomes possible to request other reports with time ranges */}

        {/* <Box className='dates report__dates'>
          <Box className='date'>
            <Typography className='date__label'>с</Typography>
            <DateTimepicker className='date__input' />
          </Box>
          <Box className='date'>
            <Typography className='date__label'>по</Typography>
            <DateTimepicker className='date__input' />
          </Box>
        </Box>
        <Box className='periods report__periods'>
          <Typography className='periods__item'>за сегодня</Typography>
          <Typography className='periods__item'>за месяц</Typography>
          <Typography className='periods__item'>за год</Typography>
          <Typography className='periods__item'>за всё время</Typography>
        </Box> */}

        {/* TODO: Uncomment when it becomes possible to request other reports with time ranges */}

        <Select id='demo-simple-select' defaultValue={reportTypes[0]?.value} className='report__select'>
          {reportTypes.map((item) => {
            return (
              <MenuItem value={item.value} key={item.value}>
                {item.title}
              </MenuItem>
            )
          })}
        </Select>
        <Button
          className='report__button'
          sx={{ mt: '100px' }}
          disabled={reportResponse?.isLoading}
          variant='contained'
          onClick={handleLoadButtonClick}
        >
          {reportResponse?.isLoading ? <CircularProgress color='inherit' size={25} /> : 'Выгрузить'}
        </Button>
      </Box>
    </Box>
  )
}
