export default function columnsProperties () {
//   const colors = {
//     Мониторинг: 'yellow',
//     'Прием к врачу': 'orange',
//     'Вызов врача на дом': 'orange',
//     'Плановая госпитализация': 'red',
//     'Экстренная госпитализация': 'red',
//   }
  return [
    { field: 'full_name', headerName: 'ФИО', width: 350 },
    { field: 'current_week_of_pregnancy', headerName: 'Текущая неделя беременности', width: 250 },
    { field: 'diagnosis', headerName: 'Диагноз', width: 200 },
    { field: 'diagnosis_status', headerName: 'Статус диагноза', width: 150 },
    { field: 'date_of_filling_checklist', headerName: 'Дата заполнения чек-листа', width: 150 },
    { field: 'date_of_doctors_appointment_or_hospitalization', headerName: 'Дата записи/госпитализации', width: 150 },
    { field: 'last_interaction_result', headerName: 'Результат', width: 150 },
    { field: 'date_of_last_interaction', headerName: 'Дата взаимодействия', width: 150 },
    // {
    //     field: "record_type",
    //     headerName: 'Тип записи',
    //     width: 150,
    //     renderCell: (params) => {
    //     if (!params.value){
    //         return ""
    //     }
    //     return (
    //         <Button
    //         style={{
    //             cursor: "auto",
    //             textTransform: "none",
    //             backgroundColor: colors[params.value] }}
    //         disableRipple
    //         disableElevation
    //         disableFocusRipple
    //         variant="contained"
    //         >{params.value}</Button>
    //     )}
    // },
    { field: 'record_type', headerName: 'Тип записи', width: 150 }
  ]
}
