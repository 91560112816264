import { React, Component } from 'react'
import getHealthChecklistFields from './api/get-health-checklist-fields'
import { getPrivateDataPregnantId } from 'api/private-data'
import { getPregnancyIdPersonalData } from 'api/pregnant-card'
import postHealthChecklist from './api/post-health-checklist'
import postHealthChecklistComplication from './api/post-health-checklist-complication'
import parseDataForPostHealthChecklistComplication from './utils/parse-data-for-post-health-checklist-complication'
import getHealthChecklistComplicationFields from './api/get-health-checklist-complication-fields'
import '../popup_components.css'
import { PregnantMultiSelect, PregnantSelect } from 'elements/'
import serializeDataForMultiselectState from './utils/serialize-data-for-multiselect-state'
import { Grid, TextField, Typography, Button, Container } from '@mui/material'
import healthChecklistComplicationValues from './data-structure/health-checklist-complication-values'
import checklistOfHealthComplicationObject from './data-structure/checklist-of-health-complication-object'
import checklistOfHealthObject from './data-structure/checklist-of-health-object'
import checklistOfHealthSelectValues from './data-structure/checklist-of-health-select-values'
import pregnancyObject from './data-structure/pregnancy-object'
import healthChecklistFields from './data-structure/health-checklist-fields'
import newChecklistOfHealthValidationObject from './data-structure/new-checklist-of-health-validation-object'
import setWeightVal from './state-logic/set-weight-val'
import parseDataForPostHealthChecklist from './utils/parse-data-for-post-health-checklist'
import styled from '@emotion/styled'
import healthChecklistComplicationFields from './data-structure/health-checklist-complication-fields'
import { PropTypes } from 'prop-types'

const TextValue = styled(Typography)({
  fontSize: '18px',
  width: '100%',
  display: 'block',
  height: '40px'
})

const TextLabel = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '18px'
})

export default class NewHealthChecklist extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pregnant_id: this.props.pregnant_id,
      doctor_username: localStorage.getItem('username'),
      current_week_of_pregnancy: 0,
      health_checklist_fields: healthChecklistFields,
      ChecklistOfHealth: checklistOfHealthObject,
      PregnancyComplication: checklistOfHealthComplicationObject,
      checklist_of_health_select_values: checklistOfHealthSelectValues,
      Pregnancy: pregnancyObject,
      data: this.props.data,
      health_checklist_complication_fields: healthChecklistComplicationFields,
      health_checklist_complication_values: healthChecklistComplicationValues,
      ChecklistOfHealthComplication: checklistOfHealthComplicationObject,
      checklist_id: this.props.data.id,
      pregnant_loading: true,
      health_checklist_fields_loading: true,
      personal_data_loading: true,
      health_checklist_complication_fields_loading: true,
      health_checklist_loading: true,
      pregnancy_id: parseInt(this.props.pregnant_id),
      is_save_button_disabled: false,
      save_button_text: 'Сохранить',

      validation: newChecklistOfHealthValidationObject,

      max_retries: 5
    }
  }

  healthChecklistFields = () => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getHealthChecklistFields()
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  health_checklist_fields: res.data,
                  health_checklist_fields_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  misPregnant = (pregnantId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getPrivateDataPregnantId(pregnantId)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  Pregnancy: res.data.Pregnancy,
                  pregnant_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  pregnancyPersonalData = (pregnantId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getPregnancyIdPersonalData(pregnantId)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  current_week_of_pregnancy: res.data.current_week_of_pregnancy,
                  personal_data_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  checkIsValid = (data) => {
    const validationKeys = Object.keys(data)
    const notValid = []
    for (let i = 0; i < validationKeys.length; i++) {
      if (data[validationKeys[i]] !== '') {
        notValid.push(data[validationKeys[i]])
      }
    }
    return [!notValid.length, notValid]
  }

  healthChecklistComplicationFields = () => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getHealthChecklistComplicationFields()
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  health_checklist_complication_fields: res.data,
                  health_checklist_complication_fields_loading: false
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  postHealthChecklistWrap = (data) => {
    const [isValid, notValidData] = this.checkIsValid(data.validation)
    if (!isValid) {
      let errorText = ''
      for (let i = 0; i < notValidData.length; i++) {
        errorText += notValidData[i] + '\n'
      }
      this.props.notify('error', errorText)
      return 0
    }
    const parsedData = parseDataForPostHealthChecklist(data)
    postHealthChecklist(parsedData)
      .then((res) => {
        this.props.updateCardBlockComponent('health_checklist')
        this.props.notify('success', 'Успешно сохранено')
        if (!parsedData.complaint && !parsedData.danger_sign) {
          this.props.setCurrentModal(null, {})
        }
        if (parsedData.complaint || parsedData.danger_sign) {
          const parsedDataComplication = parseDataForPostHealthChecklistComplication(data)
          console.log('postHealthChecklistComplication', parsedDataComplication)
          postHealthChecklistComplication(parsedDataComplication)
            .then((res) => {
              this.props.notify('success', 'Успешно сохранено')
              this.props.updateCardBlockComponent('complications')
              this.props.setCurrentModal(null, {})
            })
            .catch((err) => {
              console.log(err)
              this.props.notify('error', 'Ошибка при сохранении данных')
            })
          console.log(res)
        }
      })
      .catch((err) => {
        console.log(err)
        console.log(err.response)
        this.props.notify('error', `Возникли ошибки ${JSON.stringify(err.response.data)}`)
      })
  }

  addComplicationValidation = () => {
    this.setState({
      validation: {
        ...this.state.validation,
        diagnosis: 'Выберите диагноз',
        diagnosis_status: 'Выберите статус диагноза',
        record_type: 'Выберите тип записи'
      }
    })
  }

  removeComplicationValidation = () => {
    this.setState({
      validation: {
        ...this.state.validation,
        diagnosis: '',
        diagnosis_status: '',
        record_type: ''
      }
    })
  }

  componentDidMount () {
    this.healthChecklistFields()
    this.healthChecklistComplicationFields()
    this.misPregnant(this.props.pregnant_id)
    this.pregnancyPersonalData(this.props.pregnant_id)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.PregnancyComplication.complaint.length !== this.state.PregnancyComplication.complaint.length) {
      if (this.state.PregnancyComplication.complaint.length > 0) {
        this.addComplicationValidation()
      } else {
        this.removeComplicationValidation()
      }
    }
    if (prevState.PregnancyComplication.danger_sign.length !== this.state.PregnancyComplication.danger_sign.length) {
      if (this.state.PregnancyComplication.danger_sign.length > 0) {
        this.addComplicationValidation()
      } else {
        this.removeComplicationValidation()
      }
    }
  }

  setSelectVal = (id, val) => {
    this.setState({
      PregnancyComplication: {
        ...this.state.PregnancyComplication,
        [id]: val
      }
    })
  }

  setAnamnesisVal = (id, val) => {
    this.setState({
      PregnancyComplication: {
        ...this.state.PregnancyComplication,
        [id]: val
      }
    })
  }

  setSelectValComplications = (id, val) => {
    this.setState({
      health_checklist_complication_values: {
        ...this.state.health_checklist_complication_values,
        [id]: val
      },
      validation: { ...this.state.validation, [id]: '' },
      PregnancyComplication: {
        ...this.state.PregnancyComplication,
        [id]: { name: val }
      },
      ChecklistOfHealth: {
        ...this.state.ChecklistOfHealth,
        [id]: { name: val }
      }
    })
  }

  setMultiSelectVal = (id, val) => {
    this.setState({
      ChecklistOfHealth: {
        ...this.state.ChecklistOfHealth,
        [id]: serializeDataForMultiselectState(val)
      },
      checklist_of_health_select_values: {
        ...this.state.checklist_of_health_select_values,
        [id]: val
      },
      PregnancyComplication: {
        ...this.state.PregnancyComplication,
        [id]: serializeDataForMultiselectState(val)
      }
    })
  }

  render () {
    return (
      <Container>
        <div style={{ maxHeight: '75vh', overflowY: 'auto', padding: '1%' }}>
          <div className='popup_card-block' style={{ minHeight: '20vh' }}>
            <Grid gap={17} style={{ justifyContent: 'flex-start' }} container>
              <Grid lg={2} md={2} sm={2} item>
                <p className='row_input_title'>Жалобы</p>
                <PregnantMultiSelect
                  choices={this.state.health_checklist_fields.Complaint}
                  id='complaint'
                  size='small'
                  multiple={true}
                  type='text'
                  variant='outlined'
                  value={this.state.checklist_of_health_select_values.complaint}
                  onChange={this.setMultiSelectVal}
                />
              </Grid>
              <Grid lg={2} md={2} sm={2} item>
                <p className='row_input_title'>Опасные признаки</p>
                <PregnantMultiSelect
                  id='danger_sign'
                  size='small'
                  multiple={true}
                  type='text'
                  variant='outlined'
                  value={this.state.checklist_of_health_select_values.danger_sign}
                  onChange={this.setMultiSelectVal}
                  choices={this.state.health_checklist_fields.DangerSign}
                />
              </Grid>
              <Grid lg={2} md={2} sm={2} item>
                <p className='row_input_title'>Прием препаратов</p>
                <PregnantMultiSelect
                  id='taking_drugs'
                  size='small'
                  multiple={true}
                  type='text'
                  variant='outlined'
                  value={this.state.checklist_of_health_select_values.taking_drugs}
                  onChange={this.setMultiSelectVal}
                  choices={this.state.health_checklist_fields.Drugs}
                />
              </Grid>
              <Grid lg={1} md={1} sm={1} item>
                <p className='row_input_title'>Вес</p>
                <TextField
                  error={Boolean(this.state.validation.weight_helper_text)}
                  value={this.state.ChecklistOfHealth.weight}
                  onChange={(e) => setWeightVal(this, e.target.id, e.target.value)}
                  id='weight'
                  size='small'
                  helperText={this.state.validation.weight_helper_text}
                  type='number'
                ></TextField>
              </Grid>
            </Grid>
            <Grid mt={4} gap={1} container>
              <Grid item>
                <TextLabel>Врач ТМЦ:</TextLabel>
              </Grid>
              <Grid item>
                <TextValue>{this.state.pmsp_area?.therapist.full_name}</TextValue>
              </Grid>
            </Grid>
            <Grid gap={1} container>
              <Grid item>
                <TextLabel>Дата заполнения чек-листа:</TextLabel>
              </Grid>
              <Grid item>
                <TextValue>{new Date().toLocaleString('ru-Ru').split(', ')[0]}</TextValue>
              </Grid>
            </Grid>
            <Grid gap={1} container>
              <Grid item>
                <TextLabel>Неделя на дату заполнения:</TextLabel>
              </Grid>
              <Grid item>
                <TextValue>{this.state.current_week_of_pregnancy}</TextValue>
              </Grid>
            </Grid>
          </div>
          {this.state.PregnancyComplication.complaint.length > 0 ||
          this.state.PregnancyComplication.danger_sign.length > 0 ||
          this.state.PregnancyComplication.taking_drugs > 0
            ? (
            <div className='popup_card-block' style={{ marginTop: '3%' }}>
              <Grid container gap={4}>
                <Grid lg={2} md={2} xs={2} item>
                  <Typography style={{ fontWeight: 'bold' }}>Неделя беременности на дату заполнения</Typography>
                  <Typography>{this.state.current_week_of_pregnancy}</Typography>
                </Grid>
                <Grid lg={4} md={4} xs={4} item>
                  <Typography style={{ fontWeight: 'bold' }}>Диагноз</Typography>
                  <PregnantSelect
                    error={this.state.validation.diagnosis}
                    id='diagnosis'
                    onChange={this.setSelectValComplications}
                    value={this.state.health_checklist_complication_values.diagnosis}
                    choices={this.state.health_checklist_complication_fields.Diagnosis}
                  />
                </Grid>
                <Grid lg={2} md={2} xs={2} item>
                  <Typography style={{ fontWeight: 'bold' }}>Тип записи</Typography>
                  <PregnantSelect
                    error={this.state.validation.record_type}
                    id='record_type'
                    onChange={this.setSelectValComplications}
                    value={this.state.health_checklist_complication_values.record_type}
                    choices={this.state.health_checklist_complication_fields.RecordType}
                  />
                </Grid>
                <Grid lg={2} md={2} xs={2} item>
                  <Typography style={{ fontWeight: 'bold' }}>Статус диагноза</Typography>
                  <PregnantSelect
                    error={this.state.validation.diagnosis_status}
                    id='diagnosis_status'
                    onChange={this.setSelectValComplications}
                    value={this.state.health_checklist_complication_values.diagnosis_status}
                    choices={this.state.health_checklist_complication_fields.DiagnosisStatus}
                  />
                </Grid>
              </Grid>
              <Grid mt={3} container>
                <Grid lg={12} md={12} xs={12} item>
                  <Typography style={{ fontWeight: 'bold' }}>Анамнез</Typography>
                  <TextField
                    id='anamnesis'
                    onChange={(e) => this.setAnamnesisVal(e.target.id, e.target.value)}
                    value={this.state.PregnancyComplication.anamnesis}
                    multiline
                    rows={6}
                    fullWidth
                  ></TextField>
                </Grid>
              </Grid>
            </div>
              )
            : (
                <></>
              )}
        </div>
        <Grid justifyContent='flex-end' mt={5} gap={1} container>
          <Button
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              textTransform: 'none',
              width: '20%'
            }}
            variant='contained'
            disabled={this.state.is_save_button_disabled}
            color='primary'
            onClick={() => this.postHealthChecklistWrap(this.state)}
          >
            {this.state.save_button_text}
          </Button>
        </Grid>
      </Container>
    )
  }
}

NewHealthChecklist.propTypes = {
  pregnant_id: PropTypes.number,
  data: PropTypes.object,
  notify: PropTypes.func,
  updateCardBlockComponent: PropTypes.func,
  setCurrentModal: PropTypes.func
}
