const childFieldsObject = {
  pmsp: [
    {
      name: ''
    }
  ],
  risk_factors_child: [
    {
      name: ''
    }
  ],
  accompanying_illnesses_child: [
    {
      name: ''
    }
  ],
  vaccinations_in_the_hospital_child: [
    {
      name: ''
    }
  ],
  health_complaints_child: [
    {
      name: ''
    }
  ],
  complaints_and_suggestions_about_pmsp_work_child: [
    {
      name: ''
    }
  ],
  medical_procedures: [
    {
      name: ''
    }
  ],
  request_status: [
    {
      name: ''
    }
  ]
}

export default childFieldsObject
