import { Button } from '@mui/material'
import { React } from 'react'

export const columnsProperties = [
  {
    field: 'term_of_pregnancy',
    headerName: 'Неделя',
    width: 80
  },
  {
    field: 'date_of_creation',
    headerName: 'Дата',
    width: 105
  },
  {
    field: 'doctor_full_name',
    headerName: 'Врач ТМЦ',
    width: 185
  },
  {
    field: 'status',
    headerName: 'Статус чек-листа',
    width: 125,
    renderCell: (params) => {
      return params.value === true
        ? (
            <Button disableRipple disableElevation disableFocusRipple variant='contained' color='error'>
              Да
            </Button>
          )
        : params.value === false
          ? (
              <Button disableRipple disableElevation disableFocusRipple variant='contained' color='success'>
                Нет
              </Button>
            )
          : (
              params.value
            )
    }
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 60
  }
]
