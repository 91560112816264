export default function removeEmptyData (data) {
  const myKeys = Object.keys(data)
  const newData = {}
  for (let i = 0; i < myKeys.length; i++) {
    const objKey = myKeys[i]
    if (data[objKey]) {
      if (
        typeof data[objKey] === 'string' ||
        (Array.isArray(data[objKey]) && data[objKey].length) ||
        data[objKey].name ||
        (typeof data[objKey] === 'number' && data[objKey] % 1 === 0)
      ) {
        newData[objKey] = data[objKey]
      }
    }
  }
  return newData
}
