export const getSortValue = (data) => {
  const field = data && data[0]?.field
  const sort = data && data[0]?.sort
  switch (field) {
    case 'id':
      return sort === 'asc' ? '-id' : 'id'
    case 'pregnant_full_name':
      return sort === 'asc' ? '-full_name' : 'full_name'
    case 'info_about_pregnant_at_current_pregnancy':
      return sort === 'asc' ? '-date_of_childbirth' : 'date_of_childbirth'
    case 'date_of_last_call':
      return sort === 'asc' ? '-date_of_last_call' : 'date_of_last_call'
    default:
      return ''
  }
}
