import { createTheme, ThemeProvider } from '@mui/material/styles'
import { LinearProgress } from '@mui/material'
import { DataGrid, ruRU } from '@mui/x-data-grid'
import { React, useEffect, useState } from 'react'
import './table.css'

export default function PregnantTable (props) {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const theme = createTheme(ruRU)

  useEffect(() => {
    if (rows !== props.rows) {
      setRows(props.rows)
    }
    if (columns !== props.columns) {
      setColumns(props.columns)
    }
  })
  return (
    <div
      id='complications_table'
      style={{
        height: '83vh'
      }}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          editMode='row'
          columnVisibilityModel={{ id: false }}
          // onRowClick={(params) => {
          // props.openRow("complications", params.row)
          // }}
          slots={{
            loadingOverlay: LinearProgress
          }}
          disableRowSelectionOnClick
          hideFooter
          hideFooterPagination
          hideFooterSelectedRowCount
          columns={columns || []}
          rows={rows || []}
        />
      </ThemeProvider>
    </div>
  )
}
