import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const Title = styled(Typography)({
  fontSize: '1.3em',
  fontWeight: '550'
})

export const TextValue = styled(Typography)({
  fontSize: '18px',
  width: '100%',
  display: 'block',
  height: '30px'
})

export const TextLabel = styled(Typography)({
  fontWeight: '550',
  fontSize: '18px'
})
