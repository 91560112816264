import { React, Component } from 'react'
import PregnantTable from './elements/table/table'
import Header from './header/header'
import getViolations from './api/get-violations'
import parseData from './utils/parse-data'
import columnsProperties from './utils/columns-properties'
import debounce from './utils/debounce'
import Notification from 'components/notification/notification'

export default class ViolationsListView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      columns: [],
      page: 0,
      pages: 0,
      page_size: 14,
      loading: false,
      totalItems: 0,
      query: '',
      error: '',
      isError: false
    }
    this.debouncedGetData = this.debouncedGetData.bind(this)
  }

  componentDidMount () {
    this.getData(this.state.page, this.state.page_size)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.page !== prevState.page || this.state.page_size !== prevState.page_size) {
      this.getData(this.state.page, this.state.page_size, this.state.query)
    }
    if (this.state.query !== prevState.query) {
      this.debouncedGetData(this.state.page, this.state.page_size, this.state.query)
    }
  }

  getData = (page, pageSize, query = '') => {
    this.setState({ loading: true })
    getViolations(page + 1, pageSize, query)
      .then((res) => {
        if (res.data) {
          this.setState({
            rows: parseData(res.data?.violations),
            columns: columnsProperties,
            loading: false,
            totalItems: res.data?.violations?.lenght,
            error: '',
            isError: false
          })
        }
      })
      .catch((err) => {
        this.setState({ error: err?.response?.statusText, isError: true })
      })
      .finally(() => this.setState({ loading: false }))
  }

  debouncedGetData = debounce(this.getData.bind(this), 800)

  handlePageChange = (data) => {
    this.setState({ page: data.page, page_size: data?.pageSize })
  }

  handleQueryChange = (e) => {}

  handleCloseError = () => {
    this.setState({ error: '', isError: false })
  }

  render () {
    return (
      <div style={{ height: '90vh' }}>
        {this.state.error && (
          <Notification
            isOpen={this.state.isError}
            handleClose={this.handleCloseError}
            message={this.state.error}
            type='error'
          />
        )}
        <Header handleQueryChange={this.handleQueryChange} query={this.state.query} />
        <PregnantTable
          loading={this.state.loading}
          rows={this.state.rows}
          columns={this.state.columns}
          table_title={'Список нарушений'}
          handlePageChange={this.handlePageChange}
          paginationModel={{
            page: this.state.page,
            pageSize: this.state.page_size
          }}
          totalItems={this.state.totalItems}
        />
      </div>
    )
  }
}
