export const setFilterValues = (setFilters, name, value) => {
  switch (name) {
    case 'age_search': {
      setFilters((prevState) => ({
        ...prevState,
        first_age: '',
        last_age: '',
        [name]: value,
      }))
      break
    }

    case 'first_age':
    case 'last_age': {
      setFilters((prevState) => ({
        ...prevState,
        age_search: '',
        [name]: value,
      }))
      break
    }

    case 'desired_week_of_pregnancy': {
      setFilters((prevState) => ({
        ...prevState,
        further_past_week_of_pregnancy: '',
        most_recent_week_of_pregnancy: '',
        [name]: value,
      }))
      break
    }

    case 'further_past_week_of_pregnancy':
    case 'most_recent_week_of_pregnancy': {
      setFilters((prevState) => ({
        ...prevState,
        desired_week_of_pregnancy: '',
        [name]: value,
      }))
      break
    }

    case 'date_of_registration_by_tmc_search': {
      setFilters((prevState) => ({
        ...prevState,
        further_past_date_of_registration_by_tmc_search: '',
        most_recent_date_of_registration_by_tmc_search: '',
        [name]: value,
      }))
      break
    }
    case 'status_of_call_search': {
      setFilters((prevState) => ({
        ...prevState,
        status_of_call_serach: '',
        [name]: value,
      }))
      break
    }

    case 'date_of_call_day_search': {
      setFilters((prevState) => ({
        ...prevState,
        date_of_call_day_search: '',
        [name]: value,
      }))
      break
    }

    case 'further_past_date_of_registration_by_tmc_search':
    case 'most_recent_date_of_registration_by_tmc_search': {
      setFilters((prevState) => ({
        ...prevState,
        date_of_registration_by_tmc_search: '',
        [name]: value,
      }))
      break
    }
    case 'pmsp_search': {
      setFilters((prevState) => ({
        ...prevState,
        pmsp_search: '',
        [name]: value.split(','),
      }))
      break
    }
    default: {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
      break
    }
  }
}
