export default function parseData (data) {
  if (data !== undefined) {
    for (let i = 0; i < data.length; i++) {
      data[i].date_of_set_status = data[i].date_of_set_status ? data[i].date_of_set_status.split('T')[0] : ''
      data[i].id = i
    }
    return data
  }
  return []
}
