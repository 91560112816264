const choiceFieldsObject = {
  SocialStatus: [],
  BloodType: [],
  FamilyStatus: [],
  AreaOfResidence: [],
  RhFactor: [],
  TypeOfPregnancy: [],
  RiskFactor: [],
  ComplicationOfPreviousPregnancy: [],
  AccompanyingIllness: []
}

export default choiceFieldsObject
