import { React, useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { PropTypes } from 'prop-types'

export default function ChildRequestsTable(props) {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (rows !== props.rows) {
      setRows(props.rows)
    }
    if (columns !== props.columns) {
      setColumns(props.columns)
    }
  })

  return <DataGrid getRowId={() => crypto.randomUUID()} rows={rows} columns={columns} />
}

ChildRequestsTable.propTypes = {
  rows: PropTypes.array,
  cols: PropTypes.array,
}
