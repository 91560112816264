export const getSortValue = (data) => {
  const field = data && data[0]?.field
  const sort = data && data[0]?.sort
  switch (field) {
    case 'full_name':
      return sort === 'asc' ? '-full_name' : 'full_name'
    case 'current_week_of_pregnancy':
      return sort === 'asc' ? '-current_week_of_pregnancy' : 'current_week_of_pregnancy'
    case 'date_of_filling_checklist':
      return sort === 'asc' ? '-date_of_filling_checklist' : 'date_of_filling_checklist'
    case 'date_of_doctors_appointment_or_hospitalization':
      return sort === 'asc'
        ? '-date_of_doctors_appointment_or_hospitalization'
        : 'date_of_doctors_appointment_or_hospitalization'
    case 'date_of_last_interaction':
      return sort === 'asc' ? '-date_of_last_interaction' : 'date_of_last_interaction'
    default:
      return ''
  }
}
