import { React } from 'react'
import { Sidebar } from 'react-pro-sidebar'
import { Button } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import './pregnant_card_sidebar.css'
import { PropTypes } from 'prop-types'

export default function PregnantCardSidebar (props) {
  return (
    <Sidebar id='pregnant_card_sidebar'>
      <div className='sidebar_close_button'>
        <a onClick={() => props.setCurrentModal(null, {})}>
          <CloseOutlinedIcon></CloseOutlinedIcon>
        </a>
      </div>
      <p className='sidebar_title'>Переход</p>
      <Button
        className={`sidebar_button ${props.current_modal_window.name === 'card' ? 'active' : 'inactive'}`}
        onClick={() => props.setCurrentModal('card', {})}
      >
        Личные данные
      </Button>
      <Button
        className={`sidebar_button ${
          props.current_modal_window.name === 'complications' || props.current_modal_window.name === 'new_complications'
            ? 'active'
            : 'inactive'
        }`}
        onClick={() => props.setCurrentModal('new_complications', {})}
      >
        Течение беременности
      </Button>
      <Button
        className={`sidebar_button ${
          props.current_modal_window.name === 'violations' || props.current_modal_window.name === 'new_violations'
            ? 'active'
            : 'inactive'
        }`}
        onClick={() => props.setCurrentModal('new_violations', {})}
      >
        Замечания и пожелания
      </Button>
      {props.current_modal_window.name === 'health_checklist' && (
        <Button
          className={`sidebar_button ${props.current_modal_window.name === 'health_checklist' ? 'active' : 'inactive'}`}
          onClick={() => props.setCurrentModal('health_checklist', {})}
        >
          Чек-лист состояния здоровья
        </Button>
      )}
      {props.current_modal_window.name === 'control_checklist' && (
        <Button
          className={`sidebar_button ${
            props.current_modal_window.name === 'control_checklist' ? 'active' : 'inactive'
          }`}
          onClick={() => props.setCurrentModal('control_checklist', {})}
        >
          Чек-лист контроля качества
        </Button>
      )}
    </Sidebar>
  )
}

PregnantCardSidebar.propTypes = {
  current_modal_window: PropTypes.object,
  setCurrentModal: PropTypes.func
}
