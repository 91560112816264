export function getNumberInputValueOperator (InputComponent, InputComponentProps) {
  return {
    label: 'равен',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null
      }
      return ({ value }) => {
        return value !== null && filterItem.value[0] <= value && value <= filterItem.value[1]
      }
    },
    InputComponent,
    InputComponentProps
  }
}
