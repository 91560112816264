import { React, Component } from 'react'
import {
  getQualityChecklistViolationsId,
  getQualityChecklistInteractionCommonFields,
  getPrivateDataPregnantId,
  postQualityChecklistViolations,
  postQualityChecklistInteractionCommon
} from 'api/'
import { PregnantSelect, PatientHelpModal } from 'elements/'
import { Grid, TextField, Typography, Button } from '@mui/material'
import styled from '@emotion/styled'
import { buttonStyle } from './styles/button-style'
import { PropTypes } from 'prop-types'
import '../popup_components.css'
import './violations.css'

const TextLabel = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '18px'
})
const TextValue = styled(Typography)({
  fontSize: '18px',
  width: '100%'
})

export default class Violations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pregnant_id: this.props.pregnant_id,
      data: this.props.data,

      week_of_pregnancy_on_date_of_filling: 0,
      group_of_violation: '',
      type_of_violation: '',
      violator: '',
      date_of_creation: '',
      doctor_full_name: '',
      doctor_username: localStorage.getItem('username'),
      pregnant_full_name: '',
      save_btn_disabled: false,
      save_btn_text: 'Сохранить',
      interaction_with_violation_status: { name: '' },
      interactions: [],

      comment: '',
      violation_id: this.props.data.id,

      max_retries: 5,
      interaction_with_violation_statuses: [],
      quality_checklist_violations_fields: [],
      quality_checklist_violations_fields_loading: true,
      quality_checklist_violations_data_loading: true,

      is_help_window_open: false,
      pregnant: null
    }
  }

  misPregnantId = (pregnantId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getPrivateDataPregnantId(pregnantId)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  pregnant: res?.data,
                  pregnant_full_name: res.data.pregnant.full_name,
                  week_of_pregnancy_on_date_of_filling: res.data.week_of_pregnancy_on_date_of_filling
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  qualityChecklistViolationsId = (violationId) => {
    let timeoutResponse
    const timeoutResponsesCache = []
    for (let i = 0; i < this.state.max_retries; i++) {
      timeoutResponse = setTimeout(() => {
        getQualityChecklistViolationsId(violationId)
          .then((res) => {
            if (res.data) {
              this.setState(
                {
                  quality_checklist_violations_data_loading: false,
                  date_of_creation: res.data.date_of_creation,
                  group_of_violation: res.data.group_of_violation,
                  type_of_violation: res.data.type_of_violation,
                  violator: res.data.violator,
                  doctor_full_name: res.data.doctor_full_name,
                  interactions: res.data.interactions,
                  week_of_pregnancy_on_date_of_filling: res.data.week_of_pregnancy_on_date_of_filling
                },
                () => {
                  timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
                }
              )
            }
          })
          .catch((err) => console.log(err))
      }, 1500 * i)
      timeoutResponsesCache.push(timeoutResponse)
    }
  }

  postQualityChecklistInteractionCommonWrap = (data) => {
    const parsedData = data
    if (!data.comment){
      delete parsedData['comment']
    }
    postQualityChecklistInteractionCommon(data)
      .then((res) => {
        if (res.data) {
          this.qualityChecklistViolationsId(this.props.data.id)
          this.setState({
            save_btn_disabled: true,
            save_btn_text: 'Сохранено'
          })
          this.props.updateCardBlockComponent('violations')
          this.props.setCurrentModal(null, {})
          this.props.notify('success', 'Успешно сохранено')
        }
      })
      .catch((err) => {
        this.props.notify('error', JSON.stringify(err?.response?.data))
      })
  }

  getQualityChecklistInteractionCommonFieldsWrap = () => {
    getQualityChecklistInteractionCommonFields()
      .then((res) => {
        if (res.data) {
          this.setState({
            interaction_with_violation_statuses: res.data.interaction_with_violation_statuses
          })
        }
      })
      .catch((err) => console.log(err))
  }

  postQualityChecklistViolationsWrap = (data) => {
    console.log(data)
    postQualityChecklistViolations(data)
      .then((res) => {
        console.log(data, res)
      })
      .catch((err) => {
        console.log(data, err)
      })
  }

  componentDidMount () {
    this.misPregnantId(this.props.pregnant_id)
    this.getQualityChecklistInteractionCommonFieldsWrap()
    this.qualityChecklistViolationsId(this.props.data.id)
  }

  setInteractionWithViolationStatus = (id, val) => {
    this.setState({ interaction_with_violation_status: val })
  }

  setText = (id, val) => {
    this.setState({
      [id]: val
    })
  }

  render () {
    return (
      <div className='pregnant_card_popup'>
        <div className='pregnant_card_sidebar_effect-violations'>
          <div className='main_block'>
            <div className='main_block_form_row'>
              <p className='main_block_title'>Замечания и пожелания</p>
            </div>
            <div className='popup_card-block main_block_form_row'>
              <div>
                <TextLabel className='row_input_title'>Замечания и пожелания</TextLabel>
                <TextValue>{this.state.type_of_violation.name}</TextValue>
              </div>
              <div>
                <TextLabel className='row_input_title'>Нарушитель</TextLabel>
                <TextValue>{this.state.violator.name}</TextValue>
              </div>
              <div>
                <TextLabel className='row_input_title'>Дата нарушения</TextLabel>
                <TextValue>{this.state.date_of_creation}</TextValue>
              </div>
              <div>
                <TextLabel className='row_input_title'>ФИО врача ТМЦ</TextLabel>
                <TextValue>{this.state.doctor_full_name || '---'}</TextValue>
              </div>
            </div>
          </div>
          <div className='main_block'>
            <div className='main_block_form_row'>
              <p className='main_block_title'>Взаимодействия</p>
            </div>
            <div className='popup_card-block'>
              <Grid spacing={2} container style={{ alignItems: 'normal' }}>
                <Grid xs={3} lg={3} md={3} item>
                  <p className='row_input_title'>Статус</p>
                  <PregnantSelect
                    id='interaction_with_violation_status'
                    onChange={this.setInteractionWithViolationStatus}
                    choices={this.state.interaction_with_violation_statuses}
                    value={this.state.interaction_with_violation_status}
                  ></PregnantSelect>
                </Grid>
                <Grid xs={3} lg={3} md={3} item>
                  <p className='row_input_title'>Врач ТМЦ</p>
                  <TextValue>{localStorage.getItem('full_name') || localStorage.getItem('username')}</TextValue>
                </Grid>
                <Grid xs={3} lg={3} md={3} item>
                  <p className='row_input_title'>Комментарий</p>
                  <TextField
                    id='comment'
                    onChange={(e) => this.setText(e.target.id, e.target.value)}
                    value={this.state.comment}
                    multiline
                    rows={4}
                    fullWidth
                  ></TextField>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className='main_block'>
            <div className='main_block_form_row'>
              <p className='main_block_title'>История взаимодействия</p>
            </div>
            {this.state.interactions.map((obj, index) => {
              return (
                <Grid key={index} justifyContent='space-around' container className='main_block_form_row'>
                  <Grid item>
                    <p className='row_input_title'>Статус</p>
                    <p>{obj.interaction_with_violation_status?.name}</p>
                  </Grid>
                  <Grid item>
                    <p className='row_input_title'>Дата статуса</p>
                    <p>{obj.date_of_creation}</p>
                  </Grid>
                  <Grid item>
                    <p className='row_input_title'>Комментарий</p>
                    <p>{obj.comment}</p>
                  </Grid>
                  <Grid item>
                    <p className='row_input_title'>Врач ТМЦ</p>
                    <p>{obj.doctor_full_name || '---'}</p>
                  </Grid>
                </Grid>
              )
            })}
          </div>
        </div>
        <Grid mt={3} container style={{ justifyContent: 'flex-end', gap: 20 }}>
          <Button
            onClick={() => this.setState({ is_help_window_open: true })}
            style={buttonStyle}
            variant='contained'
            color='primary'
          >
            Сформировать справку
          </Button>
          <Button
            onClick={() => {
              this.postQualityChecklistInteractionCommonWrap(this.state)
            }}
            disabled={this.state.save_btn_disabled}
            style={buttonStyle}
            variant='contained'
            color='primary'
          >
            {this.state.save_btn_text}
          </Button>
        </Grid>
        <PatientHelpModal
          open={this.state.is_help_window_open}
          onClose={() => this.setState({ is_help_window_open: false })}
          data={{
            ...this.state.pregnant,
            week: this.props.current_week_of_pregnancy,
            type_of_violation: this.state.type_of_violation,
            violator: this.state.violator,
            date_of_creation: this.state.date_of_creation,
            doctor_full_name: this.state.doctor_full_name,
            comment: this.state.comment
          }}
        />
      </div>
    )
  }
}

Violations.propTypes = {
  notify: PropTypes.func,
  data: PropTypes.object,
  pregnant_id: PropTypes.number,
  current_week_of_pregnancy: PropTypes.number,
  updateCardBlockComponent: PropTypes.func,
  setCurrentModal: PropTypes.func
}
