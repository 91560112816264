import { Alert, Snackbar } from '@mui/material'
import { React } from 'react'
import { PropTypes } from 'prop-types'

export default function Notification (props) {
  return (
    <Snackbar
      id='notification'
      open={props.isOpen}
      autoHideDuration={4000}
      onClose={props.handleClose}
      // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert variant='filled' severity={props.type}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}

Notification.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  type: PropTypes.string
}
