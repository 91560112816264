const {
  getHealthChecklistFields,
  postHealthChecklist
} = require('./health-checklist')

const {
  getComplicationFields,
  getComplications
} = require('./complications-list')

const {
  apiToken,
  refreshToken
} = require('./auth')

const {
  gaveBirthChildFields,
  gaveBirthChildGetId,
  gaveBirthChildHistoryGet,
  gaveBirthChildSave,
  gaveBirthPregnantFields,
  gaveBirthPregnantGetId,
  gaveBirthPregnantHistoryGet,
  gaveBirthPregnantSave,
  gaveBirthSearch
} = require('./gave-birth-list')

const {
  getMisValidationOfPersonalData,
  getPregnancyIdComplications,
  getPregnancyIdHealthChecklist,
  getPregnancyIdPersonalData,
  getPregnancyIdQualityChecklist,
  getPregnancyIdViolations,
  getPregnancyIdCallHistory
} = require('./pregnant-card')

const {
  postCallHistoryCreateCallHistory
} = require('./call-history')

const {
  getPrivateDataFields,
  getPrivateDataPregnantId,
  patchPrivateDataPregnantId
} = require('./private-data')

const {
  gethealthChecklistComplicationAll,
  postHealthChecklistComplication,
  getHealthChecklistComplicationId,
  getHealthChecklistComplicationFields
} = require('./health-checklist-complication')

const {
  postHealthChecklistComplicationInteraction,
  getHealthChecklistComplicationInteractionFieldsRecordType
} = require('./health-checklist-complication-interaction')

const {
  getQualityChecklistFields,
  getQualityChecklistId,
  postQualityChecklist,
  postQualityChecklistViolationsStep,
  getQualityChecklistInteractionCommonFields,
  getQualityChecklistViolationsCommonFields,
  postQualityChecklistViolationsCommon,
  postQualityChecklistInteractionCommon
} = require('./control-checklist')

const {
  getQualityChecklistViolationsId,
  postQualityChecklistViolations,
  getQualityChecklistViolationsFields
} = require('./violations')

const { getCallHistoryReport } = require('./report')

export {
  gaveBirthSearch,
  gaveBirthPregnantSave,
  gaveBirthPregnantHistoryGet,
  gaveBirthPregnantGetId,
  gaveBirthPregnantFields,
  gaveBirthChildSave,
  gaveBirthChildHistoryGet,
  gaveBirthChildGetId,
  gaveBirthChildFields,

  refreshToken,
  apiToken,

  getComplications,
  getComplicationFields,

  getPregnancyIdViolations,
  getPregnancyIdQualityChecklist,
  getPregnancyIdPersonalData,
  getPregnancyIdHealthChecklist,
  getPregnancyIdComplications,
  getPregnancyIdCallHistory,
  getMisValidationOfPersonalData,

  postCallHistoryCreateCallHistory,

  getPrivateDataFields,
  getPrivateDataPregnantId,
  patchPrivateDataPregnantId,

  getHealthChecklistFields,
  postHealthChecklist,

  gethealthChecklistComplicationAll,
  postHealthChecklistComplication,
  getHealthChecklistComplicationId,
  getHealthChecklistComplicationFields,

  postHealthChecklistComplicationInteraction,
  getHealthChecklistComplicationInteractionFieldsRecordType,

  getQualityChecklistFields,
  getQualityChecklistId,
  postQualityChecklist,
  getQualityChecklistViolationsFields,
  postQualityChecklistViolationsStep,
  getQualityChecklistInteractionCommonFields,
  getQualityChecklistViolationsCommonFields,
  postQualityChecklistViolationsCommon,
  postQualityChecklistInteractionCommon,
  getQualityChecklistViolationsId,
  postQualityChecklistViolations,

  getCallHistoryReport
}
