export function parseData(data) {
  if (data.results && data.results.length > 0) {
    for (let i = 0; i < data.results.length; i++) {
      Object.assign(data.results[i], { index: i })
      // data.results[i]['index'] = i
    }
    return data.results
  }
  return []
}
