export function filledPhonesSelect (data) {
  const phones = []
  const pregnantPhones = data.pregnant.phone || []
  const fatherPhones = data.info_about_pregnant_at_current_pregnancy.father_info?.phone || []
  const therapistPhones = data.pmsp_area.therapist[0]?.phone || []
  const healthvisitorPhones = data.pmsp_area?.health_visitor[0]?.phone || []
  for (let i = 0; i < pregnantPhones.length; i++) {
    Object.assign(pregnantPhones[i], { target: 'БЖ' })
    Object.assign(pregnantPhones[i], { fio: data.pregnant.full_name })
    phones.push(pregnantPhones[i])
  }
  for (let i = 0; i < fatherPhones.length; i++) {
    Object.assign(fatherPhones[i], { target: 'Отец' })
    Object.assign(fatherPhones[i], { fio: data.info_about_pregnant_at_current_pregnancy.father_info.full_name || '' })
    phones.push(fatherPhones[i])
  }
  for (let i = 0; i < therapistPhones.length; i++) {
    Object.assign(therapistPhones[i], { target: 'Терапевт' })
    Object.assign(therapistPhones[i], { fio: data.pmsp_area.therapist.full_name || '' })
    phones.push(therapistPhones[i])
  }
  for (let i = 0; i < healthvisitorPhones.length; i++) {
    Object.assign(healthvisitorPhones[i], { target: 'Медсестра/Медбрат' })
    Object.assign(healthvisitorPhones[i], { fio: data.pmsp_area.health_visitor.full_name || '' })
    phones.push(healthvisitorPhones[i])
  }
  return phones
}
