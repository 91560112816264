import { React, useEffect, useState } from 'react'
import getConferenceLinkByUuid from './api/get-conference-link-by-uuid'

const redirectToUrl = (url) => {
  return window.open(
    url
    //   '_blank',
  )
}

export default function ZoomJoinUrl () {
  const [uuid, setUuid] = useState(undefined)
  const [conferenceLink, setConferenceLink] = useState('')
  const queryParameters = new URLSearchParams(window.location.search)
  const uuidForJoinUrl = queryParameters.get('uuid_for_join_url')

  const handleGetConferenceLinkByUuid = (uuid) => {
    getConferenceLinkByUuid(uuid).then((res) => {
      if (res.data) {
        setConferenceLink(res.data.join_url)
      }
    })
  }
  useEffect(() => {
    if (conferenceLink) {
      redirectToUrl(conferenceLink)
    }
  }, [conferenceLink])

  useEffect(() => {
    if (uuid) {
      handleGetConferenceLinkByUuid(uuid)
    }
    if (uuid !== uuidForJoinUrl) {
      setUuid(uuidForJoinUrl)
    }
  }, [uuid, uuidForJoinUrl])

  return <></>
}
