import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'

export default async function refreshToken(refresh) {
  const credentials = { refresh }
  const request = await axiosToken.post('token/refresh/', credentials)
  return request
}

const removeTokensFromLocalStorage = () => {
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
}

const redirectToSignInPage = () => {
  window.location.href = '/login'
}

const AXIOS_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_AXIOS_BASE_URL
    : process.env.REACT_APP_PROD_AXIOS_BASE_URL

const axiosToken = axios.create({
  baseURL: `${AXIOS_URL}/auth`,
})

axios.defaults.baseURL = `${AXIOS_URL}/api_mis`

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      try {
        return refreshToken(localStorage.getItem('refresh'))
          .then((res) => {
            localStorage.setItem('access', res.data.access)
            setAuthToken(res.data.access)
            Object.assign(error.config.headers, { Authorization: 'Bearer ' + res.data.access })
            // error.config.headers['Authorization'] = 'Bearer ' + res.data.access
            return axios(originalRequest)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              removeTokensFromLocalStorage()
              redirectToSignInPage()
            }
          })
      } catch (err) {
        removeTokensFromLocalStorage()
        redirectToSignInPage()
      }
    } else {
      return Promise.reject(error)
    }
  }
)

export const setAuthToken = (token) => {
  if (token) {
    Object.assign(axios.defaults.headers.common, { Authorization: 'Bearer ' + token })
    // return (axios.defaults.headers.common['Authorization'] = `Bearer ${token}`)
  } else {
    return delete axios.defaults.headers.common.Authorization
  }
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
reportWebVitals()
