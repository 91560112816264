import isDateValid from '../is_date_valid'
import {
  getDateOfBirthFromIin,
  isOnlyNumbersIncluded
} from './'

export function getIinStateData (iin, dateOfBirth) {
  // let iinHelperText = validationObject.iin_helper_text
  let outputDateOfBirth = dateOfBirth
  console.log(iin)
  if (iin.length === 0) {
    return ['', dateOfBirth]
  }
  if (isOnlyNumbersIncluded(iin)) {
    // if (iin.length === 12) {
    //   iinHelperText = ''
    // }
    if (iin.length > 6) {
      const dateFromIin = getDateOfBirthFromIin(iin)
      if (isDateValid(dateFromIin) && dateFromIin !== dateOfBirth) {
        outputDateOfBirth = dateFromIin
      }
    }
    if (iin.length > 12) {
      return 0
    }
    return [iin, outputDateOfBirth]
  }
}
