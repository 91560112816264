export const pregnancyNewComplicationObject = {
  anamnesis: '',
  complaint: [],
  danger_sign: [],
  taking_drugs: [],
  diagnosis: {
    name: ''
  },
  diagnosis_status: {
    name: ''
  },
  record_type: {
    name: ''
  }
}
