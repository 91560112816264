import axios from 'axios'

export default async function postHealthChecklist (data) {
  const request = await axios({
    method: 'post',
    url: 'health_checklist/',
    data
  })
  return request
}
