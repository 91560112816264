export default function columnsProperties () {
  return [
    {
      field: 'date_of_creation',
      headerName: 'Дата',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'violation_type',
      headerName: 'Тип нарушения',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) => {
        return params.value?.name || ''
      }
    },
    {
      field: 'violator',
      headerName: 'Нарушитель',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) => {
        return params.value?.name || ''
      }
    },
    {
      field: 'status',
      headerName: 'Статус',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) => {
        return params.value?.name || ''
      }
    }
  ]
}
