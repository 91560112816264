export function checkIinWithZeroWithBirthDate (iin, birthdate) {
  const iinYearSecondPart = iin.charAt(0) + iin.charAt(1)
  const iinMonth = iin.charAt(2) + iin.charAt(3)
  const iinDay = iin.charAt(4) + iin.charAt(5)
  const [birthYear, birthMonth, birthDay] = birthdate.split('-')
  const birthYearSecondPart = birthYear.slice(2, 4)
  console.log(
    birthYearSecondPart +
      ' ' +
      iinYearSecondPart +
      '|' +
      iinMonth +
      ' ' +
      birthMonth +
      '|' +
      iinDay +
      ' ' +
      birthDay
  )
  return birthYearSecondPart === iinYearSecondPart && iinMonth === birthMonth && iinDay === birthDay
}
