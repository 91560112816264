const { isDataOfRegistrationTodayOrLaterThanNowDate } = require('./is-data-of-registration-today-or-later-than-now-date')
const { isDateOfRegistrationLowerThanFirstDate } = require('./is-date-of-registration-lower-than-first-date')
const { isDateOfRegistrationLowerThanNowDateForThenMonths } = require('./is-date-of-registration-lower-than-now-date-for-ten-months')
const { isDateOfRegistrationMoreThanNowDate } = require('./is-date-of-registration-more-than-now-date')

export {
  isDateOfRegistrationLowerThanNowDateForThenMonths,
  isDateOfRegistrationMoreThanNowDate,
  isDateOfRegistrationLowerThanFirstDate,
  isDataOfRegistrationTodayOrLaterThanNowDate
}
