import { createTheme, ThemeProvider } from '@mui/material/styles'
import { LinearProgress, Popover } from '@mui/material'
import { DataGrid, ruRU } from '@mui/x-data-grid'
import { React, useEffect, useState } from 'react'
import './table.css'

export default function PregnantTable (props) {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [popoverData, setPopoverData] = useState(null)
  const [anchorEl, setAnchorEl] = useState()
  const theme = createTheme(ruRU)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
    setPopoverData(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setPopoverData(null)
    setAnchorEl(null)
  }
  useEffect(() => {
    if (rows !== props.rows) {
      setRows(props.rows)
    }
    if (columns !== props.columns) {
      setColumns(props.columns)
    }
  })
  return (
    <div
      id='complications_table'
      style={{
        height: '32.3vh'
      }}
    >
      <Popover
        id='mouse-over-popover'
        onClose={handlePopoverClose}
        open={Boolean(anchorEl)}
        elevation={12}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
      >
        {popoverData}
      </Popover>
      <ThemeProvider theme={theme}>
        <DataGrid
          editMode='row'
          columnVisibilityModel={{ id: false }}
          onRowClick={(params) => {
            props.openRow('complications', params.row)
          }}
          slots={{
            loadingOverlay: LinearProgress
          }}
          slotProps={{
            row: {
              onMouseEnter: () => handlePopoverOpen,
              onMouseOver: handlePopoverClose
            }
          }}
          loading={props.loading}
          disableRowSelectionOnClick
          hideFooter
          hideFooterPagination
          hideFooterSelectedRowCount
          columns={columns}
          rows={rows}
        />
      </ThemeProvider>
    </div>
  )
}
