import PregnantMultiselectValuesPlaceholder from './pregnant-multiselect-values-placeholder'
import './pregnant-search-multiselect.css'
import { Autocomplete, TextField } from '@mui/material'
import { React } from 'react'

export function PregnantSearchMultiSelect (props) {
  const setVal = (id, values) => {
    const outputValues = []
    values.reduce((accumulator, currentVal, index) => {
      return outputValues.push({ name: currentVal })
    }, 0)
    props.onChange(props.id, outputValues)
  }

  return (
    <div>
      <Autocomplete
        fullWidth
        size='small'
        // className='MuiOutlinedInput-root'
        disableCloseOnSelect={true}
        error={props.error}
        id='pregnant-search-multiselect'
        multiple
        disablePortal
        options={props.options.map((a) => a.name) || []}
        sx={{ width: '100%' }}
        renderTags={() => undefined}
        onChange={(event, values, reason) => {
          // const formattedValues = values.map(item => item?.label)
          setVal(props.id, values)
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      <PregnantMultiselectValuesPlaceholder chosen={props.choices} />
    </div>
  )
}
