import {
  isDateOfDeregistrationLowerThanDateOfRegistration,
  isDateOfDeregistrationTodayOrEarlier
} from './'

export function AssertDateOfDeregistrationValidation (dateOfRegistration, dateOfDeregistration) {
  if (!dateOfDeregistration) {
    return ''
  }
  if (isDateOfDeregistrationLowerThanDateOfRegistration(dateOfRegistration, dateOfDeregistration)) {
    return 'Дата снятия не может быть раньше даты взятия на учёт ТМЦ'
  }
  if (isDateOfDeregistrationTodayOrEarlier(dateOfDeregistration)) {
    return 'Дата снятия не может быть позже, чем сегодня'
  }
  return ''
}
