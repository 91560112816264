import removeEmptyData from './remove-empty-data'

export default function parseData (data) {
  const parsedData = {}
  Object.assign(parsedData, { complaint: data.PregnancyComplication.complaint })
  Object.assign(parsedData, { danger_sign: data.PregnancyComplication.danger_sign })
  Object.assign(parsedData, { taking_drugs: data.PregnancyComplication.taking_drugs })
  Object.assign(parsedData, { diagnosis: data.PregnancyComplication.diagnosis })
  Object.assign(parsedData, { diagnosis_status: data.PregnancyComplication.diagnosis_status })
  Object.assign(parsedData, { pregnancy_id: data.pregnancy_id })
  Object.assign(parsedData, { doctor_username: data.doctor_username })
  Object.assign(parsedData, { record_type: data.PregnancyComplication.record_type })
  Object.assign(parsedData, { anamnesis: data.PregnancyComplication.anamnesis.replace(/\n/g, ' ') })
  return removeEmptyData(parsedData)
}
