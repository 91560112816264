import { React, Component } from 'react'
import { CardContent, CardHeader, Card, Button } from '@mui/material'
import pregnancyComplications from './api-state/pregnancy-compliactions'
import PregnantTable from './elements/table/table'
import Loading from '../loading/loading'

export default class Complications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rows: [],
      columns: [],
      loading: true,
      max_retries: 5
    }
  }

  pregnancyComplicationsWithParams = () => {
    pregnancyComplications(this, this.props.pregnant_id)
  }

  handleButtonClick = (target, data) => {
    return this.props.validation_of_personal_data_messages_string
      ? this.props.notify('error', this.props.validation_of_personal_data_messages_string)
      : this.props.setCurrentModal(target, data)
  }

  componentDidMount () {
    this.pregnancyComplicationsWithParams()
  }

  render () {
    return (
      <Card className='pregnant_card_block'>
        {this.state.loading
          ? (
          <Loading></Loading>
            )
          : (
              <>
                <CardHeader className='block_title' title='Течение беременности' />
                <CardContent>
                  <div style={{ height: '32.3vh' }}>
                    <PregnantTable
                      loading={this.state.loading}
                      rows={this.state.rows}
                      columns={this.state.columns}
                      openRow={this.props.setCurrentModal}
                      className='pregnant_card_table'
                      hideFooterPagination={true}
                      hideFooterSelectedRowCount={true}
                    ></PregnantTable>
                  </div>
                  <div className='button_row'>
                    <Button
                      style={{ textTransform: 'none', fontSize: '16px' }}
                      onClick={() => this.handleButtonClick('new_complications', {})}
                      variant='contained'
                      color='primary'
                    >
                      Новая запись
                    </Button>

                    <Button
                      style={{ textTransform: 'none', fontSize: '16px' }}
                      onClick={() => this.handleButtonClick('all_complications', {})}
                      variant='contained'
                      color='primary'
                    >
                      Все записи
                    </Button>
                  </div>
                </CardContent>
              </>
            )}
      </Card>
    )
  }
}
