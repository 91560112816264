import React, { useState } from 'react'
import { Box, MenuItem, Select } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export default function MultiSelectInputValue(props) {
  const { item, applyValue } = props
  const items = props.fields && props.fields[item?.field]
  const [searchParams] = useSearchParams()
  const [value, setValue] = useState(searchParams.get('pmsp_search') ? searchParams.get('pmsp_search').split(',') : [])

  const handleChange = (event) => {
    setValue(event.target.value)
    applyValue({ ...item, value: event.target.value.join(',') })
    props.handleFilterChange(props.getSelectFilterName(item?.field), event.target.value.join(','))
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px',
      }}
    >
      <Select
        labelId={`label-select-input-${item?.field}`}
        id={`select-input-${item?.field}`}
        value={value}
        multiple={true}
        label='Значение'
        onChange={handleChange}
      >
        {items?.map((item, index) => (
          <MenuItem selected={value.includes(item?.name)} value={item?.name} key={index}>
            {item?.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
