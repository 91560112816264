import { React } from 'react'
import { TextField as TextFieldBase } from '@mui/material'
import { PropTypes } from 'prop-types'

export const TextField = (props) => {
  return (
    <TextFieldBase
      fullWidth={props.fullWidth}
      size='small'
      value={props.value}
      disabled={props.disabled}
      onChange={(event) => props.onChange(event.target.value)}
    ></TextFieldBase>
  )
}

TextField.propTypes = {
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
