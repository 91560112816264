export const borderStyles = {
  borderRadius: '20px',
  border: '1px solid #ced4da'
}

export const borderStylesWithMaxWidth = {
  borderRadius: '20px',
  border: '1px solid #ced4da',
  maxWidth: '49.3%'
}

export const blockTitleStyles = {
  fontWeight: 'bold',
  fontSize: '30px'
}

export const infoTitleStyles = {
  fontWeight: 'bold',
  fontSize: '23px'
}
export const inputLabelStyles = {
  fontWeight: 'bold',
  fontSize: '15.3px'
}
