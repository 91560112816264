import { React, Component } from 'react'
import {
  TextField,
  Grid,
  InputAdornment
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import './header.css'

export default class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      call_target: ''
    }
  }

  render () {
    return (
      <div style={{ width: '95%', margin: '0 auto' }}>
        <Grid py={1} spacing={1} container>
          <Grid item xs={8} lg={8} md={8}>
            <TextField
              sx={{ height: '100%' }}
              placeholder='Введите данные для поиска...'
              size='small'
              fullWidth
              onChange={this.props.handleQueryChange}
              value={this.props.query}
              margin='none'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  height: '100%'
                }
              }}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}
