import { React, Component } from 'react'
import { CardContent, CardHeader, Card, Button } from '@mui/material'
import Loading from '../loading/loading'
import personalDataObject from './data-structure/personal_data_object'
import pregnancyPersonalData from './api-state/pregnancy-personal-data'
import { transformDatetimeToDate } from 'shortcuts/'
import { TextLabel as TextLabelBase, TextValue as TextValueBase } from 'elements/'
import { getPrivateDataPregnantId } from 'api/'

const TextLabel = (props) => {
  const { value } = props
  return <TextLabelBase style={{ textAlign: 'start' }}>{value}</TextLabelBase>
}

const TextValue = (props) => {
  const { value } = props
  return <TextValueBase style={{ textAlign: 'start' }}>{value}</TextValueBase>
}

export default class PregnantCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      personal_data: personalDataObject,
      pregnant: null,
      current_modal_window: null,
      loading: true,
      pregnant_loading: true,
      max_retries: 5,
    }
  }

  misPregnantIdWithParams = () => {
    getPrivateDataPregnantId(this.props.pregnant_id)
      .then((res) => {
        if (res.data) {
          this.setState({
            pregnant: res.data.pregnant,
            info_about_pregnant_at_current_pregnancy: res.data.info_about_pregnant_at_current_pregnancy,
            pmsp_area: res.data.pmsp_area,
            pregnant_loading: false,
          })
        }
      })
      .catch((err) => console.log(err))
  }

  pregnancyPersonalDataWithParams = () => {
    pregnancyPersonalData(this, this.props.pregnant_id)
  }

  componentDidMount() {
    this.misPregnantIdWithParams()
    this.pregnancyPersonalDataWithParams()
  }

  render() {
    return (
      <Card className='pregnant_card_block'>
        <CardHeader className='block_title' title='Личные данные' />
        <CardContent>
          {this.state.loading ? (
            <Loading></Loading>
          ) : (
            <>
              <div style={{ height: '31.2vh' }}>
                <div className='block_row'>
                  <div>
                    <TextLabel value='Возраст' />
                    <TextValue value={this.state.personal_data.age} />
                  </div>
                  <div>
                    <TextLabel value='ПМСП' />
                    <TextValue value={this.state.personal_data.pmsp} />
                  </div>
                </div>
                <div className='block_row'>
                  <div>
                    <TextLabel value='Взятие на учёт' />
                    <TextValue value={transformDatetimeToDate(this.state.personal_data.date_of_registration_by_tmc)} />
                  </div>
                  <div>
                    <TextLabel value='Тип беременности' />
                    <TextValue value={this.state.info_about_pregnant_at_current_pregnancy?.type_of_pregnancy?.name} />
                  </div>
                </div>
                <div className='block_row'>
                  <div>
                    <TextLabel value='Вес до беременности' />
                    <TextValue value={this.state.personal_data.weight_before_pregnancy} />
                  </div>
                  <div>
                    <TextLabel value='Всего беременностей' />
                    <TextValue value={this.state.personal_data.total_pregnancies} />
                  </div>
                </div>
                <div className='block_row'>
                  <div>
                    <TextLabel value='Снятие с учёта' />
                    <TextValue
                      value={this.state.info_about_pregnant_at_current_pregnancy?.reason_of_deregistration?.name}
                    />
                  </div>
                  <div>
                    <TextLabel value='Дата родов' />
                    <TextValue
                      value={transformDatetimeToDate(this.state.personal_data.estimated_date_of_childbirth) || ''}
                    />
                  </div>
                </div>
              </div>
              <div className='button_row'>
                <Button
                  variant='contained'
                  style={{ textTransform: 'none', fontSize: '16px' }}
                  onClick={() => this.props.setCurrentModal('card')}
                  color='primary'
                >
                  Подробнее
                </Button>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    )
  }
}
