import axios from 'axios'

export async function getCallHistoryReport () {
  const request = await axios.post(
    'reports/report',
    {},
    {
      responseType: 'blob'
    }
  )
  return request
}
