import { React } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { PropTypes } from 'prop-types'

export default function PregnantSelect (props) {
  return props.choices !== undefined && props.choices.length > 0
    ? (
      <div>
        <Select
          id={props.id}
          error={props.error || false}
          fullWidth={props.fullWidth || false}
          size='small'
          multiple={props.multiple}
          value={props.value?.name || ''}
          onChange={(event) => {
            props.onChange(props.id, { name: event.target.value })
          }}
          style={{ width: '100%' }}
        >
          {props.choices.map((obj, index) => {
            return <MenuItem key={index} value={obj.name}>{obj.name}</MenuItem>
          })}
        </Select>
      </div>
      )
    : (
    <div>
      <Select
        style={{ width: '100%' }}
      ></Select>
    </div>
      )
}

PregnantSelect.propTypes = {
  id: PropTypes.number,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  choices: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func
}
