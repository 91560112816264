import { getPregnancyIdPersonalData } from 'api/'

function pregnancyPersonalData (parent, pregnantId) {
  let timeoutResponse
  const timeoutResponsesCache = []
  for (let i = 0; i < parent.state.max_retries; i++) {
    timeoutResponse = setTimeout(() => {
      getPregnancyIdPersonalData(pregnantId)
        .then((res) => {
          if (res.data) {
            parent.setState(
              {
                personal_data: res.data,
                loading: false
              },
              () => {
                timeoutResponsesCache.map((timeout) => clearTimeout(timeout))
              }
            )
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }, 1500 * i)
    timeoutResponsesCache.push(timeoutResponse)
  }
}

export default pregnancyPersonalData
