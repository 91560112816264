import * as React from 'react'
import { createContext, useRef } from 'react'
import Loading from './loading-component'

const LoadingContext = createContext({})

export function LoadingProvider ({ children }) {
  const ref = useRef()
  const startLoading = () => ref.current.start()
  const stopLoading = () => ref.current.stop()
  const value = React.useMemo(() => ({ ref, startLoading, stopLoading }), [ref, startLoading, stopLoading])
  return (
    <LoadingContext.Provider value={value}>
      <Loading ref={ref}></Loading>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoader = () => React.useContext(LoadingContext)
